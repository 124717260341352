import {
    ADD_ANALYSIS,
    GET_ONE_ANALYSIS,
    UPDATE_ANALYSIS,
    DELETE_ANALYSIS,
    ANALYSIS_ERROR,
    GET_ANALYSIS,
    CLEAR_ANALYSIS,
    SET_ANALYSIS_LOADING
} from '../utils/consts';

const initialState = {
    analysis: null,
    analysisList: [],
    isLoading: false
};

const analysis = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_ANALYSIS:
            return {
                ...state,
                analysisList: [ payload, ...state.analysisList ],
            };
        case GET_ANALYSIS:
            return {
                ...state,
                analysisList: payload,
                isLoading: false,
            };
        case GET_ONE_ANALYSIS:
        case UPDATE_ANALYSIS:
            return {
                ...state,
                analysis: payload,
                isLoading: false
            };
        case DELETE_ANALYSIS:
            return {
                ...state,
                analysis: null,
                isLoading: false
            };
        case SET_ANALYSIS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_ANALYSIS:
            return {
                ...state,
                analysis: null,
            };
        case ANALYSIS_ERROR:
            return {
                ...state,
                isLoading: false,
                analysis: null
            };
        default:
            return state;
    }
};

export default analysis;