import {$api} from "../http";

class TnvedService {

    async addTnved( code, name, economic_code, certification_code, standarts ) {
        return $api.post( '/tnved', { code, name, economic_code, certification_code, standarts } );
    }

    async getAllTnved() {
        return $api.get( '/tnved' );
    }

    async updateTnved( tnvedId, code, name, economic_code, certification_code, standarts ) {
        return $api.put( `/tnved/${tnvedId}`, { code, name, economic_code, certification_code, standarts } );
    }

    async removeTnved( tnvedId ) {
        return $api.delete( `/tnved/${tnvedId}` );
    }

    async getTnvedById( tnvedId ) {
        return $api.get( `/tnved/${tnvedId}` );
    }
}

export default new TnvedService();

