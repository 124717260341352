import {
    ADD_ECONOMIC_CODE,
    GET_ECONOMIC_CODE,
    UPDATE_ECONOMIC_CODE,
    DELETE_ECONOMIC_CODE,
    ECONOMIC_CODE_ERROR,
    GET_ECONOMIC_CODES,
    CLEAR_ECONOMIC_CODE,
    SET_ECONOMIC_CODE_LOADING
} from '../utils/consts';

const initialState = {
    economicCode: null,
    economicCodeList: [],
    isLoading: false
};

const economicCode = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_ECONOMIC_CODE:
            return {
                ...state,
                economicCodeList: [ payload, ...state.economicCodeList ],
            };
        case GET_ECONOMIC_CODES:
            return {
                ...state,
                economicCodeList: payload,
                isLoading: false,
            };
        case GET_ECONOMIC_CODE:
        case UPDATE_ECONOMIC_CODE:
            return {
                ...state,
                economicCode: payload,
                isLoading: false
            };
        case DELETE_ECONOMIC_CODE:
            return {
                ...state,
                economicCode: null,
                isLoading: false
            };
        case SET_ECONOMIC_CODE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_ECONOMIC_CODE:
            return {
                ...state,
                economicCode: null,
            };
        case ECONOMIC_CODE_ERROR:
            return {
                ...state,
                isLoading: false,
                economicCode: null
            };
        default:
            return state;
    }
};

export default economicCode;