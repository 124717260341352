import {$api} from "../http";

class QsmOneService {
    async addQsmOne(
        qsmdoctype,
        document_code,
        document_name,
        standart_clause,
        approval_date,
        introduction_date,
        actual_last,
        actual_next,
        responsibility_dev,
        responsibility_approval,
        responsibility_agreement,
        responsibility_acquaintance,
        responsibility_keeping,
        status,
        document_link,
        journalqsmone_mailing
    ) {
        return $api.post( '/qsmone', {
            qsmdoctype,
            document_code,
            document_name,
            standart_clause,
            approval_date,
            introduction_date,
            actual_last,
            actual_next,
            responsibility_dev,
            responsibility_approval,
            responsibility_agreement,
            responsibility_acquaintance,
            responsibility_keeping,
            status,
            document_link,
            journalqsmone_mailing
        } );
    }

    async getAllQsmOne() {
        return $api.get( '/qsmone' );
    }

    async updateQsmOne(
        qsmId,
        qsmdoctype,
        document_code,
        document_name,
        standart_clause,
        approval_date,
        introduction_date,
        actual_last,
        actual_next,
        responsibility_dev,
        responsibility_approval,
        responsibility_agreement,
        responsibility_acquaintance,
        responsibility_keeping,
        status,
        document_link,
        journalqsmone_mailing
    ) {
        return $api.put( `/qsmone/${qsmId}`, {
            qsmdoctype,
            document_code,
            document_name,
            standart_clause,
            approval_date,
            introduction_date,
            actual_last,
            actual_next,
            responsibility_dev,
            responsibility_approval,
            responsibility_agreement,
            responsibility_acquaintance,
            responsibility_keeping,
            status,
            document_link,
            journalqsmone_mailing
        } );
    }

    async removeQsmOne( qsmId ) {
        return $api.delete( `/qsmone/${qsmId}` );
    }

    async getQsmOneById( qsmId ) {
        return $api.get( `/qsmone/${qsmId}` );
    }
}

export default new QsmOneService();

