import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Space, Button, Popconfirm, Empty } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllCbsoa, removeCbsoa, getCbsoaById } from '../../actions/cbsoa';
import { getAllTnved } from "../../actions/tnveds";
import Loading from "../../components/Loading/Loading";
import appNotification from "../../components/AppNotification/AppNotification";

import CbsoaModal from "../../components/Modals/CbsoaModal";
import { CLEAR_CBSOA } from "../../utils/consts";
import '../Qsm.css';

const { Title } = Typography;

const Cbsoa = () => {

    const [ visible, setVisible ] = useState( false );
    const dispatch = useDispatch();
    const cbsoaItems = useSelector( state => state.cbsoa.cbsoaItems );
    const isLoading = useSelector( state => state.cbsoa.isLoading );
    const notify = useSelector( state => state.notifications.notify );


    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllTnved() );
        dispatch( getAllCbsoa() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    const Codes = ( { id, field } ) => {
        let economicCodes = [];
        let data = [];
        data = cbsoaItems.filter( item => item._id === id ).map( item => item.objects.filter( item => item[ field ] ) );
        new Set( data[ 0 ].map( item => item[ field ].code ).sort() ).forEach( val => economicCodes.push( val ) );
        return economicCodes.map( item => <p key={item}>{item}</p> );
    };
    const Standarts = ( { id } ) => {
        let Standarts = [];
        let data = [];
        data = cbsoaItems.filter( item => item._id === id ).map( item => item.objects.filter( item => item.standarts ) )[0].map(item => item.standarts);
        new Set( data.map(item => item.map(it => it.code)).sort() ).forEach( val => Standarts.push( val ) );
        return Standarts[0].map( item => <p key={item}>{item}</p> );
    };

    return (
        <>
            <Title level={3}>Область аккредитации</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_CBSOA } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            {isLoading ?
                <Loading/> : cbsoaItems && cbsoaItems.length > 0 ? (
                    <div className="QsmTableWrapper">
                        <table className="QsmTable colFixed">
                            <thead className="QsmTableHead">
                            <tr>
                                <th>№№</th>
                                <th>Код <br/> экономического сектора</th>
                                <th>Код <br/> объекта сертификации</th>
                                <th>Код ТН ВЭД</th>
                                <th>Наименование <br/>объекта сертификации (вид продукции)</th>
                                <th>Обозначение нормативных <br/>документов в области технического<br/> регулирования,
                                    регламентирующих<br/> требования к объектам сертификации
                                </th>
                                <th rowSpan={2} className="colFixed">Действия</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                cbsoaItems.map( ( cbsoaItem, idx ) => {
                                    return (
                                        <tr key={cbsoaItem._id}>
                                            <td>{idx + 1}</td>
                                            <td>{<Codes id={cbsoaItem._id} field="economic_code"/>}</td>
                                            <td>{<Codes id={cbsoaItem._id} field="certification_code"/>}</td>
                                            <td>{cbsoaItem.objects.map( obj => <p key={obj._id}>{obj.code}</p> )}</td>
                                            <td>{cbsoaItem.name}</td>
                                            <td>{<Standarts id={cbsoaItem._id} />}</td>
                                            <td>
                                                <>
                                                    <Button type="primary" icon={<EditOutlined/>}
                                                            size="small"
                                                            onClick={() => {
                                                                dispatch( getCbsoaById( cbsoaItem._id ) );
                                                                setVisible( true );
                                                            }}/>
                                                    {
                                                        cbsoaItems.length >= 1 ? (
                                                            <Popconfirm
                                                                title="Вы уверены что хотите удалить запись ?"
                                                                onConfirm={() => dispatch( removeCbsoa( cbsoaItem._id ) )}>
                                                                <Button type="primary" danger
                                                                        icon={<DeleteOutlined/>}
                                                                        size="small"/>
                                                            </Popconfirm>
                                                        ) : null
                                                    }
                                                </>
                                            </td>
                                        </tr>
                                    )
                                } )
                            }
                            </tbody>
                        </table>
                    </div>
                ) : ( <Empty/> )}
            <CbsoaModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </> )

};

export default Cbsoa;