import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeSubdivision, addSubdivision, updateSubdivision } from '../../actions/subdivisions';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
};

const SubdivisionModal = ( { visible, onCreate, onCancel, history } ) => {

    const subdivision = useSelector( state => state.subdivisions.subdivision );
    const isLoading = useSelector( state => state.subdivisions.isLoading );
    const dispatch = useDispatch();

    const [ subdivisionData, setSubdivisionData ] = useState( subdivision || initialState );

    useEffect( () => {
        setSubdivisionData( subdivision !== null ? subdivision : initialState );
    }, [ subdivision ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newSubdivisionData = {
                    name: values.name
                };
                const newData = { ...subdivisionData, ...newSubdivisionData };
                setSubdivisionData( newData );
                if ( subdivision !== null && subdivision._id ) {
                    dispatch( updateSubdivision( subdivision._id, newData ) );
                } else {
                    dispatch( addSubdivision( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    return (
        <Modal
            visible={visible}
            title={subdivision !== null && subdivision._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                subdivision !== null && subdivision._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeSubdivision( subdivision._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {subdivision !== null && subdivision._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formInit={form}
                name="subdivisionForm"
                size="middle"
                initValues={subdivisionData}
            >
                <FormField name="name" label="Наименование"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default SubdivisionModal;