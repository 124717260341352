import {
    ADD_STANDART,
    GET_STANDART,
    UPDATE_STANDART,
    DELETE_STANDART,
    STANDART_ERROR,
    GET_STANDARTS,
    CLEAR_STANDART,
    SET_STANDART_LOADING
} from '../utils/consts';

const initialState = {
    standart: null,
    standarts: [],
    isLoading: false
};

const standarts = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_STANDART:
            return {
                ...state,
                standarts: [ payload, ...state.standarts ],
            };
        case GET_STANDARTS:
            return {
                ...state,
                standarts: payload,
                isLoading: false,
            };
        case GET_STANDART:
        case UPDATE_STANDART:
            return {
                ...state,
                standart: payload,
                isLoading: false
            };
        case DELETE_STANDART:
            return {
                ...state,
                standart: null,
                isLoading: false
            };
        case SET_STANDART_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_STANDART:
            return {
                ...state,
                standart: null
            };

        case STANDART_ERROR:
            return {
                ...state,
                isLoading: false,
                standart: null
            };
        default:
            return state;
    }
};

export default standarts;