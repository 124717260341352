import {$api} from "../http";

class CertificationCodeService {

    async addCertificationCode( code, name ) {
        return $api.post( '/certificationcode', { code, name } );
    }

    async getAllCertificationCode() {
        return $api.get( '/certificationcode' );
    }

    async updateCertificationCode( certificationId, code, name ) {
        return $api.put( `/certificationcode/${certificationId}`, { code, name } );
    }

    async removeCertificationCode( certificationId ) {
        return $api.delete( `/certificationcode/${certificationId}` );
    }

    async getCertificationCodeById( certificationId ) {
        return $api.get( `/certificationcode/${certificationId}` );
    }
}

export default new CertificationCodeService();

