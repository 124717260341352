import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllCertificationCode, removeCertificationCode, getCertificationCodeById } from '../../actions/certificationCode';
import Loading from "../../components/Loading/Loading";
import CertificationCodeModal from "../../components/Modals/CertificationCodeModal";
import appNotification from "../../components/AppNotification/AppNotification";
import { CLEAR_CERTIFICATION_CODE } from "../../utils/consts";

const { Title } = Typography;

const CertificationCode = () => {

    const [ filteredInfo, setFilteredInfo ] = useState( null );
    const [ sortedInfo, setSortedInfo ] = useState( null );
    const [ visible, setVisible ] = useState( false );

    const certificationCodeList = useSelector( state => state.certificationCode.certificationCodeList );
    const isLoading = useSelector( state => state.certificationCode.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const dispatch = useDispatch();

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllCertificationCode() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    let sorted = sortedInfo;
    let filtered = filteredInfo;
    sorted = sorted || {};
    filtered = filtered || {};

    // change filters and sorts
    const handleChange = ( pagination, filters, sorter ) => {
        setFilteredInfo( filters );
        setSortedInfo( sorter );
    };
    // clear filter and sorts
    const clearAll = () => {
        setFilteredInfo( null );
        setSortedInfo( null );
    };

    let certificationCode = [];
    let certificationCodeName = [];

    new Set( certificationCodeList.map( ( item ) => item.code ) ).forEach( val => certificationCode.push( val ) );
    new Set( certificationCodeList.map( ( item ) => item.name ) ).forEach( val => certificationCodeName.push( val ) );

    const columns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 60,
            render: ( text, record, index ) => index + 1,
        },
        {
            title: 'Код объекта',
            dataIndex: 'code',
            key: 'code',
            width: 200,
            render: ( text, record ) => ( <Button type="link" size="small" onClick={() => {
                dispatch( getCertificationCodeById( record._id ) );
                setVisible( true );
            }}>{text}</Button> ),
            filters: ( certificationCode.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.code || null,
            onFilter: ( value, record ) => record.code.toString().toLowerCase().includes(value.toString().toLowerCase()),
            sorter: (a, b) => a.code - b.code,
            sortOrder: sorted.columnKey === 'code' ? sortedInfo.order : null,
        },
        {
            title: 'Наименование объекта сертификация продукции',
            dataIndex: 'name',
            key: 'name',
            filters: ( certificationCodeName.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.name || null,
            onFilter: ( value, record ) => record.name.includes( value ),
            sorter: (a, b) => a.name.length - b.name.length,
            sortOrder: sorted.columnKey === 'name' ? sortedInfo.order : null,
        },

        {
            title: 'Действия',
            dataIndex: 'action',
            render: ( text, record ) => (
                <>
                    <Button type="primary" icon={<EditOutlined/>} size="small" onClick={() => {
                        dispatch( getCertificationCodeById( record._id ) );
                        setVisible( true );
                    }}/>
                    {
                        certificationCodeList.length >= 1 ? (
                            <Popconfirm title="Вы уверены что хотите удалить запись ?"
                                        onConfirm={() => dispatch( removeCertificationCode( record._id ) )}>
                                <Button type="primary" danger icon={<DeleteOutlined/>} size="small"/>
                            </Popconfirm>
                        ) : null
                    }
                </>
            ),
            width: 150,
            fixed: 'right'
        },
    ];

    return isLoading ? <Loading/> : (
        <>
            <Title level={3}> Классификатор объектов сертификации</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button onClick={clearAll}>Очистить фильтры</Button>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_CERTIFICATION_CODE } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            <Table
                bordered
                rowKey={( record ) => record._id}
                columns={columns}
                dataSource={certificationCodeList}
                onChange={handleChange}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1200, y: 500 }}
            />
            <CertificationCodeModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </>
    );

};

export default CertificationCode;