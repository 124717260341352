import {$api} from "../http";

class RoleService {

    async addRole( name ) {
        return $api.post( '/role', { name } );
    }

    async getAllRoles() {
        return $api.get( '/role' );
    }

    async updateRole( roleId, name ) {
        return $api.put( `/role/${roleId}`, { name } );
    }

    async removeRole( roleId ) {
        return $api.delete( `/role/${roleId}` );
    }

    async getRoleById( roleId ) {
        return $api.get( `/role/${roleId}` );
    }
}

export default new RoleService();

