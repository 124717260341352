import React, { useEffect, useState } from "react";
import { Empty, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAllQsmOneDocType } from '../../actions/qsmOneDocTypes';
import { getAllQsmOne } from '../../actions/qsmOne';
import { getAllPositions } from "../../actions/positions";
import { getAllUsers } from "../../actions/users";
import Loading from "../../components/Loading/Loading";
import appNotification from "../../components/AppNotification/AppNotification";
import QsmOneModal from "../../components/Modals/QsmOneModal";

import '../Qsm.css';

const { Title } = Typography;

const JournalQSMThree = ( ) => {
    const [ visible, setVisible ] = useState( false );

    const dispatch = useDispatch();
    const qmsOneDataList = useSelector( state => state.qsmOne.qsmOneDataList );
    const isLoading = useSelector( state => state.qsmOne.isLoading );
    const notify = useSelector( state => state.notifications.notify );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllQsmOneDocType() );
        dispatch( getAllQsmOne() );
        dispatch( getAllPositions() );
        dispatch( getAllUsers() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    return isLoading ? <Loading/> : qmsOneDataList && qmsOneDataList.length > 0 ? (
        <>
            <Title level={3}>График актуализации ВД ИСМ ПСМ-LET-01-C</Title>
            <div className="QsmTableWrapper">
                <table className="QsmTable">
                    <thead className="QsmTableHead">
                    <tr>
                        <th rowSpan={2}>№ п/п</th>
                        <th rowSpan={2}>Код<br/> документа</th>
                        <th rowSpan={2}>Наименование<br/> документа</th>
                        <th colSpan={2}>Актуализация</th>
                        <th rowSpan={2}>Статус</th>
                        <th rowSpan={2}>Примечание</th>
                    </tr>
                    <tr>
                        <th>Последняя</th>
                        <th>Следующая</th>
                    </tr>
                    <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        qmsOneDataList.filter( ( data ) => data?.qsmdoctype?.name !== 'Должностные инструкции' ).map( ( child, index ) => (
                            <tr key={`child${child._id}`}>
                                <td>{index + 1}</td>
                                <td>{child.document_code}</td>
                                <td>{child.document_name}</td>
                                <td>{moment( child.actual_last ).format( 'DD.MM.YYYY' )}</td>
                                <td>{moment( child.actual_next ).format( 'DD.MM.YYYY' )}</td>
                                <td>{child.status}</td>
                                <td></td>
                            </tr>
                        ) )
                    }
                    </tbody>
                </table>
            </div>

            <QsmOneModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </>
    ) : ( <Empty/> );

};

export default JournalQSMThree;