import {
    ADD_NONCONFORMITY,
    GET_NONCONFORMITY,
    GET_NONCONFORMITY_USER,
    UPDATE_NONCONFORMITY,
    DELETE_NONCONFORMITY,
    NONCONFORMITY_ERROR,
    GET_ALL_NONCONFORMITY,
    GET_LAST_NONCONFORMITY,
    CLEAR_NONCONFORMITY,
    SET_NONCONFORMITY_LOADING
} from '../utils/consts';

const initialState = {
    nonconformity: null,
    nonconformityLast: null,
    nonconformityList: [],
    isLoading: false
};

const nonconformity = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_NONCONFORMITY:
            return {
                ...state,
                nonconformityList: [ payload, ...state.nonconformityList ],
            };
        case GET_ALL_NONCONFORMITY:
            return {
                ...state,
                nonconformityList: payload,
                isLoading: false,
            };
        case GET_LAST_NONCONFORMITY:
            return {
                ...state,
                nonconformityLast: payload,
                isLoading: false,
            };
        case GET_NONCONFORMITY_USER:
            return {
                ...state,
                nonconformityList: payload,
                isLoading: false,
            };
        case GET_NONCONFORMITY:
        case UPDATE_NONCONFORMITY:
            return {
                ...state,
                nonconformity: payload,
                isLoading: false
            };
        case DELETE_NONCONFORMITY:
            return {
                ...state,
                nonconformity: null,
                isLoading: false
            };
        case SET_NONCONFORMITY_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_NONCONFORMITY:
            return {
                ...state,
                nonconformity: null,
            };
        case NONCONFORMITY_ERROR:
            return {
                ...state,
                isLoading: false,
                nonconformity: null
            };

        default:
            return state;
    }
};

export default nonconformity;