import {
    ADD_SUBDIVISION,
    GET_SUBDIVISION,
    UPDATE_SUBDIVISION,
    DELETE_SUBDIVISION,
    SUBDIVISION_ERROR,
    GET_SUBDIVISIONS,
    CLEAR_SUBDIVISION,
    SET_SUBDIVISION_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import SubdivisionService from "../services/SubdivisionService";

// Get All Subdivision
export const getAllSubdivision = () => async dispatch => {
    dispatch( { type: SET_SUBDIVISION_LOADING } );
    dispatch( { type: CLEAR_SUBDIVISION } );
    try {
        const response = await SubdivisionService.getAllSubdivision();
        dispatch( {
            type: GET_SUBDIVISIONS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: SUBDIVISION_ERROR
        } );
    }
};

// Get Subdivision by ID
export const getSubdivisionById = ( subdivisionId ) => async dispatch => {
    dispatch( { type: SET_SUBDIVISION_LOADING } );
    dispatch( { type: CLEAR_SUBDIVISION } );
    try {
        const response = await SubdivisionService.getSubdivisionById( subdivisionId );
        dispatch( {
            type: GET_SUBDIVISION,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: SUBDIVISION_ERROR
        } );
    }
};


// Add Subdivision
export const addSubdivision = ( { name } ) => async dispatch => {
    dispatch( { type: SET_SUBDIVISION_LOADING } );
    dispatch( { type: CLEAR_SUBDIVISION } );
    try {
        const response = await SubdivisionService.addSubdivision( name );
        dispatch( {
            type: ADD_SUBDIVISION,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllSubdivision() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: SUBDIVISION_ERROR
        } );
    }
};
// Update Subdivision
export const updateSubdivision = ( subdivisionId, { name } ) => async dispatch => {
    dispatch( { type: SET_SUBDIVISION_LOADING } );
    dispatch( { type: CLEAR_SUBDIVISION } );
    try {
        const response = await SubdivisionService.updateSubdivision( subdivisionId, name );
        dispatch( {
            type: UPDATE_SUBDIVISION,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllSubdivision() );
        dispatch( getSubdivisionById( subdivisionId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: SUBDIVISION_ERROR
        } );
    }
};

// Delete Subdivision
export const removeSubdivision = ( subdivisionId ) => async dispatch => {
    dispatch( { type: SET_SUBDIVISION_LOADING } );
    dispatch( { type: CLEAR_SUBDIVISION } );
    try {
        await SubdivisionService.removeSubdivision( subdivisionId );
        dispatch( {
            type: DELETE_SUBDIVISION,
            payload: subdivisionId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllSubdivision() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: SUBDIVISION_ERROR
        } );
    }
};




