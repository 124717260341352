import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Modal, Form, Input, Button, DatePicker } from 'antd';
import { addRisk } from '../../actions/risks';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    risk_date: moment(),
    risk_content: "",
    employee: ""
};
const { TextArea } = Input;

const RiskAddModal = ( { visible, onCreate, onCancel } ) => {

    const user = useSelector( state => state.auth.user );
    const isLoading = useSelector( state => state.risks.isLoading );
    const dispatch = useDispatch();

    const [ riskData, setRiskData ] = useState( initialState );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newRiskData = {
                    risk_date: values.risk_date,
                    risk_content: values.risk_content,
                    employee: user.id
                };
                const newData = { ...riskData, ...newRiskData };
                setRiskData( newData );
                dispatch( addRisk( newData ) );
                form.submit();
                form.resetFields();
                setRiskData( initialState );
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 12 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 12 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={"Добавить запись" }
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {"Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="riskaddForm"
                size="middle"
                initValues={riskData}
            >
                <FormField
                    name="risk_date" label="Дата обнаружения риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста укажите Дата обнаружения риска',
                    } ]}>
                    <DatePicker format='DD.MM.YYYY'/>
                </FormField>
                <FormField
                    name="risk_content"
                    label="Содержание обнаруженного риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Содержание обнаруженного риска!',
                        whitespace: true
                    } ]}
                >
                    <TextArea name="risk_content" rows={3}/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default RiskAddModal;