import {
    ADD_CERTIFICATION_CODE,
    GET_CERTIFICATION_CODE,
    UPDATE_CERTIFICATION_CODE,
    DELETE_CERTIFICATION_CODE,
    CERTIFICATION_CODE_ERROR,
    GET_CERTIFICATION_CODES,
    CLEAR_CERTIFICATION_CODE,
    SET_CERTIFICATION_CODE_LOADING
} from '../utils/consts';

const initialState = {
    certificationCode: null,
    certificationCodeList: [],
    isLoading: false
};

const certificationCode = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_CERTIFICATION_CODE:
            return {
                ...state,
                certificationCodeList: [ payload, ...state.certificationCodeList ],
            };
        case GET_CERTIFICATION_CODES:
            return {
                ...state,
                certificationCodeList: payload,
                isLoading: false,
            };
        case GET_CERTIFICATION_CODE:
        case UPDATE_CERTIFICATION_CODE:
            return {
                ...state,
                certificationCode: payload,
                isLoading: false
            };
        case DELETE_CERTIFICATION_CODE:
            return {
                ...state,
                certificationCode: null,
                isLoading: false
            };
        case SET_CERTIFICATION_CODE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_CERTIFICATION_CODE:
            return {
                ...state,
                certificationCode: null,
            };
        case CERTIFICATION_CODE_ERROR:
            return {
                ...state,
                isLoading: false,
                certificationCode: null
            };
        default:
            return state;
    }
};

export default certificationCode;