import {
    GET_ALLQSMDESIGNATION,
    UPDATE_QSMDESIGNATION,
    GET_QSMDESIGNATION,
    DELETE_QSMDESIGNATION,
    SET_QSMDESIGNATION_LOADING,
    CLEAR_QSMDESIGNATION,
    QSMDESIGNATION_ERROR,
    ADD_QSMDESIGNATION,
} from '../utils/consts';

import { setNotify } from './notications';

import QsmService from "../services/QsmDesignationsService";

// Get All Qsm Four
export const getAllQsmDesignation = () => async dispatch => {
    dispatch( { type: SET_QSMDESIGNATION_LOADING } );
    dispatch( { type: CLEAR_QSMDESIGNATION } );
    try {
        const response = await QsmService.getAllQsmDesignation();
        dispatch( {
            type: GET_ALLQSMDESIGNATION,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMDESIGNATION_ERROR
        } );
    }
};

// Get QSM Four by Id
export const getQsmDesignationById = ( qsmId ) => async dispatch => {
    dispatch( { type: SET_QSMDESIGNATION_LOADING } );
    dispatch( { type: CLEAR_QSMDESIGNATION } );
    try {
        const response = await QsmService.getQsmDesignationById( qsmId );
        dispatch( {
            type: GET_QSMDESIGNATION,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMDESIGNATION_ERROR
        } );
    }
};

// Add QSM Four
export const addQsmDesignation = (
    {
        document_code,
        document_name_ru,
        document_name_uz,
        document_name_en,
        description
    }
) => async dispatch => {
    dispatch( { type: SET_QSMDESIGNATION_LOADING } );
    dispatch( { type: CLEAR_QSMDESIGNATION } );
    try {
        const response = await QsmService.addQsmDesignation(
            document_code,
            document_name_ru,
            document_name_uz,
            document_name_en,
            description
        );
        dispatch( {
            type: ADD_QSMDESIGNATION,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllQsmDesignation() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMDESIGNATION_ERROR
        } );
    }
};
// Update QSM Four
export const updateQsmDesignation = (
    qsmId,
    {
        document_code,
        document_name_ru,
        document_name_uz,
        document_name_en,
        description
    }
) => async dispatch => {
    dispatch( { type: SET_QSMDESIGNATION_LOADING } );
    dispatch( { type: CLEAR_QSMDESIGNATION } );
    try {
        const response = await QsmService.updateQsmDesignation(
            qsmId,
            document_code,
            document_name_ru,
            document_name_uz,
            document_name_en,
            description
        );
        dispatch( {
            type: UPDATE_QSMDESIGNATION,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllQsmDesignation() );
        dispatch( getQsmDesignationById( qsmId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMDESIGNATION_ERROR
        } );
    }
};

// DELETE QSM Four
export const removeQsmDesignation = ( qsmId ) => async dispatch => {
    dispatch( { type: SET_QSMDESIGNATION_LOADING } );
    dispatch( { type: CLEAR_QSMDESIGNATION } );
    try {
        await QsmService.removeQsmDesignation( qsmId );
        dispatch( {
            type: DELETE_QSMDESIGNATION,
            payload: qsmId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllQsmDesignation() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMDESIGNATION_ERROR
        } );
    }
};




