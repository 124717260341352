import {$api} from "../http";

class RiskTypeService {

    async addRiskType( name, description ) {
        return $api.post( '/risktype', { name, description } );
    }

    async getAllRiskTypes() {
        return $api.get( '/risktype' );
    }

    async updateRiskType( riskId, name, description ) {
        return $api.put( `/risktype/${riskId}`, { name, description } );
    }

    async removeRiskType( riskId ) {
        return $api.delete( `/risktype/${riskId}` );
    }

    async getRiskTypeById( riskId ) {
        return $api.get( `/risktype/${riskId}` );
    }
}

export default new RiskTypeService();

