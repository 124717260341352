import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeCertificationCode, addCertificationCode, updateCertificationCode } from '../../actions/certificationCode';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    code: '',
    name: '',
};

const CertificationCodeModal = ( { visible, onCreate, onCancel } ) => {

    const certificationCode = useSelector( state => state.certificationCode.certificationCode );
    const isLoading = useSelector( state => state.certificationCode.isLoading );
    const dispatch = useDispatch();

    const [ certificationCodeData, setCertificationCodeData ] = useState( certificationCode || initialState );

    useEffect( () => {
        setCertificationCodeData( certificationCode !== null ? certificationCode : initialState );
    }, [ certificationCode ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newCertificationCodeData = {
                    code: values.code,
                    name: values.name
                };
                const newData = { ...certificationCodeData, ...newCertificationCodeData };
                setCertificationCodeData( newData );
                if ( certificationCode !== null && certificationCode._id ) {
                    dispatch( updateCertificationCode( certificationCode._id, newData ) );
                } else {
                    dispatch( addCertificationCode( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={certificationCode !== null && certificationCode._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={700}
            footer={[
                certificationCode !== null && certificationCode._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeCertificationCode( certificationCode._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {certificationCode !== null && certificationCode._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="certificationCodeForm"
                size="middle"
                initValues={certificationCodeData}
            >
                <FormField name="code" label="Код объекта"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Код объекта!',
                               whitespace: true
                           } ]}>
                    <Input name="code"/>
                </FormField>
                <FormField name="name" label="Наименование объекта"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Наименование объекта сертификации продукции!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default CertificationCodeModal;