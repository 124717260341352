import {$api} from "../http";

class UserService {

    async addUser( email, name, password, role, position, department ) {
        return $api.post( '/user/registration', { email, name, password, role, position, department } );
    }

    async getAllUsers() {
        return $api.get( '/user/users' );
    }

    async updateUser( userId, email, name, password, role, position, department ) {
        return $api.put( `/user/${userId}`, { email, name, password, role, position, department } );
    }

    async updateProfile( userId, fmData ) {
        return $api.put( `/user/profile/${userId}`, fmData );
    }

    async removeUser( userId ) {
        return $api.delete( `/user/${userId}` );
    }

    async getUserById( userId ) {
        return $api.get( `/user/${userId}` );
    }
}

export default new UserService();

