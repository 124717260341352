import React from 'react';
import {
    Form,
} from 'antd';


const FormField = ( { name, label, rules, labelCol, children, initialValue } ) => {
    return (
        <Form.Item
            name={name}
            labelCol={labelCol}
            label={
                <span>
                        {label}&nbsp;
                    {/*<Tooltip title={`${label}`}>*/}
                    {/*<QuestionCircleOutlined/>*/}
                    {/*</Tooltip>*/}
                    </span>
            }
            rules={rules}
            initialValue={initialValue}
        >
            {children}
        </Form.Item>
    );
};

export default FormField;