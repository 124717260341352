import {
    ADD_RISKTYPE,
    GET_RISKTYPE,
    UPDATE_RISKTYPE,
    DELETE_RISKTYPE,
    RISKTYPE_ERROR,
    GET_RISKTYPES,
    CLEAR_RISKTYPE,
    SET_RISKTYPE_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import RiskTypeService from "../services/RiskTypeService";

// Get All Risk Types
export const getAllRiskTypes = () => async dispatch => {
    dispatch( { type: SET_RISKTYPE_LOADING } );
    dispatch( { type: CLEAR_RISKTYPE } );
    try {
        const response = await RiskTypeService.getAllRiskTypes();
        dispatch( {
            type: GET_RISKTYPES,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISKTYPE_ERROR
        } );
    }
};

// Get Risk Type by ID
export const getRiskTypeById = ( riskId ) => async dispatch => {
    dispatch( { type: SET_RISKTYPE_LOADING } );
    dispatch( { type: CLEAR_RISKTYPE } );
    try {
        const response = await RiskTypeService.getRiskTypeById( riskId );
        dispatch( {
            type: GET_RISKTYPE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISKTYPE_ERROR
        } );
    }
};


// Add Risk Type
export const addRiskType = ( { name, description } ) => async dispatch => {
    dispatch( { type: SET_RISKTYPE_LOADING } );
    dispatch( { type: CLEAR_RISKTYPE } );
    try {
        const response = await RiskTypeService.addRiskType( name, description );
        dispatch( {
            type: ADD_RISKTYPE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllRiskTypes() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISKTYPE_ERROR
        } );
    }
};

// Update Risk Type
export const updateRiskType = ( riskId, { name, description } ) => async dispatch => {
    dispatch( { type: SET_RISKTYPE_LOADING } );
    dispatch( { type: CLEAR_RISKTYPE } );
    try {
        const response = await RiskTypeService.updateRiskType( riskId, name, description );
        dispatch( {
            type: UPDATE_RISKTYPE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllRiskTypes() );
        dispatch( getRiskTypeById( riskId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISKTYPE_ERROR
        } );
    }
};

// Delete Risk Type
export const removeRiskType = ( riskId ) => async dispatch => {
    dispatch( { type: SET_RISKTYPE_LOADING } );
    dispatch( { type: CLEAR_RISKTYPE } );
    try {
        await RiskTypeService.removeRiskType( riskId );
        dispatch( {
            type: DELETE_RISKTYPE,
            payload: riskId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllRiskTypes() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISKTYPE_ERROR
        } );
    }
};



