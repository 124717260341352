import {$api} from "../http";

class NonconformityService {

    async addNonconformity(
        audit_code,
        nonconformity_code,
        nonconformity_date,
        analysis_type,
        employee,
        subdivision,
        influence,
        document_designation,
        document_section,
        nonconformity_description,
        nonconformity_reason,
        corrective_number,
        corrective_description,
        elimination_terms,
        elimination_responsible,
        completion_mark,
        corrective_confirmation,
        analysis_result,
        r_corrective_description,
        r_elimination_terms,
        r_elimination_responsible,
        r_completion_mark,
        r_corrective_confirmation,
        r_analysis_result,
        status
    ) {
        return $api.post( '/nonconformity/', {
            audit_code,
            nonconformity_code,
            nonconformity_date,
            analysis_type,
            employee,
            subdivision,
            influence,
            document_designation,
            document_section,
            nonconformity_description,
            nonconformity_reason,
            corrective_number,
            corrective_description,
            elimination_terms,
            elimination_responsible,
            completion_mark,
            corrective_confirmation,
            analysis_result,
            r_corrective_description,
            r_elimination_terms,
            r_elimination_responsible,
            r_completion_mark,
            r_corrective_confirmation,
            r_analysis_result,
            status
        } );
    }

    async getAllNonconformity() {
        return $api.get( `/nonconformity/` );
    }

    async updateNonconformity(
        nonconformityId,
        audit_code,
        nonconformity_code,
        nonconformity_date,
        analysis_type,
        employee,
        subdivision,
        influence,
        document_designation,
        document_section,
        nonconformity_description,
        nonconformity_reason,
        corrective_number,
        corrective_description,
        elimination_terms,
        elimination_responsible,
        completion_mark,
        corrective_confirmation,
        analysis_result,
        r_corrective_description,
        r_elimination_terms,
        r_elimination_responsible,
        r_completion_mark,
        r_corrective_confirmation,
        r_analysis_result,
        status
    ) {
        return $api.put( `/nonconformity/${nonconformityId}`, {
                audit_code,
                nonconformity_code,
                nonconformity_date,
                analysis_type,
                employee,
                subdivision,
                influence,
                document_designation,
                document_section,
                nonconformity_description,
                nonconformity_reason,
                corrective_number,
                corrective_description,
                elimination_terms,
                elimination_responsible,
                completion_mark,
                corrective_confirmation,
                analysis_result,
                r_corrective_description,
                r_elimination_terms,
                r_elimination_responsible,
                r_completion_mark,
                r_corrective_confirmation,
                r_analysis_result,
                status
            }
        );
    }

    async removeNonconformity( nonconformityId ) {
        return $api.delete( `/nonconformity/${nonconformityId}` );
    }

    async getNonconformityById( nonconformityId ) {
        return $api.get( `/nonconformity/${nonconformityId}` );
    }

    async getNonconformityByUser( userId ) {
        return $api.get( `/nonconformity/user/${userId}` );
    }

    async getLastNonconformity( userId ) {
        return $api.get( `/nonconformity/last` );
    }
}

export default new NonconformityService();

