import {$api} from "../http";

class AuthService {
    async login( email, password ) {
        return $api.post( '/user/login', { email, password } );
    }

    async logout() {
        return $api.post( '/user/logout' );
    }
}

export default new AuthService();

