import {
    ADD_ECONOMIC_CODE,
    GET_ECONOMIC_CODE,
    UPDATE_ECONOMIC_CODE,
    DELETE_ECONOMIC_CODE,
    ECONOMIC_CODE_ERROR,
    GET_ECONOMIC_CODES,
    CLEAR_ECONOMIC_CODE,
    SET_ECONOMIC_CODE_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import EconomicService from "../services/EconomicCodeService";

// Get All EconomicCode
export const getAllEconomicCode = () => async dispatch => {
    dispatch( { type: SET_ECONOMIC_CODE_LOADING } );
    dispatch( { type: CLEAR_ECONOMIC_CODE } );
    try {
        const response = await EconomicService.getAllEconomicCode();
        dispatch( {
            type: GET_ECONOMIC_CODES,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        console.log(e.response?.statusText);
        dispatch( {
            type: ECONOMIC_CODE_ERROR
        } );
    }
};

// Get EconomicCode by ID
export const getEconomicCodeById = ( economicId ) => async dispatch => {
    dispatch( { type: SET_ECONOMIC_CODE_LOADING } );
    dispatch( { type: CLEAR_ECONOMIC_CODE } );
    try {
        const response = await EconomicService.getEconomicCodeById( economicId );
        dispatch( {
            type: GET_ECONOMIC_CODE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ECONOMIC_CODE_ERROR
        } );
    }
};


// Add EconomicCode
export const addEconomicCode = ( { code, name } ) => async dispatch => {
    dispatch( { type: SET_ECONOMIC_CODE_LOADING } );
    dispatch( { type: CLEAR_ECONOMIC_CODE } );
    try {
        const response = await EconomicService.addEconomicCode( code, name );
        dispatch( {
            type: ADD_ECONOMIC_CODE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllEconomicCode() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ECONOMIC_CODE_ERROR
        } );
    }
};
// Update EconomicCode
export const updateEconomicCode = ( economicId, { code, name } ) => async dispatch => {
    dispatch( { type: SET_ECONOMIC_CODE_LOADING } );
    dispatch( { type: CLEAR_ECONOMIC_CODE } );
    try {
        const response = await EconomicService.updateEconomicCode( economicId, code, name );
        dispatch( {
            type: UPDATE_ECONOMIC_CODE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllEconomicCode() );
        dispatch( getEconomicCodeById( economicId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ECONOMIC_CODE_ERROR
        } );
    }
};

// Delete EconomicCode
export const removeEconomicCode = ( economicId ) => async dispatch => {
    dispatch( { type: SET_ECONOMIC_CODE_LOADING } );
    dispatch( { type: CLEAR_ECONOMIC_CODE } );
    try {
        await EconomicService.removeEconomicCode( economicId );
        dispatch( {
            type: DELETE_ECONOMIC_CODE,
            payload: economicId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllEconomicCode() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ECONOMIC_CODE_ERROR
        } );
    }
};




