import {
    ADD_DEPARTMENT,
    GET_DEPARTMENT,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    DEPARTMENT_ERROR,
    GET_DEPARTMENTS,
    CLEAR_DEPARTMENT, 
    SET_DEPARTMENT_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import DepartmentService from "../services/DepartmentService";

// Get All Departments
export const getAllDepartments = () => async dispatch => {
    dispatch( {type: SET_DEPARTMENT_LOADING } );
    dispatch( {type: CLEAR_DEPARTMENT } );
    try {
        const response = await DepartmentService.getAllDepartments();
        dispatch( {
            type: GET_DEPARTMENTS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: DEPARTMENT_ERROR
        } );
    }
};

// Get Department by ID
export const getDepartmentById = ( departmentId ) => async dispatch => {
    dispatch( {type: SET_DEPARTMENT_LOADING } );
    dispatch( {type: CLEAR_DEPARTMENT } );
    try {
        const response = await DepartmentService.getDepartmentById( departmentId );
        dispatch( {
            type: GET_DEPARTMENT,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: DEPARTMENT_ERROR
        } );
    }
};


// Add department
export const addDepartment = ( { name } ) => async dispatch => {
    dispatch( {type: SET_DEPARTMENT_LOADING } );
    dispatch( {type: CLEAR_DEPARTMENT } );
    try {
        const response = await DepartmentService.addDepartment( name );
        dispatch( {
            type: ADD_DEPARTMENT,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllDepartments() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: DEPARTMENT_ERROR
        } );
    }
};
// Update Department
export const updateDepartment = ( departmentId, { name } ) => async dispatch => {
    dispatch( {type: SET_DEPARTMENT_LOADING } );
    dispatch( {type: CLEAR_DEPARTMENT } );
    try {
        const response = await DepartmentService.updateDepartment( departmentId, name );
        dispatch( {
            type: UPDATE_DEPARTMENT,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllDepartments() );
        dispatch( getDepartmentById( departmentId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: DEPARTMENT_ERROR
        } );
    }
};

// Delete Department
export const removeDepartment = ( departmentId ) => async dispatch => {
    dispatch( { type: SET_DEPARTMENT_LOADING } );
    dispatch( { type: CLEAR_DEPARTMENT } );
    try {
        await DepartmentService.removeDepartment( departmentId );
        dispatch( {
            type: DELETE_DEPARTMENT,
            payload: departmentId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllDepartments() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: DEPARTMENT_ERROR
        } );
    }
};




