import React from 'react';
import { Layout, Avatar, Menu, Dropdown, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    PoweroffOutlined
} from '@ant-design/icons';
import { logout } from "../actions/auth";

const { Header } = Layout;

const AppHeader = ( { toggle, changeToggle } ) => {
    const user = useSelector( state => state.auth.user );
    const dispatch = useDispatch();
    const menu = (
        <Menu>
            <Menu.Item key="profile" icon={<UserOutlined/>}>
                <Link to="/profile">
                    Профиль
                </Link>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="exit" icon={<PoweroffOutlined/>} onClick={() => dispatch( logout() )}>
                Выйти
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement( toggle ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: changeToggle,
            } )}
            <div style={{ display: 'flex' }}>
                <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                    <Tooltip title="Ant User" placement="top">
                        <Avatar style={{ backgroundColor: '#87d068' }}>U</Avatar>
                    </Tooltip>
                    <Tooltip title="Ant User" placement="top">
                        <Avatar style={{ backgroundColor: '#87d068' }}>U</Avatar>
                    </Tooltip>
                    <Tooltip title="Ant User" placement="top">
                        <Avatar style={{ backgroundColor: '#87d068' }}>U</Avatar>
                    </Tooltip>
                    <Tooltip title="Ant User" placement="top">
                        <Avatar style={{ backgroundColor: '#87d068' }}>U</Avatar>
                    </Tooltip>
                </Avatar.Group>
                <Dropdown overlay={menu} placement="bottomRight">
                    <span className="header-left-bar-item">
                         <Tooltip title={user?.name} placement="left">
                        <Avatar style={{ backgroundColor: '#87d068' }}>{user?.name.substr(0, 1)}</Avatar>
                    </Tooltip>

                    </span>
                </Dropdown>
            </div>

        </Header>
    )
};

export default AppHeader;