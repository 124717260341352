import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Steps, Divider } from 'antd';
import { getAllApplication, getApplicationById } from '../../actions/applications';
import { getAllCertSchema } from "../../actions/certSchemas";
import { getAllClients } from "../../actions/clients";
import { getAllUsers } from "../../actions/users";
import { getAllTnved } from "../../actions/tnveds";
import { getAllLab } from "../../actions/labs";
import { getAllStandarts } from "../../actions/standarts";
import Loading from "../../components/Loading/Loading";
import appNotification from "../../components/AppNotification/AppNotification";
import ApplicationMain from "../../components/Application/ApplicationMain";
import ApplicationDecision from "../../components/Application/ApplicationDecision";
import ApplicationSampling from "../../components/Application/ApplicationSampling";
import ApplicationTesting from "../../components/Application/ApplicationTesting";
import ApplicationExtradition from "../../components/Application/ApplicationExtradition";
import './style.css';
import { getAllCertificationCode } from "../../actions/certificationCode";
import { getAllEconomicCode } from "../../actions/economicCode";

const { Title } = Typography;
const { Step } = Steps;

const AppItem = () => {
    const { id } = useParams();
    const clients = useSelector( state => state.clients.clientsList );
    const users = useSelector( state => state.users.users );
    const certSchemasList = useSelector( state => state.certSchemas.certSchemasList );
    const tnvedsList = useSelector( state => state.tnveds.tnvedsList );
    const labsList = useSelector( state => state.labs.labsList );
    const standarts = useSelector( state => state.standarts.standarts );
    const application = useSelector( state => state.applications.application );
    const applicationsList = useSelector( state => state.applications.applicationsList );
    const isLoading = useSelector( state => state.applications.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const dispatch = useDispatch();


    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllApplication() );
        dispatch( getAllCertSchema() );
        dispatch( getAllClients() );
        dispatch( getAllUsers() );
        dispatch( getAllTnved() );
        dispatch( getAllLab() );
        dispatch( getAllStandarts() );
        dispatch( getAllCertificationCode() );
        dispatch( getAllEconomicCode() );

    }, [ dispatch ] );

    useEffect( () => {
        if ( id && id !== 'add' ) {
            dispatch( getApplicationById( id ) );
        }

    }, [ dispatch, id ] );

    const [ current, setCurrent ] = useState( 0 );

    const onChange = ( value ) => {
        setCurrent( value );
    };

    let max = Math.max( ...applicationsList.map( item => item.app_number ) );

    const steps = [
        {
            title: 'Заявка',
            content: <ApplicationMain data={application} clients={clients} users={users}
                                      certSchemasList={certSchemasList} tnved={tnvedsList} standarts={standarts}
                                      max={max}/>,
        },
        {
            title: 'Решение по заявке',
            content: <ApplicationDecision data={application} labs={labsList} users={users}/>,
        },
        {
            title: 'Отбор образцов',
            content: <ApplicationSampling data={application} users={users}/>,
        },
        {
            title: 'Программа испытаний',
            content: 'Last-content',
        },
        {
            title: 'Результаты испытаний',
            content: <ApplicationTesting data={application} labs={labsList}/>,
        },
        {
            title: 'Результаты обследования',
            content: 'Last-content',
        },
        {
            title: 'Решение о выдаче',
            content: <ApplicationExtradition data={application} users={users}/>,
        },
        {
            title: 'Оформление сертификата',
            content: 'Last-content',
        },

    ];

    return isLoading ? <Loading/> : (
        <>
            <Title level={3}>Заявка</Title>

            <Steps progressDot current={current} onChange={onChange} size="small">
                {
                    application?.cert_schema?.code === "3" ? steps.map( ( item ) => ( <Step key={item.title} title={item.title}/> ) ) : steps.filter((item, id) => id !== 5).map( ( item ) => ( <Step key={item.title} title={item.title}/> ) )
                }
            </Steps>

            <Divider/>
            <div className="steps-content">{steps[ current ].content}</div>

        </>
    );

};

export default AppItem;