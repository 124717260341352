import {$api} from "../http";

class StandartService {

    async addStandart( code, name, product_name, standart_link, status, description ) {
        return $api.post( '/standart', { code, name, product_name, standart_link, status, description } );
    }

    async getAllStandarts() {
        return $api.get( '/standart' );
    }

    async updateStandart( standartId, code, name, product_name, standart_link, status, description ) {
        return $api.put( `/standart/${standartId}`, { code, name, product_name, standart_link, status, description } );
    }

    async removeStandart( standartId ) {
        return $api.delete( `/standart/${standartId}` );
    }

    async getStandartById( standartId ) {
        return $api.get( `/standart/${standartId}` );
    }
}

export default new StandartService();

