import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeAnalysis, addAnalysis, updateAnalysis } from '../../actions/analysis';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
};

const AnalysisModal = ( { visible, onCreate, onCancel, history } ) => {

    const analysis = useSelector( state => state.analysis.analysis );
    const isLoading = useSelector( state => state.analysis.isLoading );
    const dispatch = useDispatch();

    const [ analysisData, setAnalysisData ] = useState( analysis || initialState );

    useEffect( () => {
        setAnalysisData( analysis !== null ? analysis : initialState );
    }, [ analysis ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newAnalysisData = {
                    name: values.name
                };
                const newData = { ...analysisData, ...newAnalysisData };
                setAnalysisData( newData );
                if ( analysis !== null && analysis._id ) {
                    dispatch( updateAnalysis( analysis._id, newData ) );
                } else {
                    dispatch( addAnalysis( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    return (
        <Modal
            visible={visible}
            title={analysis !== null && analysis._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                analysis !== null && analysis._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeAnalysis( analysis._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {analysis !== null && analysis._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formInit={form}
                name="analysisForm"
                size="middle"
                initValues={analysisData}
            >
                <FormField name="name" label="Наименование"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default AnalysisModal;