import React, { useEffect } from 'react';
import { Row, Col, Form, Input, Button, Avatar } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { login } from '../../actions/auth';

import './Auth.css';
import appNotification from "../../components/AppNotification/AppNotification";

const Auth = () => {
    const dispatch = useDispatch();
    const notify = useSelector( state => state.notifications.notify );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    const onFinish = ( values ) => {
        dispatch( login( values ) );
    };

    const onFinishFailed = ( errorInfo ) => {
        console.log( 'Failed:', errorInfo );
    };
    return (
        <Row justify="center" align="middle" className="login-form-wrapper">
            <Col xs={24} xl={4}>
                <p className="login-form-avatar"><Avatar size={64} icon={<UserOutlined/>}/></p>
                <Form
                    size="large"
                    name="normal_login"
                    className="login-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="email"
                        rules={[ { required: true, message: 'Пожалуйста, введите ваш логин!' } ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Username"
                            type="email"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[ { required: true, message: 'Пожалуйста, введите свой пароль!' } ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default Auth;
