import React from 'react';

const Admin = () => {


    return (
        <div>
            <h1>Admin</h1>
        </div>
    );
};

export default Admin;
