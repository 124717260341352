import React, { useEffect, useState } from 'react';
import { Button, Empty, Popconfirm, Space, Typography } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import appNotification from "../../components/AppNotification/AppNotification";
import Loading from "../../components/Loading/Loading";
import '../Qsm.css';

const { Title } = Typography;
const Devices = () => {
    const dispatch = useDispatch();
    return (

        <>
            <Title level={3}>Журнал учета технических средств</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" htmlType="button" onClick={() => {
                }}>Добавить запись</Button>
            </Space>

            <div className="QsmTableWrapper">
                <table className="QsmTable colFixed">
                    <thead className="QsmTableHead">
                    <tr>
                        <th>Номер сертификата поверки / калибровки</th>
                        <th>Дата последней поверки / калибровки</th>
                        <th>Периодичность поверки / калибровки</th>
                        <th>Место проведения поверки</th>

                        <th>Дата следующей калибровки</th>
                        <th>Следующие точки калибровки</th>
                    </tr>
                    <tr>
                        <th>Дата верификации</th>
                        <th>Кем проведена верификация</th>
                        <th>Краткая характеристика верификации</th>
                    </tr>
                    <tr>
                        <th>Результаты промежуточной проверки оборудований</th>
                        <th>Рекомендации по результатам промежуточного контроля</th>
                        <th>Дата промежуточного контроля</th>
                    </tr>
                    <tr>
                        <th>№</th>
                        <th>Наименование</th>
                        <th>Тип</th>
                        <th>Заводской номер</th>
                        <th>Рабочий интервал</th>
                        <th>Погрешность, класс точности, цена деления</th>
                        <th>Принадлежность</th>
                        <th>Порядок эксплуатации и обслуживания</th>
                        <th>КОД СИ</th>
                        <th>Место размещения оборудования</th>
                        <th>Дата получения оборудования и пуска в эксплуатацию</th>
                        <th>Фирма производитель</th>
                        <th>Страна производитель</th>
                        <th>Климатические условия эксплуатации</th>
                        <th>Описание любых повреждений</th>
                        <th>Периодичность технического обслуживания</th>
                        <th>Ответсвенный за сохранность технического средства</th>
                        <th>Метрологические характеристики после проверки</th>
                        <th>ФОТО</th>
                        <th>Сведения о версии ПО</th>
                        <th>Наличие точек регулировок</th>
                        <th>Условия транспартировки</th>
                        <th>Информация о вводе в эксплуатацию</th>
                        <th>Примечание</th>
                    </tr>
                    </thead>
                </table>
            </div>
            </>
    )
};

export default  Devices;