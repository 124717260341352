import {
    ADD_STANDART,
    GET_STANDART,
    UPDATE_STANDART,
    DELETE_STANDART,
    STANDART_ERROR,
    GET_STANDARTS,
    CLEAR_STANDART,
    SET_STANDART_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import StandartService from "../services/StandartService";

// Get All Standarts
export const getAllStandarts = () => async dispatch => {
    dispatch( { type: SET_STANDART_LOADING } );
    dispatch( { type: CLEAR_STANDART } );
    try {
        const response = await StandartService.getAllStandarts();
        dispatch( {
            type: GET_STANDARTS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response.data.message ? e.response?.data?.message : e.response?.statusText}` ) );
        dispatch( {
            type: STANDART_ERROR
        } );
    }
};

// Get Standart by ID
export const getStandartById = ( standartId ) => async dispatch => {
    dispatch( { type: SET_STANDART_LOADING } );
    dispatch( { type: CLEAR_STANDART } );
    try {
        const response = await StandartService.getStandartById( standartId );
        dispatch( {
            type: GET_STANDART,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: STANDART_ERROR
        } );
    }
};


// Add position
export const addStandart = ( { code, name, product_name, standart_link, status, description } ) => async dispatch => {
    dispatch( { type: SET_STANDART_LOADING } );
    dispatch( { type: CLEAR_STANDART } );
    try {
        const response = await StandartService.addStandart( code, name, product_name, standart_link, status, description );
        dispatch( {
            type: ADD_STANDART,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllStandarts() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response.data.message ? e.response?.data?.message : e.response?.statusText}` ) );
        dispatch( {
            type: STANDART_ERROR
        } );
    }
};
// Update Standart
export const updateStandart = ( standartId, { code, name, product_name, standart_link, status, description } ) => async dispatch => {
    dispatch( { type: SET_STANDART_LOADING } );
    dispatch( { type: CLEAR_STANDART } );
    try {
        const response = await StandartService.updateStandart( standartId, code, name, product_name, standart_link, status, description );
        dispatch( {
            type: UPDATE_STANDART,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllStandarts() );
        dispatch( getStandartById( standartId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: STANDART_ERROR
        } );
    }
};

// Delete Standart
export const removeStandart = ( standartId ) => async dispatch => {
    dispatch( { type: SET_STANDART_LOADING } );
    dispatch( { type: CLEAR_STANDART } );
    try {
        await StandartService.removeStandart( standartId );
        dispatch( {
            type: DELETE_STANDART,
            payload: standartId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllStandarts() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: STANDART_ERROR
        } );
    }
};




