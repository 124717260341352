import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { updateProfile } from "../../actions/users";
import { SERVER_URL } from "../../http";
function getBase64( img, callback ) {
    const reader = new FileReader();
    reader.addEventListener( 'load', () => callback( reader.result ) );
    reader.readAsDataURL( img );
}

function beforeUpload( file ) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if ( !isJpgOrPng ) {
        message.error( 'You can only upload JPG/PNG file!' );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if ( !isLt2M ) {
        message.error( 'Image must smaller than 2MB!' );
    }
    return isJpgOrPng && isLt2M;
}

const Profile = () => {
    const [ loading, setLoading ] = useState( false );
    const [ imageUrl, setImageUrl ] = useState( false );
    const user = useSelector( state => state.auth.user );
    const dispatch = useDispatch();

    const handleChange = info => {
        if ( info.file.status === 'uploading' ) {
            setLoading( true );
            return;
        }
        if ( info.file.status === 'done' ) {
            // Get this url from response in real world.
            getBase64( info.file.originFileObj, imageUrl =>
                setImageUrl(imageUrl),
                setLoading( false )
            );
        }
    };
    const uploadImage = async options => {
        const { onSuccess, onError, file } = options;
        const fmData = new FormData();
        fmData.append("image", file);

        try {
            dispatch(updateProfile(user.id, fmData));
            onSuccess("Ok");
        } catch (err) {
            console.log("Error: ", err);
            onError({ err });
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={uploadImage}
            onChange={handleChange}
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }}/> : user?.avatar ? <img src={`${SERVER_URL}avatar/${user?.avatar}`} alt="avatar" style={{ width: '100%' }}/>  : uploadButton}
        </Upload>
    );
};

export default Profile;