import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './actions/auth';
import Loading from './components/Loading/Loading';
import AppRouter from "./components/AppRouter";
import Auth from "./pages/Auth/Auth";
import Wrapper from "./layout/Wrapper";

const App = () => {
    const user = useSelector( state => state.auth );
    const dispatch = useDispatch();

    useEffect( () => {
        if ( localStorage.getItem( 'token' ) ) {
            dispatch( checkAuth() );
        }
    }, [ dispatch ] );

    if ( user.isLoading ) {
        return <Loading/>
    }

    if ( !user.isAuth ) {
        return (
            <Auth/>
        );
    }

    return (
        <Router>
            <Wrapper>
                <AppRouter/>
            </Wrapper>
        </Router>
    );
};

export default App;
