import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, Row, Col, Space, Card } from 'antd';
import moment from 'moment';
import {
    updateApplication,
    addApplication,
} from '../../actions/applications';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";

const initialState = [ {
    labs: [],
} ];

const { Option } = Select;

const ApplicationTesting = ( { data, labs } ) => {

    const isLoading = useSelector( state => state.applications.isLoading );
    const dispatch = useDispatch();

    if ( data && data.decision ) {
        data.decision.labs.map( item => item.delivery_date = moment( item.delivery_date ) );
        data.decision.labs.map( item => item.protocol_date = moment( item.protocol_date ) );
    }

    const [ appData, setAppData ] = useState( data || initialState );

    useEffect( () => {
        setAppData( data !== null ? data : initialState );
    }, [ data ] );


    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {

                const newAppData = { ...appData.decision, labs: values.labs };
                const newData = { ...appData, decision: newAppData };
                setAppData( newData );
                console.log( newData )

                if ( appData !== null && appData._id ) {
                    dispatch( updateApplication( appData._id, newData ) );
                } else {
                    dispatch( addApplication( newData ) );
                }
                form.submit();
                form.resetFields();

            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },


        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },

        },
    };


    return (
        <>
            {isLoading ? <Loading/> : (
                <>

                    <AppForm
                        formItemLayout={formItemLayout}
                        formInit={form}
                        name="appForm"
                        size="middle"
                        labelWrap="true"
                        initValues={{
                            ...appData.decision
                        }}
                    >
                        <Row gutter={15}>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }}>
                                <Form.List name="labs">

                                    {( fields ) => (
                                        <Row gutter={15}>

                                            {fields.map( ( { key, name, ...restField } ) => (
                                                <Col xs={{ span: 24 }} sm={{ span: 12 }} key={key}>
                                                    <Card>
                                                        <p style={{ marginBottom: '15px' }}>Наименование
                                                            АИЛ: <b>{labs.filter( item => item._id === appData.decision.labs[ key ]?.lab )[ 0 ]?.name}</b>
                                                        </p>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            {...restField}
                                                            label="Дата сдачи продукции в АИЛ"
                                                            name={[ name, 'delivery_date' ]}
                                                            rules={[ {
                                                                required: true,
                                                                message: 'Дата сдачи в продукции АИЛ',
                                                            } ]}>
                                                            <DatePicker format='DD.MM.YYYY'/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            {...restField}
                                                            label="Дата оформления протокола"
                                                            name={[ name, 'protocol_date' ]}
                                                            rules={[ {
                                                                required: true,
                                                                message: 'Дата оформления протокола',
                                                            } ]}>
                                                            <DatePicker format='DD.MM.YYYY'/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            {...restField}
                                                            label="№ протокола"
                                                            name={[ name, 'protocol_number' ]}
                                                            rules={[ {
                                                                required: true,
                                                                message: '№ протокола',
                                                            } ]}>
                                                            <Input/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={[ name, 'result' ]}
                                                            label="Результаты испытаний"
                                                            rules={[ {
                                                                required: true,
                                                                message: 'Пожалуйста укажите Результаты испытаний',
                                                            } ]}>
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="Форма оплаты работ"
                                                                optionFilterProp="children"
                                                                name="result"
                                                                filterOption={( input, option ) =>
                                                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                                }
                                                            >
                                                                <Option value="Соответствует"
                                                                        selected>Соответствует</Option>
                                                                <Option value="Не соответствует">Не
                                                                    соответствует</Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            {...restField}
                                                            label="Ф.И.О испытателя"
                                                            name={[ name, 'tester' ]}>
                                                            <Input/>
                                                        </Form.Item>

                                                    </Card>
                                                </Col>
                                            ) )}
                                        </Row>
                                    )}

                                </Form.List>


                            </Col>
                            <Col xs={24} sm={4} offset={10}>
                                <Space style={{marginTop: '15px'}}>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onFormSubmit}
                                    >
                                        {data !== null && data._id ? "Обновить" : "Добавить"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </AppForm>
                </>
            )}
        </>
    );
};

export default ApplicationTesting;