import {
    ADMIN_ROUTE,
    LOGIN_ROUTE,
    USERS_ROUTE,
    POSITIONS_ROUTE,
    DEPARTMENT_ROUTE,
    DASHBOARD_ROUTE,
    JOURNALQSMONE_ROUTE,
    JOURNALQSMTWO_ROUTE,
    JOURNALQSMTHREE_ROUTE,
    JOURNALQSMDESIGNATION_ROUTE,
    ROLES_ROUTE,
    RISKMAP_ROUTE,
    RISKTYPES_ROUTE,
    RISKADD_ROUTE,
    RISKS_ROUTE,
    ANALYSIS_ROUTE,
    INFLUENCES_ROUTE,
    SUBDIVISIONS_ROUTE,
    QSM_DOCTYPES_ROUTE,
    NONCONFORMITY_ROUTE,
    NONCONFORMITY_JOURNAL_ROUTE,
    STANDARTS_ROUTE,
    DEVICES_ROUTE,
    PROFILE_ROUTE,
    TNVED_ROUTE,
    LABS_ROUTE,
    CERT_SCHEMA_ROUTE,
    CLIENTS_ROUTE,
    APP_ROUTE,
    APPS_ROUTE,
    CBSOA_ROUTE,
    ECONOMIC_CODE_ROUTE,
    CERT_CODE_ROUTE
} from "./utils/consts";

import Admin from "./pages/Admin";
import Users from "./pages/Users/Users";
import Positions from "./pages/Positions/Positions";
import Departments from "./pages/Departments/Departments";
import Roles from "./pages/Roles/Roles";
import JournalQSMOne from "./pages/JournalQSM/JournalQSMOne";
import Dashboard from "./pages/Dashboard/Dashboard";
import Auth from "./pages/Auth/Auth";
import JournalQSMTwo from "./pages/JournalQSM/JournalQSMTwo";
import JournalQSMThree from "./pages/JournalQSM/JournalQSMThree";
import JournalQSMDesignation from "./pages/JournalQSM/JournalQSMDesignation";
import RiskMap from "./pages/Risks/RiskMap";
import RiskTypes from "./pages/Risks/RiskTypes";
import RiskAddJournal from "./pages/Risks/RiskAddJournal";
import RiskJournal from "./pages/Risks/RiskJournal";
import Analysis from "./pages/Analysis/Analysis";
import Influences from "./pages/Influences/Influences";
import Subdivisions from "./pages/Subdivisions/Subdivisions";
import QsmOneDocTypes from "./pages/QsmOneDocTypes/QsmOneDocTypes";
import Nonconformity from "./pages/Nonconformity/Nonconformity";
import NonconformityUser from "./pages/Nonconformity/NonconformityUser";
import Standarts from "./pages/Standarts/Standarts";
import Devices from "./pages/Devices/Devices";
import Profile from "./pages/Profile/Profile";
import Tnved from "./pages/Tnved/Tnved";
import CertSchema from "./pages/CertSchema/CertSchema";
import Labs from "./pages/Labs/Labs";
import Clients from "./pages/Clients/Clients";

import AppItem from "./pages/Applications/AppItem";
import Applications from "./pages/Applications/Applications";
import Cbsoa from "./pages/Cbsoa/Cbsoa";
import EconomicCode from "./pages/EconomicCode/EconomicCode";
import CertificationCode from "./pages/CertificationCode/CertificationCode";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: PROFILE_ROUTE,
        Component: Profile
    },
    {
        path: USERS_ROUTE,
        Component: Users
    },
    {
        path: POSITIONS_ROUTE,
        Component: Positions
    },
    {
        path: ROLES_ROUTE,
        Component: Roles
    },
    {
        path: DEPARTMENT_ROUTE,
        Component: Departments
    },
    {
        path: DASHBOARD_ROUTE,
        Component: Dashboard
    },
    {
        path: JOURNALQSMONE_ROUTE,
        Component: JournalQSMOne
    },
    {
        path: JOURNALQSMTWO_ROUTE,
        Component: JournalQSMTwo
    },
    {
        path: JOURNALQSMTHREE_ROUTE,
        Component: JournalQSMThree
    },
    {
        path: JOURNALQSMDESIGNATION_ROUTE,
        Component: JournalQSMDesignation
    },
    {
        path: NONCONFORMITY_ROUTE,
        Component: Nonconformity
    },
    {
        path: NONCONFORMITY_JOURNAL_ROUTE,
        Component: NonconformityUser
    },
    {
        path: RISKMAP_ROUTE,
        Component: RiskMap
    },
    {
        path: RISKTYPES_ROUTE,
        Component: RiskTypes
    },
    {
        path: RISKADD_ROUTE,
        Component: RiskAddJournal
    },
    {
        path: RISKS_ROUTE,
        Component: RiskJournal
    },
    {
        path: ANALYSIS_ROUTE,
        Component: Analysis
    },
    {
        path: INFLUENCES_ROUTE,
        Component: Influences
    },
    {
        path: SUBDIVISIONS_ROUTE,
        Component: Subdivisions
    },
    {
        path: QSM_DOCTYPES_ROUTE,
        Component: QsmOneDocTypes
    },
    {
        path: STANDARTS_ROUTE,
        Component: Standarts
    },
    {
        path: DEVICES_ROUTE,
        Component: Devices
    },
    {
        path: TNVED_ROUTE,
        Component: Tnved
    },
    {
        path: CERT_SCHEMA_ROUTE,
        Component: CertSchema
    },
    {
        path: LABS_ROUTE,
        Component: Labs
    },
    {
        path: CLIENTS_ROUTE,
        Component: Clients
    },
    {
        path: APP_ROUTE,
        Component: AppItem
    },
    {
        path: APPS_ROUTE,
        Component: Applications
    },
    {
        path: CBSOA_ROUTE,
        Component: Cbsoa
    },
    {
        path: ECONOMIC_CODE_ROUTE,
        Component: EconomicCode
    },
    {
        path: CERT_CODE_ROUTE,
        Component: CertificationCode
    },
];

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    }
];
