import {
    ADD_CERTIFICATION_CODE,
    GET_CERTIFICATION_CODE,
    UPDATE_CERTIFICATION_CODE,
    DELETE_CERTIFICATION_CODE,
    CERTIFICATION_CODE_ERROR,
    GET_CERTIFICATION_CODES,
    CLEAR_CERTIFICATION_CODE,
    SET_CERTIFICATION_CODE_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import CertificationCodeService from "../services/CertificationCodeService";

// Get All CertificationCode
export const getAllCertificationCode = () => async dispatch => {
    dispatch( { type: SET_CERTIFICATION_CODE_LOADING } );
    dispatch( { type: CLEAR_CERTIFICATION_CODE } );
    try {
        const response = await CertificationCodeService.getAllCertificationCode();
        dispatch( {
            type: GET_CERTIFICATION_CODES,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        console.log(e.response?.statusText);
        dispatch( {
            type: CERTIFICATION_CODE_ERROR
        } );
    }
};

// Get CertificationCode by ID
export const getCertificationCodeById = ( economicId ) => async dispatch => {
    dispatch( { type: SET_CERTIFICATION_CODE_LOADING } );
    dispatch( { type: CLEAR_CERTIFICATION_CODE } );
    try {
        const response = await CertificationCodeService.getCertificationCodeById( economicId );
        dispatch( {
            type: GET_CERTIFICATION_CODE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERTIFICATION_CODE_ERROR
        } );
    }
};


// Add CertificationCode
export const addCertificationCode = ( { code, name } ) => async dispatch => {
    dispatch( { type: SET_CERTIFICATION_CODE_LOADING } );
    dispatch( { type: CLEAR_CERTIFICATION_CODE } );
    try {
        const response = await CertificationCodeService.addCertificationCode( code, name );
        dispatch( {
            type: ADD_CERTIFICATION_CODE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllCertificationCode() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERTIFICATION_CODE_ERROR
        } );
    }
};
// Update CertificationCode
export const updateCertificationCode = ( economicId, { code, name } ) => async dispatch => {
    dispatch( { type: SET_CERTIFICATION_CODE_LOADING } );
    dispatch( { type: CLEAR_CERTIFICATION_CODE } );
    try {
        const response = await CertificationCodeService.updateCertificationCode( economicId, code, name );
        dispatch( {
            type: UPDATE_CERTIFICATION_CODE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllCertificationCode() );
        dispatch( getCertificationCodeById( economicId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERTIFICATION_CODE_ERROR
        } );
    }
};

// Delete CertificationCode
export const removeCertificationCode = ( economicId ) => async dispatch => {
    dispatch( { type: SET_CERTIFICATION_CODE_LOADING } );
    dispatch( { type: CLEAR_CERTIFICATION_CODE } );
    try {
        await CertificationCodeService.removeCertificationCode( economicId );
        dispatch( {
            type: DELETE_CERTIFICATION_CODE,
            payload: economicId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllCertificationCode() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERTIFICATION_CODE_ERROR
        } );
    }
};




