import {
    ADD_ANALYSIS,
    GET_ONE_ANALYSIS,
    UPDATE_ANALYSIS,
    DELETE_ANALYSIS,
    ANALYSIS_ERROR,
    GET_ANALYSIS,
    CLEAR_ANALYSIS,
    SET_ANALYSIS_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import AnalysisService from "../services/AnalysisService";

// Get All Analysis
export const getAllAnalysis = () => async dispatch => {
    dispatch( { type: SET_ANALYSIS_LOADING } );
    dispatch( { type: CLEAR_ANALYSIS } );
    try {
        const response = await AnalysisService.getAllAnalysis();
        dispatch( {
            type: GET_ANALYSIS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ANALYSIS_ERROR
        } );
    }
};

// Get Analysis by ID
export const getAnalysisById = ( analysisId ) => async dispatch => {
    dispatch( { type: SET_ANALYSIS_LOADING } );
    dispatch( { type: CLEAR_ANALYSIS } );
    try {
        const response = await AnalysisService.getAnalysisById( analysisId );
        dispatch( {
            type: GET_ONE_ANALYSIS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ANALYSIS_ERROR
        } );
    }
};


// Add Analysis
export const addAnalysis = ( { name } ) => async dispatch => {
    dispatch( { type: SET_ANALYSIS_LOADING } );
    dispatch( { type: CLEAR_ANALYSIS } );
    try {
        const response = await AnalysisService.addAnalysis( name );
        dispatch( {
            type: ADD_ANALYSIS,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllAnalysis() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ANALYSIS_ERROR
        } );
    }
};
// Update Analysis
export const updateAnalysis = ( analysisId, { name } ) => async dispatch => {
    dispatch( { type: SET_ANALYSIS_LOADING } );
    dispatch( { type: CLEAR_ANALYSIS } );
    try {
        const response = await AnalysisService.updateAnalysis( analysisId, name );
        dispatch( {
            type: UPDATE_ANALYSIS,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllAnalysis() );
        dispatch( getAnalysisById( analysisId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ANALYSIS_ERROR
        } );
    }
};

// Delete Analysis
export const removeAnalysis = ( analysisId ) => async dispatch => {
    dispatch( { type: SET_ANALYSIS_LOADING } );
    dispatch( { type: CLEAR_ANALYSIS } );
    try {
        await AnalysisService.removeAnalysis( analysisId );
        dispatch( {
            type: DELETE_ANALYSIS,
            payload: analysisId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllAnalysis() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ANALYSIS_ERROR
        } );
    }
};




