import {
    ADD_CLIENT,
    GET_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    CLIENT_ERROR,
    GET_CLIENTS,
    CLEAR_CLIENT,
    SET_CLIENT_LOADING
} from '../utils/consts';

const initialState = {
    client: null,
    clientsList: [],
    isLoading: false
};

const clients = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_CLIENT:
            return {
                ...state,
                clientsList: [ payload, ...state.clientsList ],
            };
        case GET_CLIENTS:
            return {
                ...state,
                clientsList: payload,
                isLoading: false,
            };
        case GET_CLIENT:
        case UPDATE_CLIENT:
            return {
                ...state,
                client: payload,
                isLoading: false
            };
        case DELETE_CLIENT:
            return {
                ...state,
                client: null,
                isLoading: false
            };
        case SET_CLIENT_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_CLIENT:
            return {
                ...state,
                client: null,
            };
        case CLIENT_ERROR:
            return {
                ...state,
                isLoading: false,
                client: null
            };
        default:
            return state;
    }
};

export default clients;