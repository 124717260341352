import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllCertSchema, removeCertSchema, getCertSchemaById } from '../../actions/certSchemas';
import Loading from "../../components/Loading/Loading";
import CertSchemaModal from "../../components/Modals/CertSchemaModal";
import appNotification from "../../components/AppNotification/AppNotification";
import { CLEAR_CERT_SCHEMA } from "../../utils/consts";

const { Title } = Typography;

const CertSchema = () => {

    const [ filteredInfo, setFilteredInfo ] = useState( null );
    const [ sortedInfo, setSortedInfo ] = useState( null );
    const [ visible, setVisible ] = useState( false );

    const certSchemasList = useSelector( state => state.certSchemas.certSchemasList );
    const isLoading = useSelector( state => state.certSchemas.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const dispatch = useDispatch();

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllCertSchema() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    let sorted = sortedInfo;
    let filtered = filteredInfo;
    sorted = sorted || {};
    filtered = filtered || {};

    // change filters and sorts
    const handleChange = ( pagination, filters, sorter ) => {
        setFilteredInfo( filters );
        setSortedInfo( sorter );
    };
    // clear filter and sorts
    const clearAll = () => {
        setFilteredInfo( null );
        setSortedInfo( null );
    };

    let schemaCode = [];
    new Set( certSchemasList.map( ( schema ) => schema.code ) ).forEach( val => schemaCode.push( val ) );

    const columns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 60,
            render: ( text, record, index ) => index + 1,
        },
        {
            title: '№ схемы',
            dataIndex: 'code',
            key: 'code',
            width: 150,
            render: ( text, record ) => ( <Button type="link" size="small" onClick={() => {
                dispatch( getCertSchemaById( record._id ) );
                setVisible( true );
            }}>{text}</Button> ),
            filters: ( certSchemasList.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.code || null,
            onFilter: ( value, record ) => record.code.toString().toLowerCase().includes(value.toString().toLowerCase()),
            sorter: ( a, b ) => a.code - b.code,
            sortOrder: sorted.columnKey === 'code' && sorted.order,
        },
        {
            title: 'Вид испытания',
            dataIndex: 'test_type',
            key: 'test_type',
        },
        {
            title: 'Обследования производства',
            dataIndex: 'survey',
            key: 'survey',
        },
        {
            title: 'Инспекционный контроль',
            dataIndex: 'inspection',
            key: 'inspection',
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            render: ( text, record ) => (
                <>
                    <Button type="primary" icon={<EditOutlined/>} size="small" onClick={() => {
                        dispatch( getCertSchemaById( record._id ) );
                        setVisible( true );
                    }}/>
                    {
                        certSchemasList.length >= 1 ? (
                            <Popconfirm title="Вы уверены что хотите удалить запись ?"
                                        onConfirm={() => dispatch( removeCertSchema( record._id ) )}>
                                <Button type="primary" danger icon={<DeleteOutlined/>} size="small"/>
                            </Popconfirm>
                        ) : null
                    }
                </>
            ),
            width: 150,
            fixed: 'right'
        },
    ];

    return isLoading ? <Loading/> : (
        <>
            <Title level={3}>Схемы сертификации</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button onClick={clearAll}>Очистить фильтры</Button>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_CERT_SCHEMA } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            <Table
                bordered
                rowKey={( record ) => record._id}
                columns={columns}
                dataSource={certSchemasList}
                onChange={handleChange}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1200, y: 500 }}
            />
            <CertSchemaModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </>
    );

};

export default CertSchema;