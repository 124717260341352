import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeInfluence, addInfluence, updateInfluence } from '../../actions/influences';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
};

const InfluenceModal = ( { visible, onCreate, onCancel, history } ) => {

    const influence = useSelector( state => state.influences.influence );
    const isLoading = useSelector( state => state.influences.isLoading );
    const dispatch = useDispatch();

    const [ influenceData, setInfluenceData ] = useState( influence || initialState );

    useEffect( () => {
        setInfluenceData( influence !== null ? influence : initialState );
    }, [ influence ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newInfluenceData = {
                    name: values.name
                };
                const newData = { ...influenceData, ...newInfluenceData };
                setInfluenceData( newData );
                if ( influence !== null && influence._id ) {
                    dispatch( updateInfluence( influence._id, newData ) );
                } else {
                    dispatch( addInfluence( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    return (
        <Modal
            visible={visible}
            title={influence !== null && influence._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                influence !== null && influence._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeInfluence( influence._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {influence !== null && influence._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formInit={form}
                name="influenceForm"
                size="middle"
                initValues={influenceData}
            >
                <FormField name="name" label="Наименование"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default InfluenceModal;