import {
    QSMONE_ERROR,
    CLEAR_QSMONE,
    DELETE_QSMONE,
    UPDATE_QSMONE,
    GET_QSMONE,
    GET_ALLQSMONE,
    ADD_QSMONE,
    SET_QSMONE_LOADING,
} from '../utils/consts';

const initialState = {
    qsmOneData: null,
    qsmOneDataList: [],
    isLoading: false
};

const qsmOne = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_QSMONE:
            return {
                ...state,
                qsmOneDataList: [ payload, ...state.qsmOneDataList ],
            };
        case GET_ALLQSMONE:
            return {
                ...state,
                qsmOneDataList: payload,
                isLoading: false,
            };
        case GET_QSMONE:
        case UPDATE_QSMONE:
            return {
                ...state,
                qsmOneData: payload,
                isLoading: false
            };
        case DELETE_QSMONE:
            return {
                ...state,
                qsmOneData: null,
                isLoading: false
            };
        case SET_QSMONE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_QSMONE:
            return {
                ...state,
                qsmOneData: null,
            };
        case QSMONE_ERROR:
            return {
                ...state,
                isLoading: false,
                qsmOneData: null
            };
        default:
            return state;
    }
};

export default qsmOne;