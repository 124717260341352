import {
    ADD_RISKTYPE,
    GET_RISKTYPE,
    UPDATE_RISKTYPE,
    DELETE_RISKTYPE,
    RISKTYPE_ERROR,
    GET_RISKTYPES,
    CLEAR_RISKTYPE,
    SET_RISKTYPE_LOADING,
} from '../utils/consts';

const initialState = {
    riskType: null,
    riskTypes: [],
    isLoading: false
};

const riskTypes = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_RISKTYPE:
            return {
                ...state,
                riskTypes: [ payload, ...state.riskTypes ],
            };
        case GET_RISKTYPES:
            return {
                ...state,
                riskTypes: payload,
                isLoading: false,
            };
        case GET_RISKTYPE:
        case UPDATE_RISKTYPE:
            return {
                ...state,
                riskType: payload,
                isLoading: false
            };
        case DELETE_RISKTYPE:
            return {
                ...state,
                riskType: null,
                isLoading: false
            };
        case SET_RISKTYPE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_RISKTYPE:
            return {
                ...state,
                riskType: null,
            };
        case RISKTYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                riskType: null
            };
        default:
            return state;
    }
};

export default riskTypes;