import React, { useEffect, useState } from 'react';
import { Button, Empty, Popconfirm, Typography } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import appNotification from "../../components/AppNotification/AppNotification";
import { getAllRisks, getRiskById, removeRisk } from "../../actions/risks";
import { getAllUsers } from "../../actions/users";
import RiskModal from "../../components/Modals/RiskModal";
import '../Qsm.css';
import Loading from "../../components/Loading/Loading";

const { Title } = Typography;
const RiskJournal = () => {
    const dispatch = useDispatch();
    const risks = useSelector( state => state.risks.risks );
    const isLoading = useSelector( state => state.risks.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const [ visible, setVisible ] = useState( false );
    const [ eliminatesCount, setEliminatesCount ] = useState( 0 );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllRisks() );
        dispatch( getAllUsers() );
    }, [ dispatch ] );

    useEffect( () => {
        let elminateCout = 0;
        risks.map( risk => {
            if ( risk.risk_eliminates && risk.risk_eliminates.length > 0 ) {
                if ( risk.risk_eliminates.length > elminateCout ) {
                    elminateCout = risk.risk_eliminates.length;
                }
                setEliminatesCount( elminateCout );
            }
            return false;
        } );
    } );

    const onCreate = () => {
        setVisible( false );
    };


    const Cols = ( data ) => {

        if ( data.data && data.data.length > 0 ) {
            let arr = [];
            for ( let i = 0; i < ( eliminatesCount - data.data.length ) * 6; i++ ) {
                arr.push( i )
            }
            return (
                <React.Fragment>
                    {
                        data.data.map( ( item, idx ) => {
                                return (
                                    <React.Fragment key={item._id}>
                                        <td>{item.impact_assessment}</td>
                                        <td>{item.probability_score}</td>
                                        <td
                                            className={
                                                item.impact_assessment * item.probability_score > 0 && item.impact_assessment * item.probability_score <= 4 ? 'bg-green' :
                                                    item.impact_assessment * item.probability_score > 4 && item.impact_assessment * item.probability_score <= 9 ? 'bg-yellow' :
                                                        item.impact_assessment * item.probability_score > 9 && item.impact_assessment * item.probability_score <= 14 ? 'bg-orange' :
                                                            item.impact_assessment * item.probability_score > 14 ? 'bg-red' : ''
                                            }

                                        >{item.impact_assessment * item.probability_score}</td>
                                        <td>{item.risk_activity}</td>
                                        <td>{item.risk_activity_date}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.risk_responsible.map( (resp, index) => <p key={index}>{resp.name}</p> )}</td>
                                    </React.Fragment>
                                )
                            }
                        )
                    }
                    {
                        arr.map( ( item, index ) => {
                            return <td key={index}>-</td>
                        } )
                    }
                </React.Fragment>
            )
        } else {
            let arr = [];
            for ( let i = 0; i < eliminatesCount * 6; i++ ) {
                arr.push( i )
            }
            return <React.Fragment>
                {
                    arr.map( ( item, index ) => {
                        return <td key={index}>-</td>
                    } )
                }
            </React.Fragment>;
        }
    };

    return (
        <>
            <Title level={3}>Журнал управления рисками РСМ-LET-01-C</Title>
            {isLoading ? <Loading/> : risks.length > 0 ? (
                <div className="QsmTableWrapper">
                    <table className="QsmTable colFixed">
                        <thead className="QsmTableHead">
                        <tr>
                            <th rowSpan={2}>№ п/п</th>
                            <th rowSpan={2}>Дата<br/> обнаружения<br/> риска</th>
                            <th rowSpan={2}>Содержание<br/> обнаруженного риска</th>
                            <th rowSpan={2}>Ф.И.О. сотрудника,<br/> обнаружившего риск</th>
                            <th rowSpan={2}>Наименование риска<br/> (идентификация риска)</th>
                            <th rowSpan={2}>Возможные<br/> последствия риска</th>
                            <th rowSpan={2}>Причины риска</th>
                            <th rowSpan={2}>Оценка риска на<br/> текущий момент</th>
                            <th colSpan={3}>Первичная оценка риска</th>
                            <th rowSpan={2}>Мероприятия<br/>по устранению <br/>(минимизации) риска</th>
                            <th rowSpan={2}>Сроки выполнения<br/> мероприятий</th>
                            <th rowSpan={2}>Ответственный за<br/> выполнение</th>
                            {
                                risks.filter( ( item, index ) => index !== 0 && index < eliminatesCount ).map( ( item, index ) => (
                                    <React.Fragment key={index}>
                                        <th colSpan={3}>Повторная оценка риска после<br/> осуществленных
                                            мероприятий
                                        </th>
                                        <th rowSpan={2}>Мероприятия<br/>по устранению <br/>(минимизации)
                                            риска
                                        </th>
                                        <th rowSpan={2}>Сроки выполнения<br/> мероприятий</th>
                                        <th rowSpan={2}>Ответственный за<br/> выполнение</th>
                                    </React.Fragment>
                                ) )
                            }
                            <th rowSpan={2}>Статус риска<br/> (отметка о <br/>принятии риска)</th>
                            <th rowSpan={2} className="colFixed">Действия</th>
                        </tr>
                        <tr>
                            <th>Оценка<br/> влияния (I)</th>
                            <th>Оценка<br/> вероятности (P)</th>
                            <th>Оценка<br/> риска (I x P)</th>

                            {
                                risks.filter( ( item, index ) => index !== 0 && index < eliminatesCount ).map( ( item, index ) => (
                                    <React.Fragment key={index}>
                                        <th>Оценка<br/> влияния (I)</th>
                                        <th>Оценка<br/> вероятности (P)</th>
                                        <th>Оценка<br/> риска (I x P)</th>
                                    </React.Fragment>
                                ) )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            risks.map( ( risk, index ) => (
                                <tr key={risk._id}>
                                    <td>{index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{moment( risk.risk_date ).format( 'DD.MM.YYYY' )}</td>
                                    <td>{risk.risk_content}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{risk?.employee?.name}</td>
                                    <td>{risk.risk_name}</td>
                                    <td>{risk.potential_consequences}</td>
                                    <td>{risk.risk_reason}</td>
                                    <td
                                        className={
                                            risk.risk_eliminates[risk.risk_eliminates.length -1]?.impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score > 0 && risk.risk_eliminates[risk.risk_eliminates?.length -1].impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score <= 4 ? 'bg-green' :
                                                risk.risk_eliminates[risk.risk_eliminates.length -1]?.impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score > 4 && risk.risk_eliminates[risk.risk_eliminates?.length -1].impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score <= 9 ? 'bg-yellow' :
                                                    risk.risk_eliminates[risk.risk_eliminates.length -1]?.impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score > 9 && risk.risk_eliminates[risk.risk_eliminates?.length -1].impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score <= 14 ? 'bg-orange' :
                                                        risk.risk_eliminates[risk.risk_eliminates.length -1]?.impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score > 14 ? 'bg-red' : ''
                                        }


                                    >
                                        {
                                        risk.risk_eliminates[risk.risk_eliminates.length -1]?.impact_assessment *  risk.risk_eliminates[risk.risk_eliminates.length -1]?.probability_score
                                    }
                                    </td>
                                    <Cols data={risk.risk_eliminates}/>
                                    <td>{risk.status}</td>
                                    <td>
                                        <>
                                            <Button type="primary" icon={<EditOutlined/>} size="small"
                                                    onClick={() => {
                                                        dispatch( getRiskById( risk._id ) );
                                                        setVisible( true );
                                                    }}/>
                                            {
                                                risks && risks.length >= 1 ? (
                                                    <Popconfirm
                                                        title="Вы уверены что хотите удалить запись ?"
                                                        onConfirm={() => dispatch( removeRisk( risk._id ) )}>
                                                        <Button type="primary" danger
                                                                icon={<DeleteOutlined/>} size="small"/>
                                                    </Popconfirm>
                                                ) : null
                                            }
                                        </>
                                    </td>
                                </tr>
                            ) )
                        }
                        </tbody>
                    </table>
                </div>

            ) : ( <Empty/> )}
            <RiskModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </> )
};

export default RiskJournal;