import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeEconomicCode, addEconomicCode, updateEconomicCode } from '../../actions/economicCode';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    code: '',
    name: '',
};

const EconomicCodeModal = ( { visible, onCreate, onCancel } ) => {

    const economicCode = useSelector( state => state.economicCode.economicCode );
    const isLoading = useSelector( state => state.economicCode.isLoading );
    const dispatch = useDispatch();

    const [ economicCodeData, setEconomicCodeData ] = useState( economicCode || initialState );

    useEffect( () => {
        setEconomicCodeData( economicCode !== null ? economicCode : initialState );
    }, [ economicCode ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newCertificationCodeData = {
                    code: values.code,
                    name: values.name
                };
                const newData = { ...economicCodeData, ...newCertificationCodeData };
                setEconomicCodeData( newData );
                if ( economicCode !== null && economicCode._id ) {
                    dispatch( updateEconomicCode( economicCode._id, newData ) );
                } else {
                    dispatch( addEconomicCode( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={economicCode !== null && economicCode._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={700}
            footer={[
                economicCode !== null && economicCode._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeEconomicCode( economicCode._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {economicCode !== null && economicCode._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="economicCodeForm"
                size="middle"
                initValues={economicCodeData}
            >
                <FormField name="code" label="Код направления"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Код направления!',
                               whitespace: true
                           } ]}>
                    <Input name="code"/>
                </FormField>
                <FormField name="name" label="Объект сертификации продукции"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Наименование Объект сертификации продукции!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default EconomicCodeModal;