import {$api} from "../http";

class CbsoaService {

    async addCbsoa( code, name, objects ) {
        return $api.post( '/cbsoa', { code, name, objects } );
    }

    async getAllCbsoa() {
        return $api.get( '/cbsoa' );
    }

    async updateCbsoa( cbsoaid, code, name, objects ) {
        return $api.put( `/cbsoa/${cbsoaid}`, { code, name, objects } );
    }

    async removeCbsoa( cbsoaid ) {
        return $api.delete( `/cbsoa/${cbsoaid}` );
    }

    async getCbsoaById( cbsoaid ) {
        return $api.get( `/cbsoa/${cbsoaid}` );
    }
}

export default new CbsoaService();

