import {
    ADD_CBSOA,
    GET_CBSOA,
    UPDATE_CBSOA,
    DELETE_CBSOA,
    CBSOA_ERROR,
    GET_CBSOAS,
    CLEAR_CBSOA,
    SET_CBSOA_LOADING
} from '../utils/consts';

const initialState = {
    cbsoaItem: null,
    cbsoaItems: [],
    isLoading: false
};

const cbsoa = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_CBSOA:
            return {
                ...state,
                cbsoaItems: [ payload, ...state.cbsoaItems ],
            };
        case GET_CBSOAS:
            return {
                ...state,
                cbsoaItems: payload,
                isLoading: false,
            };
        case GET_CBSOA:
        case UPDATE_CBSOA:
            return {
                ...state,
                cbsoaItem: payload,
                isLoading: false
            };
        case DELETE_CBSOA:
            return {
                ...state,
                cbsoaItem: null,
                isLoading: false
            };
        case SET_CBSOA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_CBSOA:
            return {
                ...state,
                cbsoaItem: null,
            };
        case CBSOA_ERROR:
            return {
                ...state,
                isLoading: false,
                cbsoaItem: null
            };
        default:
            return state;
    }
};

export default cbsoa;