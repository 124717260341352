import {
    ADD_USER,
    USER_ERROR,
    GET_USERS,
    CLEAR_USER,
    SET_USER_LOADING,
    GET_USER,
    UPDATE_USER,
    DELETE_USER
} from '../utils/consts';

const initialState = {
    user: null,
    users: [],
    isLoading: false
};

const users = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_USER:
            return {
                ...state,
                users: [ payload, ...state.users ],
                isLoading: false,
            };
        case GET_USERS:
            return {
                ...state,
                users: payload,
                isLoading: false,
            };
        case GET_USER:
        case UPDATE_USER:
            return {
                ...state,
                user: payload,
                isLoading: false
            };
        case DELETE_USER:
            return {
                ...state,
                user: null,
                isLoading: false
            };
        case SET_USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_USER:
            return {
                ...state,
                user: null,
            };
        case USER_ERROR:
            return {
                ...state,
                isLoading: false,
                user: null
            };
        default:
            return state;
    }
};

export default users;