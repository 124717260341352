import {$api} from "../http";

class PositionService {

    async addPosition( name ) {
        return $api.post( '/position', { name } );
    }

    async getAllPositions() {
        return $api.get( '/position' );
    }

    async updatePosition( positionId, name ) {
        return $api.put( `/position/${positionId}`, { name } );
    }

    async removePosition( positionId ) {
        return $api.delete( `/position/${positionId}` );
    }

    async getPositionById( positionId ) {
        return $api.get( `/position/${positionId}` );
    }
}

export default new PositionService();

