import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllTnved, removeTnved, getTnvedById } from '../../actions/tnveds';
import { getAllCertificationCode } from '../../actions/certificationCode';
import { getAllEconomicCode } from '../../actions/economicCode';
import { getAllStandarts } from '../../actions/standarts';
import Loading from "../../components/Loading/Loading";
import TnvedModal from "../../components/Modals/TnvedModal";
import appNotification from "../../components/AppNotification/AppNotification";
import { CLEAR_TNVED } from "../../utils/consts";

const { Title } = Typography;

const Tnveds = () => {

    const [ filteredInfo, setFilteredInfo ] = useState( null );
    const [ sortedInfo, setSortedInfo ] = useState( null );
    const [ visible, setVisible ] = useState( false );

    const tnvedsList = useSelector( state => state.tnveds.tnvedsList );
    const isLoading = useSelector( state => state.tnveds.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const dispatch = useDispatch();

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllTnved() );
        dispatch( getAllCertificationCode() );
        dispatch( getAllEconomicCode() );
        dispatch( getAllStandarts() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    let sorted = sortedInfo;
    let filtered = filteredInfo;
    sorted = sorted || {};
    filtered = filtered || {};

    // change filters and sorts
    const handleChange = ( pagination, filters, sorter ) => {
        setFilteredInfo( filters );
        setSortedInfo( sorter );
    };
    // clear filter and sorts
    const clearAll = () => {
        setFilteredInfo( null );
        setSortedInfo( null );
    };

    let tnvedsCode = [];
    let tnvedsName = [];
    let tnvedsEconomic = [];
    let tnvedsCertification = [];

    new Set( tnvedsList.map( ( tnved ) => tnved.code ) ).forEach( val => tnvedsCode.push( val ) );
    new Set( tnvedsList.map( ( tnved ) => tnved.name ) ).forEach( val => tnvedsName.push( val ) );
    new Set( tnvedsList.map( ( tnved ) => tnved?.economic_code?.code ) ).forEach( val => tnvedsEconomic.push( val ) );
    new Set( tnvedsList.map( ( tnved ) => tnved?.certification_code?.code ) ).forEach( val => tnvedsCertification.push( val ) );

    const columns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 60,
            render: ( text, record, index ) => index + 1,
        },
        {
            title: 'Код ТН ВЭД',
            dataIndex: 'code',
            key: 'code',
            width: 150,
            render: ( text, record ) => ( <Button type="link" size="small" onClick={() => {
                dispatch( getTnvedById( record._id ) );
                setVisible( true );
            }}>{text}</Button> ),
            filters: ( tnvedsCode.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.code || null,
            onFilter: ( value, record ) => record.code.toString().toLowerCase().includes(value.toString().toLowerCase()),
            sorter: ( a, b ) => a.code - b.code,
            sortOrder: sorted.columnKey === 'code' && sorted.order,
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            filters: ( tnvedsName.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.name || null,
            onFilter: ( value, record ) => record.name.includes( value ),
            sorter: ( a, b ) => a.name.length - b.name.length,
            sortOrder: sorted.columnKey === 'name' && sorted.order,
        },
        {
            title: 'Код экономического сектора',
            dataIndex: 'economic_code',
            key: 'economic_code',
            width: 200,
            render: ( text, record, index ) => record.economic_code?.code,
            filters: ( tnvedsEconomic.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.economic_code?.code || null,
            onFilter: ( value, record ) => record.economic_code?.code.toString().toLowerCase().includes(value.toString().toLowerCase()),
            sorter: ( a, b ) => a.economic_code?.code - b.economic_code?.code,
            sortOrder: sorted.columnKey === 'economic_code' && sorted.order,
        },
        {
            title: 'Код объекта сертификации',
            dataIndex: 'certification_code',
            key: 'certification_code',
            width: 200,
            render: ( text, record, index ) => record.certification_code?.code,
            filters: ( tnvedsCertification.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.certification_code?.code || null,
            onFilter: ( value, record ) => record.certification_code?.code.toString().toLowerCase().includes(value.toString().toLowerCase()),
            sorter: ( a, b ) => a.certification_code?.code.length - b.certification_code?.code.length,
            sortOrder: sorted.columnKey === 'certification_code' && sorted.order,
        },
        {
            title: 'Нормативные документы',
            dataIndex: 'standarts',
            key: 'standarts',
            render: ( text, record ) => record.standarts?.map(standart => standart.code).join(', '),
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            render: ( text, record ) => (
                <>
                    <Button type="primary" icon={<EditOutlined/>} size="small" onClick={() => {
                        dispatch( getTnvedById( record._id ) );
                        setVisible( true );
                    }}/>
                    {
                        tnvedsList.length >= 1 ? (
                            <Popconfirm title="Вы уверены что хотите удалить запись ?"
                                        onConfirm={() => dispatch( removeTnved( record._id ) )}>
                                <Button type="primary" danger icon={<DeleteOutlined/>} size="small"/>
                            </Popconfirm>
                        ) : null
                    }
                </>
            ),
            width: 150,
            fixed: 'right'
        },
    ];

    return isLoading ? <Loading/> : (
        <>
            <Title level={3}>Коды ТН ВЭД</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button onClick={clearAll}>Очистить фильтры</Button>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_TNVED } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            <Table
                bordered
                rowKey={( record ) => record._id}
                columns={columns}
                dataSource={tnvedsList}
                onChange={handleChange}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1200, y: 500 }}
            />
            <TnvedModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </>
    );

};

export default Tnveds;