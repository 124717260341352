import {
    ADD_QSMONE,
    GET_QSMONE,
    UPDATE_QSMONE,
    DELETE_QSMONE,
    QSMONE_ERROR,
    GET_ALLQSMONE,
    CLEAR_QSMONE,
    SET_QSMONE_LOADING,
} from '../utils/consts';

import { setNotify } from './notications';

import QsmService from "../services/QsmOneService";

// Get All Qsm One
export const getAllQsmOne = () => async dispatch => {
    dispatch( { type: SET_QSMONE_LOADING } );
    dispatch( { type: CLEAR_QSMONE } );
    try {
        const response = await QsmService.getAllQsmOne();
        dispatch( {
            type: GET_ALLQSMONE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONE_ERROR
        } );
    }
};

// Get QSM One by Id
export const getQsmOneById = ( qsmId ) => async dispatch => {
    dispatch( { type: SET_QSMONE_LOADING } );
    dispatch( { type: CLEAR_QSMONE } );
    try {
        const response = await QsmService.getQsmOneById( qsmId );
        dispatch( {
            type: GET_QSMONE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONE_ERROR
        } );
    }
};

// Add QSM one
export const addQsmOne = (
    {
        qsmdoctype,
        document_code,
        document_name,
        standart_clause,
        approval_date,
        introduction_date,
        actual_last,
        actual_next,
        responsibility_dev,
        responsibility_approval,
        responsibility_agreement,
        responsibility_acquaintance,
        responsibility_keeping,
        status,
        document_link,
        journalqsmone_mailing
    }
) => async dispatch => {
    dispatch( { type: SET_QSMONE_LOADING } );
    dispatch( { type: CLEAR_QSMONE } );
    try {
        const response = await QsmService.addQsmOne(
            qsmdoctype,
            document_code,
            document_name,
            standart_clause,
            approval_date,
            introduction_date,
            actual_last,
            actual_next,
            responsibility_dev,
            responsibility_approval,
            responsibility_agreement,
            responsibility_acquaintance,
            responsibility_keeping,
            status,
            document_link,
            journalqsmone_mailing
        );
        dispatch( {
            type: ADD_QSMONE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllQsmOne() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONE_ERROR
        } );
    }
};
// Update QSM One
export const updateQsmOne = (
    qsmId,
    {
        qsmdoctype,
        document_code,
        document_name,
        standart_clause,
        approval_date,
        introduction_date,
        actual_last,
        actual_next,
        responsibility_dev,
        responsibility_approval,
        responsibility_agreement,
        responsibility_acquaintance,
        responsibility_keeping,
        status,
        document_link,
        journalqsmone_mailing
    }
) => async dispatch => {
    dispatch( { type: SET_QSMONE_LOADING } );
    dispatch( { type: CLEAR_QSMONE } );
    try {
        const response = await QsmService.updateQsmOne(
            qsmId,
            qsmdoctype,
            document_code,
            document_name,
            standart_clause,
            approval_date,
            introduction_date,
            actual_last,
            actual_next,
            responsibility_dev,
            responsibility_approval,
            responsibility_agreement,
            responsibility_acquaintance,
            responsibility_keeping,
            status,
            document_link,
            journalqsmone_mailing
        );
        dispatch( {
            type: UPDATE_QSMONE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllQsmOne() );
        dispatch( getQsmOneById( qsmId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONE_ERROR
        } );
    }
};

// DELETE QSM One
export const removeQsmOne = ( qsmId ) => async dispatch => {
    dispatch( { type: SET_QSMONE_LOADING } );
    dispatch( { type: CLEAR_QSMONE } );
    try {
        await QsmService.removeQsmOne( qsmId );
        dispatch( {
            type: DELETE_QSMONE,
            payload: qsmId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllQsmOne() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONE_ERROR
        } );
    }
};




