import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, Row, Col, Space, Checkbox } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import {
    updateApplication,
    addApplication,
    generateDecisionPdf
} from '../../actions/applications';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";

const initialState = {
    decision_number: 0,
    decision_date: moment(),
    survey_type: 'Без обследования производства',
    survey_address: '',
    cert_docs: '',
    labs: [{lab: ''}],
    payment_form: 'Хозяйственный договор',
    decision_executor: '',
    after_installation: false,
};

const { TextArea } = Input;
const { Option } = Select;

const ApplicationDecision = ( { data, labs, users } ) => {

    const isLoading = useSelector( state => state.applications.isLoading );
    const dispatch = useDispatch();

    if ( data && data.decision ) {
        data.decision.decision_date = moment( data.decision.decision_date );
    }

    const [ appData, setAppData ] = useState( data || initialState );

    useEffect( () => {
        setAppData( data !== null ? data : initialState );
    }, [ data ] );


    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                let labs = [];
                values.labs.map(item => labs.push({lab: item}));
                const newAppData = {
                    decision_number: values.decision_number,
                    decision_date: values.decision_date,
                    survey_type: values.survey_type,
                    survey_address: values.survey_address,
                    cert_docs: values.cert_docs,
                    labs: labs,
                    payment_form: values.payment_form,
                    decision_executor: values.decision_executor,
                    after_installation: values.after_installation,
                };

                const newData = { ...appData, decision: newAppData };
                console.log(newData);
                setAppData( newData );
                if ( appData !== null && appData._id ) {
                    dispatch( updateApplication( appData._id, newData ) );
                } else {
                    dispatch( addApplication( newData ) );
                }
                form.submit();
                form.resetFields();

            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },


        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },

        },
    };


    return (
        <>
            {isLoading ? <Loading/> : (
                <>

                    <AppForm
                        formItemLayout={formItemLayout}
                        formInit={form}
                        name="appForm"
                        size="middle"
                        labelWrap="true"
                        initValues={{
                            ...appData.decision,
                            labs: [...appData.decision?.labs?.map(item => item.lab)],
                        }}
                    >
                        <Row gutter={15}>
                            <Col xs={24}>
                                <Space style={{ marginBottom: '20px' }}>
                                    {data !== null && data._id ?
                                        <Button type="primary" icon={<DownloadOutlined/>}
                                                onClick={() => dispatch( generateDecisionPdf( data._id ) )}
                                        >
                                            Решение
                                        </Button> : ""
                                    }
                                </Space>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 4 }}>
                                <Form.Item name="decision_number" label="№ решения"
                                           rules={[ {
                                               required: true,
                                               message: 'Пожалуйста введите № решения',
                                           } ]}>
                                    <Input name="decision_number"/>
                                </Form.Item>
                                <Form.Item
                                    name="decision_date" label="Дата решения по заявке"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Дата решения по заявке',
                                    } ]}>
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="survey_type"
                                    label="Вид обследования"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Вид обследования',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Вид обследования"
                                        optionFilterProp="children"
                                        name="survey_type"
                                        filterOption={( input, option ) =>
                                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                        }
                                    >
                                        <Option value="Без обследования производства" selected>Без обследования
                                            производства</Option>
                                        <Option value="Обследование состояния производства">Обследование состояния
                                            производства</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="survey_address"
                                    label="Наименование и адрес где будет проводиться обследование">
                                    <TextArea name="survey_address"/>
                                </Form.Item>
                                <Form.Item
                                    name="labs"
                                    label="Наименование АИЛ"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста выберите АИЛ',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Наименование АИЛ"
                                        optionFilterProp="children"
                                        mode="multiple"
                                        filterOption={( input, option ) =>
                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                        }
                                    >
                                        {labs.map( item => <Option key={item._id} value={item._id}
                                                                   selected>{item.registry_number} {item.name}</Option> )}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="cert_docs" label="Документы для целей сертификации">
                                    <Input name="cert_docs"/>
                                </Form.Item>
                                <Form.Item
                                    name="payment_form"
                                    label="Форма оплаты работ"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста выберите Форма оплаты работ',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Форма оплаты работ"
                                        optionFilterProp="children"
                                        name="payment_form"
                                        filterOption={( input, option ) =>
                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                        }
                                    >
                                        <Option value="Хозяйственный договор" selected>Хозяйственный договор</Option>
                                        <Option value="Наличные">Наличные</Option>
                                        <Option value="Перечисление">Перечисление</Option>
                                        <Option value="Тариф">Тариф</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="decision_executor"
                                    label="Исполнитель"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Ответственный исполнитель!',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Исполнитель"
                                        optionFilterProp="children"
                                        name="decision_executor"
                                        filterOption={( input, option ) =>
                                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                        }
                                    >
                                        {users?.filter( item => item?.department?.name.includes('Орган по сертификации продукции') || item?.department?.name.includes('АУП')).map(item => <Option key={item._id} value={item._id} selected>{item.name}</Option>)}

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    valuePropName="checked"
                                    name="after_installation"
                                    label="Сертификация будет осуществлятся после монтажа и установки оборудования"
                                >
                                    <Checkbox>

                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} offset={10}>
                                <Space>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onFormSubmit}
                                    >
                                        {data !== null && data._id ? "Обновить" : "Добавить"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </AppForm>
                </>
            )}
        </>
    );
};

export default ApplicationDecision;