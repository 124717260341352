import {$api} from "../http";

class ClientsService {
    async addClients( type, name, address, phone, email ) {
        return $api.post( '/clients', { type, name, address, phone, email } );
    }

    async getAllClients() {
        return $api.get( '/clients' );
    }

    async updateClients( clientsId, type, name, address, phone, email ) {
        return $api.put( `/clients/${clientsId}`, { type, name, address, phone, email } );
    }

    async removeClients( clientsId ) {
        return $api.delete( `/clients/${clientsId}` );
    }

    async getClientsById( clientsId ) {
        return $api.get( `/clients/${clientsId}` );
    }
}

export default new ClientsService();

