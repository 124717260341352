import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm, Select, InputNumber } from 'antd';
import { removeCbsoa, addCbsoa, updateCbsoa } from '../../actions/cbsoa';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";

const initialState = {
    code: '',
    name: '',
    objects: []
};
const { Option } = Select;

const CbsoaModal = ( { visible, onCreate, onCancel } ) => {

    const cbsoaItem = useSelector( state => state.cbsoa.cbsoaItem );
    const isLoading = useSelector( state => state.cbsoa.isLoading );
    const tnvedsList = useSelector( state => state.tnveds.tnvedsList );
    const dispatch = useDispatch();

    const [ cbsoaData, setCbsoaData ] = useState( cbsoaItem || initialState );

    useEffect( () => {
        setCbsoaData( cbsoaItem !== null ? cbsoaItem : initialState );
    }, [ cbsoaItem ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newTnvedData = {
                    code: values.code,
                    name: values.name,
                    objects: values.objects
                };
                const newData = { ...cbsoaData, ...newTnvedData };
                setCbsoaData( newData );
                if ( cbsoaItem !== null && cbsoaItem._id ) {
                    dispatch( updateCbsoa( cbsoaItem._id, newData ) );
                } else {
                    dispatch( addCbsoa( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={cbsoaItem !== null && cbsoaItem._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={700}
            footer={[
                cbsoaItem !== null && cbsoaItem._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeCbsoa( cbsoaItem._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {cbsoaItem !== null && cbsoaItem._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="cbsoaItemForm"
                size="middle"
                initValues={{
                    ...cbsoaData,
                    objects: [ ...cbsoaData.objects.map( item => item._id ) ]
                }}
            >
                <Form.Item name="code" label="№ Группы"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите № Группы',
                           } ]}>
                    <InputNumber/>
                </Form.Item>
                <Form.Item name="name" label="Наименование группы"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование группы!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </Form.Item>
                <Form.Item
                    name="objects"
                    label="Код ТН ВЭД"
                >
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder="Код ТН ВЭД"
                        optionFilterProp="children"
                        filterOption={( input, option ) =>
                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                        }
                    >
                        {tnvedsList.map( item => <Option key={item._id} value={item._id}
                                                         selected>{item.code}</Option> )}
                    </Select>
                </Form.Item>

            </AppForm>

        )}

        </Modal>
    );
};

export default CbsoaModal;