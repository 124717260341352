import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeRole, addRole, updateRole } from '../../actions/roles';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
};

const RoleModal = ( { visible, onCreate, onCancel } ) => {

    const role = useSelector( state => state.roles.role );
    const isLoading = useSelector( state => state.roles.isLoading );
    const dispatch = useDispatch();

    const [ roleData, setRoleData ] = useState( role || initialState );

    useEffect( () => {
        setRoleData( role !== null ? role : initialState )
    }, [ role ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newRoleData = {
                    name: values.name
                };
                const newData = { ...roleData, ...newRoleData };
                setRoleData( newData );
                if ( role !== null && role._id ) {
                    dispatch( updateRole( role._id, newData ) );
                } else {
                    dispatch( addRole( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    return (
        <Modal
            visible={visible}
            title={role !== null && role._id ? "Изменить роль" : "Добавить роль"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                role !== null && role._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeRole( role._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {role !== null && role._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formInit={form}
                name="roleForm"
                size="middle"
                initValues={roleData}
            >
                <FormField name="name" label="Наименование роли"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование роли!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>
        )}
        </Modal>
    );
};

export default RoleModal;