import React, { useEffect, useState } from 'react';
import { Button, Empty, Popconfirm, Space, Typography } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import appNotification from "../../components/AppNotification/AppNotification";
import Loading from "../../components/Loading/Loading";
import {
    getAllNonconformity,
    getLastNonconformity,
    getNonconformityById,
    removeNonconformity
} from "../../actions/nonconformity";
import { getAllUsers } from "../../actions/users";
import { getAllAnalysis } from "../../actions/analysis";
import { getAllSubdivision } from "../../actions/subdivisions";
import { getAllInfluence } from "../../actions/influences";
import NonconformityAddModal from "../../components/Modals/NonconformityAddModal";
import { CLEAR_NONCONFORMITY } from "../../utils/consts";
import '../Qsm.css';

const { Title } = Typography;

const Nonconformity = () => {
    const dispatch = useDispatch();
    const nonconformityList = useSelector( state => state.nonconformity.nonconformityList );
    const isLoading = useSelector( state => state.nonconformity.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const [ visible, setVisible ] = useState( false );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getLastNonconformity() );
        dispatch( getAllNonconformity() );
        dispatch( getAllUsers() );
        dispatch( getAllAnalysis() );
        dispatch( getAllSubdivision() );
        dispatch( getAllInfluence() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };
    return (
        <>
            <Title level={3}>Журнал регистрации несоответствий и корректирующих действий ПСМ-LET-03-А</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_NONCONFORMITY } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            {isLoading ? <Loading/> :  nonconformityList && nonconformityList.length > 0 ? (
                <div className="QsmTableWrapper">
                    <table className="QsmTable colFixed">
                        <thead className="QsmTableHead">
                        <tr>
                            <th rowSpan={2}>№ п/п</th>
                            <th rowSpan={2}>Код<br/> аудита</th>
                            <th rowSpan={2}>ID <br/>несоответсвия</th>
                            <th rowSpan={2}>Дата<br/> обнаружения<br/> несоответствия</th>
                            <th rowSpan={2}>Вид анализа<br/> (в ходе чего или<br/> при каких обстоятельствах <br/>обнаружено)
                            </th>
                            <th rowSpan={2}>Ф. И. О. сотрудника,<br/> обнаружившего<br/> несоответствие</th>
                            <th rowSpan={2}>Подразделение/<br/> сотрудник, в котором/<br/> у которого
                                выявлено<br/> несоответствие
                            </th>
                            <th rowSpan={2}>Сфера влияния<br/> несоответствия</th>
                            <th colSpan={2}>Документ, относительно которого<br/> выявлено несоответствие:</th>
                            <th rowSpan={2}>Описание несоответствия</th>
                            <th rowSpan={2}>Причина несоответсвия</th>
                            <th rowSpan={2}>Номер <br/>корректирующих <br/>действий</th>
                            <th rowSpan={2}>Содержание коррекции / <br/>корректирующего действия</th>
                            <th rowSpan={2}>Сроки устранения<br/> несоответствия</th>
                            <th rowSpan={2}>Ответственный по<br/> устранению несоответствия</th>
                            <th rowSpan={2}>Отметка о <br/>выполнении</th>
                            <th rowSpan={2}>Подтверждение выполнение<br/> корректирующих действий</th>
                            <th rowSpan={2}>Итоги анализа результативности <br/>корректирующих действий</th>
                            <th colSpan={6}>В случае не устранения или повторения выявленного несоответствия</th>



                            <th rowSpan={2}>Отметка о принятии <br/>корректирующих <br/>действий</th>
                            <th rowSpan={2} className="colFixed">Действия</th>
                        </tr>
                        <tr>
                            <th>обозначение документа</th>
                            <th>раздел, пункт документа</th>
                            <th>Дополнительные/новые <br/> действия по устранению несоответствия</th>
                            <th> Дата <br/>исполнения</th>
                            <th>Ответственный по<br/> устранению несоответствия</th>
                            <th>Отметка о <br/>выполнении</th>
                            <th>Подтверждение выполнение<br/> корректирующих действий</th>
                            <th>Итоги анализа результативности <br/>корректирующих действий</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            nonconformityList.map( ( nonconformity, index ) => (
                                <tr key={nonconformity._id}>
                                    <td>{index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        {nonconformity.audit_code < 9 ? `A-00${nonconformity.audit_code}` : nonconformity.audit_code < 100 ? `A-0${nonconformity.audit_code}` : `A-${nonconformity.audit_code}`}
                                    </td>
                                    <td>
                                        {nonconformity.nonconformity_code < 9 ? `H-00${nonconformity.nonconformity_code}` : nonconformity.nonconformity_code < 100 ? `H-0${nonconformity.nonconformity_code}` : `H-${nonconformity.nonconformity_code}`}
                                    </td>
                                    <td>{moment( nonconformity.nonconformity_date ).format( 'DD.MM.YYYY' )}</td>
                                    <td>{nonconformity?.analysis_type?.name}</td>
                                    <td>{nonconformity?.employee?.name}</td>
                                    <td>{nonconformity?.subdivision?.name}</td>
                                    <td>{nonconformity?.influence?.name}</td>
                                    <td>{nonconformity.document_designation}</td>
                                    <td>{nonconformity.document_section}</td>
                                    <td>{nonconformity.nonconformity_description}</td>
                                    <td>{nonconformity.nonconformity_reason}</td>
                                    <td>
                                        {nonconformity.corrective_number < 9 ? `K-00${nonconformity.corrective_number}` : nonconformity.corrective_number < 100 ? `K-0${nonconformity.corrective_number}` : `K-${nonconformity.corrective_number}`}
                                    </td>
                                    <td>{nonconformity.corrective_description}</td>
                                    <td>{nonconformity.elimination_terms}</td>
                                    <td>{
                                        nonconformity?.elimination_responsible?.map( ( respDev ) => (
                                            <p key={respDev._id}>{respDev.name}</p>
                                        ) )
                                    }</td>
                                    <td>{nonconformity.completion_mark}</td>
                                    <td>{nonconformity.corrective_confirmation}</td>
                                    <td>{nonconformity.analysis_result}</td>


                                    <td>{nonconformity.r_corrective_description}</td>
                                    <td>{nonconformity.r_elimination_terms}</td>
                                    <td>{
                                        nonconformity?.r_elimination_responsible?.map( ( respDev ) => (
                                            <p key={respDev._id}>{respDev.name}</p>
                                        ) )
                                    }</td>
                                    <td>{nonconformity.r_completion_mark}</td>
                                    <td>{nonconformity.r_corrective_confirmation}</td>
                                    <td>{nonconformity.r_analysis_result}</td>

                                    <td>{nonconformity.status}</td>

                                    <td>
                                        <>
                                            <Button type="primary" icon={<EditOutlined/>} size="small"
                                                    onClick={() => {
                                                        dispatch( getNonconformityById( nonconformity._id ) );
                                                        setVisible( true );
                                                    }}/>
                                            {
                                                nonconformityList && nonconformityList.length >= 1 ? (
                                                    <Popconfirm
                                                        title="Вы уверены что хотите удалить запись ?"
                                                        onConfirm={() => dispatch( removeNonconformity( nonconformity._id ) )}>
                                                        <Button type="primary" danger
                                                                icon={<DeleteOutlined/>} size="small"/>
                                                    </Popconfirm>
                                                ) : null
                                            }
                                        </>
                                    </td>
                                </tr>
                            ) )
                        }
                        </tbody>
                    </table>
                </div>

            ) : ( <Empty/> )}
            <NonconformityAddModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </> )
};

export default Nonconformity;