import {
    ADD_USER,
    GET_USER,
    UPDATE_USER,
    DELETE_USER,
    USER_ERROR,
    GET_USERS,
    SET_USER_LOADING,
    CLEAR_USER
} from '../utils/consts';

import { setNotify } from './notications';

import UserService from "../services/UserService";

// Get All Users
export const getAllUsers = () => async dispatch => {
    dispatch( { type: SET_USER_LOADING } );
    dispatch( { type: CLEAR_USER } );
    try {
        const response = await UserService.getAllUsers();
        dispatch( {
            type: GET_USERS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: USER_ERROR
        } );
    }
};

// Get User by ID
export const getUserById = ( userId ) => async dispatch => {
    dispatch( { type: SET_USER_LOADING } );
    dispatch( { type: CLEAR_USER } );
    try {
        const response = await UserService.getUserById( userId );
        dispatch( {
            type: GET_USER,
            payload: response.data.user
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: USER_ERROR
        } );
    }
};


// Add user
export const addUser = ( { email, name, password, role, position, department } ) => async dispatch => {
    dispatch( { type: SET_USER_LOADING } );
    dispatch( { type: CLEAR_USER } );
    try {
        const response = await UserService.addUser( email, name, password, role, position, department );
        dispatch( {
            type: ADD_USER,
            payload: response.data.user
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Пользователь успешно добавлен!' ) );
        dispatch( getAllUsers() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: USER_ERROR
        } );
    }
};
// Update User
export const updateUser = ( userId, { email, name, password, role, position, department } ) => async dispatch => {
    dispatch( { type: SET_USER_LOADING } );
    dispatch( { type: CLEAR_USER } );
    try {
        const response = await UserService.updateUser( userId, email, name, password, role, position, department );
        dispatch( {
            type: UPDATE_USER,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllUsers() );
        dispatch( getUserById( userId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: USER_ERROR
        } );
    }
};


// Update User Profile
export const updateProfile = ( userId, fmData) => async dispatch => {
    dispatch( { type: SET_USER_LOADING } );
    dispatch( { type: CLEAR_USER } );
    try {
        const response = await UserService.updateProfile( userId, fmData );
        dispatch( {
            type: UPDATE_USER,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getUserById( userId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: USER_ERROR
        } );
    }
};


// DELETE user
export const removeUser = ( userId ) => async dispatch => {
    dispatch( { type: SET_USER_LOADING } );
    dispatch( { type: CLEAR_USER } );
    try {
        await UserService.removeUser( userId );
        dispatch( {
            type: DELETE_USER,
            payload: userId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Пользователь успешно удален!' ) );
        dispatch( getAllUsers() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: USER_ERROR
        } );
    }
};




