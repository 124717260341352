import {$api} from "../http";

class EconomicCodeService {

    async addEconomicCode( code, name ) {
        return $api.post( '/economiccode', { code, name } );
    }

    async getAllEconomicCode() {
        return $api.get( '/economiccode' );
    }

    async updateEconomicCode( economicId, code, name ) {
        return $api.put( `/economiccode/${economicId}`, { code, name } );
    }

    async removeEconomicCode( economicId ) {
        return $api.delete( `/economiccode/${economicId}` );
    }

    async getEconomicCodeById( economicId ) {
        return $api.get( `/economiccode/${economicId}` );
    }
}

export default new EconomicCodeService();

