import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeQsmDesignation, addQsmDesignation, updateQsmDesignation } from '../../actions/qsmDesignation';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    document_code: '',
    document_name_ru: '',
    document_name_uz: '',
    document_name_en: '',
    description: ''
};
const { TextArea } = Input;

const QsmDesignationModal = ( { visible, onCreate, onCancel } ) => {

    const qsmDesignationData = useSelector( state => state.qsmDesignation.qsmDesignationData );
    const isLoading = useSelector( state => state.qsmDesignation.isLoading );
    const dispatch = useDispatch();

    const [ qsmData, setQsmData ] = useState( qsmDesignationData || initialState );

    useEffect( () => {
        setQsmData( qsmDesignationData !== null ? qsmDesignationData : initialState );
    }, [ qsmDesignationData ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newQsmData = {
                    document_code: values.document_code,
                    document_name_ru: values.document_name_ru,
                    document_name_uz: values.document_name_uz,
                    document_name_en: values.document_name_en,
                    description: values.description
                };
                const newData = { ...qsmData, ...newQsmData };
                setQsmData( newData );
                if ( qsmDesignationData !== null && qsmDesignationData._id ) {
                    dispatch( updateQsmDesignation( qsmDesignationData._id, newData ) );
                } else {
                    dispatch( addQsmDesignation( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={qsmDesignationData !== null && qsmDesignationData._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                qsmDesignationData !== null && qsmDesignationData._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeQsmDesignation( qsmDesignationData._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {qsmDesignationData !== null && qsmDesignationData._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="designationForm"
                size="middle"
                initValues={qsmData}
            >
                <FormField name="document_code" label="Код обозначения"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Код обозначения!',
                               whitespace: true
                           } ]}>
                    <Input name="document_code"/>
                </FormField>
                <FormField name="document_name_ru" label="Расшифровка(Русский)"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Расшифровка(Русский)!',
                               whitespace: true
                           } ]}>
                    <Input name="document_name_ru"/>
                </FormField>
                <FormField name="document_name_uz" label="Расшифровка(Ўзбекча)"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Расшифровка(Ўзбекча)!',
                               whitespace: true
                           } ]}>
                    <Input name="document_name_en"/>
                </FormField>
                <FormField name="document_name_en" label="Расшифровка(English)"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Расшифровка(English)!',
                               whitespace: true
                           } ]}>
                    <Input name="document_name_en"/>
                </FormField>
                <FormField name="description" label="Примечание">
                    <TextArea name="description" rows={3}/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default QsmDesignationModal;