import {
    ADD_INFLUENCE,
    GET_INFLUENCE,
    UPDATE_INFLUENCE,
    DELETE_INFLUENCE,
    INFLUENCE_ERROR,
    GET_INFLUENCES,
    CLEAR_INFLUENCE,
    SET_INFLUENCE_LOADING
} from '../utils/consts';

const initialState = {
    influence: null,
    influencesList: [],
    isLoading: false
};

const influences = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_INFLUENCE:
            return {
                ...state,
                influencesList: [ payload, ...state.influencesList ],
            };
        case GET_INFLUENCES:
            return {
                ...state,
                influencesList: payload,
                isLoading: false,
            };
        case GET_INFLUENCE:
        case UPDATE_INFLUENCE:
            return {
                ...state,
                influence: payload,
                isLoading: false
            };
        case DELETE_INFLUENCE:
            return {
                ...state,
                influence: null,
                isLoading: false
            };
        case SET_INFLUENCE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_INFLUENCE:
            return {
                ...state,
                influence: null,
            };
        case INFLUENCE_ERROR:
            return {
                ...state,
                isLoading: false,
                influence: null
            };
        default:
            return state;
    }
};

export default influences;