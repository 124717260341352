import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authRoutes } from "../routes";
import { DASHBOARD_ROUTE } from "../utils/consts";


const AppRouter = () => {
    const user = useSelector( state => state.auth );
    return (
        <Switch>
            {user.isAuth && authRoutes.map( ( { path, Component } ) =>
                <Route key={path} path={`/${path}`} component={Component} exact/>
            )}
            <Redirect to={DASHBOARD_ROUTE}/>
        </Switch>
    );
};

export default AppRouter;
