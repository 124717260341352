import {
    ADD_CERT_SCHEMA,
    GET_CERT_SCHEMA,
    UPDATE_CERT_SCHEMA,
    DELETE_CERT_SCHEMA,
    CERT_SCHEMA_ERROR,
    GET_CERT_SCHEMAS,
    CLEAR_CERT_SCHEMA,
    SET_CERT_SCHEMA_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import CertSchemaService from "../services/CertSchemaService";

// Get All CertSchema
export const getAllCertSchema = () => async dispatch => {
    dispatch( { type: SET_CERT_SCHEMA_LOADING } );
    dispatch( { type: CLEAR_CERT_SCHEMA } );
    try {
        const response = await CertSchemaService.getAllCertSchema();
        dispatch( {
            type: GET_CERT_SCHEMAS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERT_SCHEMA_ERROR
        } );
    }
};

// Get CertSchema by ID
export const getCertSchemaById = ( certschemaId ) => async dispatch => {
    dispatch( { type: SET_CERT_SCHEMA_LOADING } );
    dispatch( { type: CLEAR_CERT_SCHEMA } );
    try {
        const response = await CertSchemaService.getCertSchemaById( certschemaId );
        dispatch( {
            type: GET_CERT_SCHEMA,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERT_SCHEMA_ERROR
        } );
    }
};

// Add CertSchema
export const addCertSchema = ( { code, test_type, survey, inspection } ) => async dispatch => {
    dispatch( { type: SET_CERT_SCHEMA_LOADING } );
    dispatch( { type: CLEAR_CERT_SCHEMA } );
    try {
        const response = await CertSchemaService.addCertSchema( code, test_type, survey, inspection );
        dispatch( {
            type: ADD_CERT_SCHEMA,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllCertSchema() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERT_SCHEMA_ERROR
        } );
    }
};
// Update CertSchema
export const updateCertSchema = ( certschemaId, { code, test_type, survey, inspection } ) => async dispatch => {
    dispatch( { type: SET_CERT_SCHEMA_LOADING } );
    dispatch( { type: CLEAR_CERT_SCHEMA } );
    try {
        const response = await CertSchemaService.updateCertSchema( certschemaId, code, test_type, survey, inspection );
        dispatch( {
            type: UPDATE_CERT_SCHEMA,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllCertSchema() );
        dispatch( getCertSchemaById( certschemaId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERT_SCHEMA_ERROR
        } );
    }
};

// Delete CertSchema
export const removeCertSchema = ( certschemaId ) => async dispatch => {
    dispatch( { type: SET_CERT_SCHEMA_LOADING } );
    dispatch( { type: CLEAR_CERT_SCHEMA } );
    try {
        await CertSchemaService.removeCertSchema( certschemaId );
        dispatch( {
            type: DELETE_CERT_SCHEMA,
            payload: certschemaId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllCertSchema() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CERT_SCHEMA_ERROR
        } );
    }
};




