import {
    ADD_ROLE,
    GET_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
    ROLE_ERROR,
    GET_ROLES,
    CLEAR_ROLE,
    SET_ROLE_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import RoleService from "../services/RoleService";

// Get All Roles
export const getAllRoles = () => async dispatch => {
    dispatch( { type: SET_ROLE_LOADING } );
    dispatch( { type: CLEAR_ROLE } );
    try {
        const response = await RoleService.getAllRoles();
        dispatch( {
            type: GET_ROLES,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ROLE_ERROR
        } );
    }
};

// Get Role by ID
export const getRoleById = ( roleId ) => async dispatch => {
    dispatch( { type: SET_ROLE_LOADING } );
    dispatch( { type: CLEAR_ROLE } );
    try {
        const response = await RoleService.getRoleById( roleId );
        dispatch( {
            type: GET_ROLE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ROLE_ERROR
        } );
    }
};


// Add position
export const addRole = ( { name } ) => async dispatch => {
    dispatch( { type: SET_ROLE_LOADING } );
    dispatch( { type: CLEAR_ROLE } );
    try {
        const response = await RoleService.addRole( name );
        dispatch( {
            type: ADD_ROLE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllRoles() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ROLE_ERROR
        } );
    }
};
// Update Role
export const updateRole = ( roleId, { name } ) => async dispatch => {
    dispatch( { type: SET_ROLE_LOADING } );
    dispatch( { type: CLEAR_ROLE } );
    try {
        const response = await RoleService.updateRole( roleId, name );
        dispatch( {
            type: UPDATE_ROLE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllRoles() );
        dispatch( getRoleById( roleId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ROLE_ERROR
        } );
    }
};

// Delete Role
export const removeRole = ( roleId ) => async dispatch => {
    dispatch( { type: SET_ROLE_LOADING } );
    dispatch( { type: CLEAR_ROLE } );
    try {
        await RoleService.removeRole( roleId );
        dispatch( {
            type: DELETE_ROLE,
            payload: roleId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllRoles() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: ROLE_ERROR
        } );
    }
};




