import {$api} from "../http";

class CertSchemaService {

    async addCertSchema( code, test_type, survey, inspection ) {
        return $api.post( '/certschema', { code, test_type, survey, inspection } );
    }

    async getAllCertSchema() {
        return $api.get( '/certschema' );
    }

    async updateCertSchema( certschemaId, code, test_type, survey, inspection ) {
        return $api.put( `/certschema/${certschemaId}`, { code, test_type, survey, inspection } );
    }

    async removeCertSchema( certschemaId ) {
        return $api.delete( `/certschema/${certschemaId}` );
    }

    async getCertSchemaById( certschemaId ) {
        return $api.get( `/certschema/${certschemaId}` );
    }
}

export default new CertSchemaService();

