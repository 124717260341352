import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { NavLink } from 'react-router-dom';
import {
    UserOutlined,
    LaptopOutlined,
    PieChartOutlined,
    UnorderedListOutlined,
    AuditOutlined,
    DeploymentUnitOutlined,
    FileTextOutlined,
    ContactsOutlined,
} from '@ant-design/icons';

import {
    USERS_ROUTE,
    POSITIONS_ROUTE,
    DEPARTMENT_ROUTE,
    DASHBOARD_ROUTE,
    JOURNALQSMONE_ROUTE,
    JOURNALQSMTWO_ROUTE,
    JOURNALQSMTHREE_ROUTE,
    JOURNALQSMDESIGNATION_ROUTE,
    ROLES_ROUTE,
    RISKMAP_ROUTE,
    RISKTYPES_ROUTE,
    RISKADD_ROUTE,
    RISKS_ROUTE,
    ANALYSIS_ROUTE,
    INFLUENCES_ROUTE,
    SUBDIVISIONS_ROUTE,
    QSM_DOCTYPES_ROUTE,
    NONCONFORMITY_ROUTE,
    NONCONFORMITY_JOURNAL_ROUTE,
    STANDARTS_ROUTE,
    DEVICES_ROUTE,
    TNVED_ROUTE,
    CERT_SCHEMA_ROUTE,
    LABS_ROUTE,
    CLIENTS_ROUTE,
    APPS_ROUTE,
    ECONOMIC_CODE_ROUTE,
    CERT_CODE_ROUTE,
    CBSOA_ROUTE
} from "../utils/consts";


const { Sider } = Layout;
const { SubMenu } = Menu;


const MENU_DATA = [
    {
        title: 'Dashboard',
        icon: <PieChartOutlined/>,
        link: DASHBOARD_ROUTE,
        key: 1
    },
    {
        title: 'СМК',
        icon: <LaptopOutlined/>,
        key: 'QSM',
        child: [
            {
                title: 'ПСМ-LET-01-B',
                link: JOURNALQSMONE_ROUTE,
                key: 11,
            },
            {
                title: 'ПСМ-LET-01-D',
                link: JOURNALQSMTWO_ROUTE,
                key: 12,
            },
            {
                title: 'ПСМ-LET-01-C',
                link: JOURNALQSMTHREE_ROUTE,
                key: 13,
            },
            {
                title: 'Обозначения, документов системы менеджмента',
                link: JOURNALQSMDESIGNATION_ROUTE,
                key: 14,
            },
            {
                title: 'ПСМ-LET-03-А',
                link: NONCONFORMITY_ROUTE,
                key: 15,
            },
            {
                title: 'Журнал по устранению несоответсвий',
                link: NONCONFORMITY_JOURNAL_ROUTE,
                key: 16,
            },
            {
                title: 'РСМ-LET-01-C',
                link: RISKS_ROUTE,
                key: 17,
            },
            {
                title: 'Журнал регистрации рисков',
                link: RISKADD_ROUTE,
                key: 18,
            },
            {
                title: 'РСМ-LET-01-B',
                link: RISKTYPES_ROUTE,
                key: 19,
            },
            {
                title: 'РСМ-LET-01-D',
                link: RISKMAP_ROUTE,
                key: 20,
            },
        ]
    },
    {
        title: 'Метрология',
        icon: <DeploymentUnitOutlined/>,
        key: 'Metrology',
        child: [
            {
                title: 'Перечень ТС',
                link: DEVICES_ROUTE,
                key: 31,
            },
        ]
    },
    {
        title: 'Стандартизация',
        icon: <FileTextOutlined/>,
        key: 'Standardization',
        child: [
            {
                title: 'Перечень НД',
                link: STANDARTS_ROUTE,
                key: 41,
            },
            {
                title: 'Рассылка НД',
                link: QSM_DOCTYPES_ROUTE,
                key: 42,
            },
        ]
    },
    {
        title: 'Пользователи',
        icon: <UserOutlined/>,
        key: 'users',
        child: [
            {
                title: 'Список',
                link: USERS_ROUTE,
                key: 51,
            },
            {
                title: 'Должности',
                link: POSITIONS_ROUTE,
                key: 52,
            },
            {
                title: 'Роли',
                link: ROLES_ROUTE,
                key: 53
            },
            {
                title: 'Отделы',
                link: DEPARTMENT_ROUTE,
                key: 54
            },
        ]
    },
    {
        title: 'Справочник',
        icon: <UnorderedListOutlined/>,
        key: 'reference',
        child: [
            {
                title: 'Типы документов ИСМ',
                link: QSM_DOCTYPES_ROUTE,
                key: 61,
            },
            {
                title: 'Виды анализа',
                link: ANALYSIS_ROUTE,
                key: 62,
            },
            {
                title: 'Подразделения',
                link: SUBDIVISIONS_ROUTE,
                key: 63,
            },
            {
                title: 'Сферы влияния',
                link: INFLUENCES_ROUTE,
                key: 64
            },
            {
                title: 'Классификатор экономического сектора',
                link: ECONOMIC_CODE_ROUTE,
                key: 65
            },
            {
                title: 'Классификатор объектов сертификации',
                link: CERT_CODE_ROUTE,
                key: 66
            },
            {
                title: 'Лаборатории',
                link: LABS_ROUTE,
                key: 67
            },
            {
                title: 'Коды ТН ВЭД',
                link: TNVED_ROUTE,
                key: 68,
            },
            {
                title: 'Схемы сертификации',
                link: CERT_SCHEMA_ROUTE,
                key: 69,
            },
        ]
    },
    {
        title: 'Сертификация',
        icon: <AuditOutlined/>,
        key: 'certification',
        child: [
            {
                title: 'Заявки',
                link: APPS_ROUTE,
                key: 71,
            },
            {
                title: 'ОА ОС',
                link: CBSOA_ROUTE,
                key: 72,
            },

        ]
    },
    {
        title: 'Клиенты',
        icon: <ContactsOutlined/>,
        key: 'clients',
        child: [
            {
                title: 'Список',
                link: CLIENTS_ROUTE,
                key: 81,
            },
        ]
    },

];

const SideBar = ( { toggle } ) => {
    const [ currentSubMenu, setCurrentSubMenu ] = useState( localStorage.getItem( 'currentSubMenu' ) || '' );

    const onSubMenuClick = e => {
        if ( e.length > 0 ) {
            setCurrentSubMenu( e.join( ',' ) );
            localStorage.setItem( 'currentSubMenu', e.toString() );
        } else {
            localStorage.removeItem( 'currentSubMenu' );
        }
    };

    const menuItems = MENU_DATA.map( ( item, index ) => {
        if ( item.child && item.child.length > 0 ) {
            return (
                <SubMenu key={item.key} title={item.title} icon={item.icon}>
                    {item.child.map( ( child ) => (
                        <Menu.Item key={child.key}><NavLink
                            to={`/${child.link}`}>{child.title}</NavLink></Menu.Item> ) )}
                </SubMenu>
            )
        } else {
            return (
                <Menu.Item key={item.key} icon={item.icon}>
                    <NavLink to={`/${item.link}`}>{item.title}</NavLink>
                </Menu.Item>
            )
        }
    } );

    return (
        <Sider trigger={null} collapsible collapsed={toggle} width="300">
            <div className="logo"/>
            <Menu
                mode="inline"
                theme="dark"
                defaultOpenKeys={currentSubMenu && currentSubMenu.length > 0 ? currentSubMenu.split( ',' ) : []}
                style={{ borderRight: 0 }}
                onOpenChange={onSubMenuClick}
            >
                {menuItems}
            </Menu>
        </Sider>
    )
};

export default SideBar;

