import axios from 'axios';

import {
    LOGOUT,
    AUTH_LOADING_TRUE,
    AUTH_LOADING_FALSE,
    CHECK_AUTH,
    AUTH_ERROR
} from '../utils/consts';

import AuthService from "../services/AuthService";
import { API_URL } from "../http";
import { setNotify } from "./notications";

export const login = ( { email, password } ) => async dispatch => {
    dispatch( {
        type: AUTH_LOADING_TRUE
    } );
    try {
        const response = await AuthService.login( email, password );
        localStorage.setItem( 'token', response.data.accessToken );
        dispatch( {
            type: CHECK_AUTH,
            payload: response.data.user
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ощибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: AUTH_ERROR
        } );
    } finally {
        dispatch( {
            type: AUTH_LOADING_FALSE
        } );
    }
};

// Logout
export const logout = () => async dispatch => {
    try {
        await AuthService.logout();
        localStorage.removeItem( 'token' );
        dispatch( {
            type: LOGOUT
        } );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ощибка', `${e.response?.statusText}` ) );
    }
};

export const checkAuth = () => async dispatch => {
    dispatch( {
        type: AUTH_LOADING_TRUE
    } );
    try {
        const response = await axios.get( `${API_URL}/user/refresh`, { withCredentials: true } );
        localStorage.setItem( 'token', response.data.accessToken );
        dispatch( {
            type: CHECK_AUTH,
            payload: response.data.user
        } );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ощибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: AUTH_ERROR
        } );
    } finally {
        dispatch( {
            type: AUTH_LOADING_FALSE
        } );
    }
};