import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removePosition, addPosition, updatePosition } from '../../actions/positions';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
};

const PositionModal = ( { visible, onCreate, onCancel, history } ) => {

    const position = useSelector( state => state.positions.position );
    const isLoading = useSelector( state => state.positions.isLoading );
    const dispatch = useDispatch();

    const [ positionData, setPositionData ] = useState( position || initialState );

    useEffect( () => {
        setPositionData( position !== null ? position : initialState );
    }, [ position ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newPositionData = {
                    name: values.name
                };
                const newData = { ...positionData, ...newPositionData };
                setPositionData( newData );
                if ( position !== null && position._id ) {
                    dispatch( updatePosition( position._id, newData ) );
                } else {
                    dispatch( addPosition( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    return (
        <Modal
            visible={visible}
            title={position !== null && position._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                position !== null && position._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removePosition( position._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {position !== null && position._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formInit={form}
                name="positionForm"
                size="middle"
                initValues={positionData}
            >
                <FormField name="name" label="Наименование должности"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование должности!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default PositionModal;