import {$api} from "../http";

class AnalysisService {

    async addAnalysis( name ) {
        return $api.post( '/analysis', { name } );
    }

    async getAllAnalysis() {
        return $api.get( '/analysis' );
    }

    async updateAnalysis( analysisId, name ) {
        return $api.put( `/analysis/${analysisId}`, { name } );
    }

    async removeAnalysis( analysisId ) {
        return $api.delete( `/analysis/${analysisId}` );
    }

    async getAnalysisById( analysisId ) {
        return $api.get( `/analysis/${analysisId}` );
    }
}

export default new AnalysisService();

