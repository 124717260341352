import {$api} from "../http";

class InfluenceService {

    async addInfluence( name ) {
        return $api.post( '/influence', { name } );
    }

    async getAllInfluence() {
        return $api.get( '/influence' );
    }

    async updateInfluence( influenceId, name ) {
        return $api.put( `/influence/${influenceId}`, { name } );
    }

    async removeInfluence( influenceId ) {
        return $api.delete( `/influence/${influenceId}` );
    }

    async getInfluenceById( influenceId ) {
        return $api.get( `/influence/${influenceId}` );
    }
}

export default new InfluenceService();

