import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';

import { Provider } from 'react-redux';
import store from './store/';

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={locale}>
            <App/>
        </ConfigProvider>
    </Provider>,
    document.getElementById( 'root' )
);
