import {
    ADD_TNVED,
    GET_TNVED,
    UPDATE_TNVED,
    DELETE_TNVED,
    TNVED_ERROR,
    GET_TNVEDS,
    CLEAR_TNVED,
    SET_TNVED_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import TnvedService from "../services/TnvedService";

// Get All Tnved
export const getAllTnved = () => async dispatch => {
    dispatch( { type: SET_TNVED_LOADING } );
    dispatch( { type: CLEAR_TNVED } );
    try {
        const response = await TnvedService.getAllTnved();
        dispatch( {
            type: GET_TNVEDS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: TNVED_ERROR
        } );
    }
};

// Get Tnved by ID
export const getTnvedById = ( tnvedId ) => async dispatch => {
    dispatch( { type: SET_TNVED_LOADING } );
    dispatch( { type: CLEAR_TNVED } );
    try {
        const response = await TnvedService.getTnvedById( tnvedId );
        dispatch( {
            type: GET_TNVED,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: TNVED_ERROR
        } );
    }
};


// Add Tnved
export const addTnved = ( { code, name, economic_code, certification_code, standarts } ) => async dispatch => {
    dispatch( { type: SET_TNVED_LOADING } );
    dispatch( { type: CLEAR_TNVED } );
    try {
        const response = await TnvedService.addTnved( code, name, economic_code, certification_code, standarts );
        dispatch( {
            type: ADD_TNVED,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllTnved() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: TNVED_ERROR
        } );
    }
};
// Update Tnved
export const updateTnved = ( tnvedId, { code, name, economic_code, certification_code, standarts } ) => async dispatch => {
    dispatch( { type: SET_TNVED_LOADING } );
    dispatch( { type: CLEAR_TNVED } );
    try {
        const response = await TnvedService.updateTnved( tnvedId, code, name, economic_code, certification_code, standarts );
        dispatch( {
            type: UPDATE_TNVED,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllTnved() );
        dispatch( getTnvedById( tnvedId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: TNVED_ERROR
        } );
    }
};

// Delete Tnved
export const removeTnved = ( tnvedId ) => async dispatch => {
    dispatch( { type: SET_TNVED_LOADING } );
    dispatch( { type: CLEAR_TNVED } );
    try {
        await TnvedService.removeTnved( tnvedId );
        dispatch( {
            type: DELETE_TNVED,
            payload: tnvedId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllTnved() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: TNVED_ERROR
        } );
    }
};




