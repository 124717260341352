import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, Row, Col, Space, Checkbox } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import {
    updateApplication,
    addApplication,
    generateExtraditionPdf
} from '../../actions/applications';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";

const initialState = {
    extradition_number: 0,
    extradition_date: moment(),
    extradition: 'о выдаче',
    rejection_reason: '',
    expert: '',
    application: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    },
    marking: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    },
    accompanying: {
        availability: false,
        conclusion: 'Не предусмотрено',
        description: ''
    },
    manual: {
        availability: false,
        conclusion: 'Не предусмотрено',
        description: ''
    },
    regulatory: {
        availability: false,
        conclusion: 'Не предусмотрено',
        description: ''
    },
    decision: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    },
    program: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    },
    sampling: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    },
    inspection: {
        availability: false,
        conclusion: 'Не предусмотрено',
        description: ''
    },
    testing: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    },
    other_testing: {
        availability: false,
        conclusion: 'Не предусмотрено',
        description: ''
    },
    returning: {
        availability: true,
        conclusion: 'Удовлетворительно',
        description: ''
    }
};

const { TextArea } = Input;
const { Option } = Select;

const ApplicationExtradition = ( { data, users } ) => {

    const isLoading = useSelector( state => state.applications.isLoading );
    const dispatch = useDispatch();

    if ( data && data.extradition ) {
        data.extradition.extradition_date = moment( data.decision.extradition_date );
    }

    const [ appData, setAppData ] = useState( data || initialState );

    useEffect( () => {
        setAppData( data !== null ? data : initialState );
    }, [ data ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newAppData = {
                    extradition_number: values.extradition_number,
                    extradition_date: values.extradition_date,
                    extradition: values.extradition,
                    expert: values.expert,
                    application: {
                        availability: values.app_availability,
                        conclusion: values.app_conclusion,
                        description: values.app_description,
                    },
                    marking: {
                        availability: values.marking_availability,
                        conclusion: values.marking_conclusion,
                        description: values.marking_description,
                    },
                    accompanying: {
                        availability: values.accompanying_availability,
                        conclusion: values.accompanying_conclusion,
                        description: values.accompanying_description,
                    },
                    manual: {
                        availability: values.manual_availability,
                        conclusion: values.manual_conclusion,
                        description: values.manual_description,
                    },
                    regulatory: {
                        availability: values.regulatory_availability,
                        conclusion: values.regulatory_conclusion,
                        description: values.regulatory_description,
                    },
                    decision: {
                        availability: values.decision_availability,
                        conclusion: values.decision_conclusion,
                        description: values.decision_description,
                    },
                    program: {
                        availability: values.program_availability,
                        conclusion: values.program_conclusion,
                        description: values.program_description,
                    },
                    sampling: {
                        availability: values.sampling_availability,
                        conclusion: values.sampling_conclusion,
                        description: values.sampling_description,
                    },
                    inspection: {
                        availability: values.inspection_availability,
                        conclusion: values.inspection_conclusion,
                        description: values.inspection_description,
                    },
                    testing: {
                        availability: values.testing_availability,
                        conclusion: values.testing_conclusion,
                        description: values.testing_description,
                    },
                    other_testing: {
                        availability: values.other_testing_availability,
                        conclusion: values.other_testing_conclusion,
                        description: values.other_testing_description,
                    },
                    returning: {
                        availability: values.returning_availability,
                        conclusion: values.returning_conclusion,
                        description: values.returning_description,
                    },
                };

                const newData = { ...appData, extradition: newAppData };
                setAppData( newData );
                if ( appData !== null && appData._id ) {
                    dispatch( updateApplication( appData._id, newData ) );
                } else {
                    dispatch( addApplication( newData ) );
                }
                form.submit();
                form.resetFields();

            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },

        },
    };

    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };
    const checkboxLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 22 },
        },
        wrapperCol: {
            offset: 0,
            span: 2,
        },
    };

    const onFieldsChange = ( changedFields, allFields ) => {
        console.log( allFields )
        // setAppData( { ...appData, ...allFields } );
    };


    return (
        <>
            {isLoading ? <Loading/> : (
                <>
                    <AppForm
                        formItemLayout={formItemLayout}
                        formInit={form}
                        name="appForm"
                        size="middle"
                        labelWrap="true"
                        onValuesChange={onFieldsChange}
                        initValues={{
                            ...appData.extradition,
                            app_availability: appData?.extradition?.application?.availability,
                            app_conclusion: appData?.extradition?.application?.conclusion,
                            app_description: appData?.extradition?.application?.description,
                            marking_availability: appData?.extradition?.marking?.availability,
                            marking_conclusion: appData?.extradition?.marking?.conclusion,
                            marking_description: appData?.extradition?.marking?.description,
                            accompanying_availability: appData?.extradition?.accompanying?.availability,
                            accompanying_conclusion: appData?.extradition?.accompanying?.conclusion,
                            accompanying_description: appData?.extradition?.accompanying?.description,
                            manual_availability: appData?.extradition?.manual?.availability,
                            manual_conclusion: appData?.extradition?.manual?.conclusion,
                            manual_description: appData?.extradition?.manual?.description,
                            regulatory_availability: appData?.extradition?.regulatory?.availability,
                            regulatory_conclusion: appData?.extradition?.regulatory?.conclusion,
                            regulatory_description: appData?.extradition?.regulatory?.description,
                            decision_availability: appData?.extradition?.decision?.availability,
                            decision_conclusion: appData?.extradition?.decision?.conclusion,
                            decision_description: appData?.extradition?.decision?.description,
                            program_availability: appData?.extradition?.program?.availability,
                            program_conclusion: appData?.extradition?.program?.conclusion,
                            program_description: appData?.extradition?.program?.description,
                            sampling_availability: appData?.extradition?.sampling?.availability,
                            sampling_conclusion: appData?.extradition?.sampling?.conclusion,
                            sampling_description: appData?.extradition?.sampling?.description,
                            inspection_availability: appData?.extradition?.inspection?.availability,
                            inspection_conclusion: appData?.extradition?.inspection?.conclusion,
                            inspection_description: appData?.extradition?.inspection?.description,
                            testing_availability: appData?.extradition?.testing?.availability,
                            testing_conclusion: appData?.extradition?.testing?.conclusion,
                            testing_description: appData?.extradition?.testing?.description,
                            other_testing_availability: appData?.extradition?.other_testing?.availability,
                            other_testing_conclusion: appData?.extradition?.other_testing?.conclusion,
                            other_testing_description: appData?.extradition?.other_testing?.description,
                            returning_availability: appData?.extradition?.returning?.availability,
                            returning_conclusion: appData?.extradition?.returning?.conclusion,
                            returning_description: appData?.extradition?.returning?.description,
                            extradition_number: appData.extradition.extradition_number ? appData.extradition.extradition_number : appData.request_number,
                        }}
                    >
                        <Row gutter={15}>
                            <Col xs={24}>
                                <Space style={{ marginBottom: '20px' }}>
                                    {data !== null && data._id ?
                                        <Button type="primary" icon={<DownloadOutlined/>}
                                                onClick={() => dispatch( generateExtraditionPdf( data._id ) )}
                                        >
                                            Решение о выдачи сертификата
                                        </Button> : ""
                                    }
                                </Space>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 10 }}>
                                <Form.Item name="extradition_number" label="№ решения"
                                           rules={[ {
                                               required: true,
                                               message: 'Пожалуйста введите № решения',
                                           } ]}>
                                    <Input name="extradition_number"/>
                                </Form.Item>
                                <Form.Item
                                    name="extradition_date" label="Дата решения"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Дата решения',
                                    } ]}>
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="expert"
                                    label="Технический эксперт"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите технического эксперта',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="От органа по сертификации"
                                        optionFilterProp="children"
                                        name="expert"
                                        filterOption={( input, option ) =>
                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                        }
                                    >
                                        {users?.filter( item => item?.department?.name.includes( 'Орган по сертификации продукции' ) || item?.department?.name.includes( 'Технический эксперт' ) ).map( item =>
                                            <Option key={item._id} value={item._id} selected>{item.name}</Option> )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="extradition"
                                    label="Принято решение"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите принятое решение',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Принято решение"
                                        optionFilterProp="children"
                                        name="survey_type"
                                        filterOption={( input, option ) =>
                                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                        }
                                    >
                                        <Option value="о выдаче" selected>о выдаче</Option>
                                        <Option value="об отказе">об отказе</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="rejection_reason"
                                    label="Причина отказа">
                                    <TextArea name="survey_address"/>
                                </Form.Item>

                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 14 }}>
                                <table className="ExtradionTable">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="app_availability"
                                                valuePropName="checked"
                                                label="Заявка на сертификацию"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="app_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="app_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="marking_availability"
                                                valuePropName="checked"
                                                label="Образец маркировки"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="marking_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="marking_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="accompanying_availability"
                                                valuePropName="checked"
                                                label="Сопроводительные документы"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="accompanying_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="accompanying_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="manual_availability"
                                                valuePropName="checked"
                                                label="Паспорт, руководство по эксплуатации, инструкции и др."
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="manual_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="manual_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="regulatory_availability"
                                                valuePropName="checked"
                                                label="Нормативный документ на продукцию"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="regulatory_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="regulatory_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="decision_availability"
                                                valuePropName="checked"
                                                label="Решение по заявки"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="decision_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="decision_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="program_availability"
                                                valuePropName="checked"
                                                label="Программа испытаний"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="program_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="program_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="sampling_availability"
                                                valuePropName="checked"
                                                label="Акт идентификации и отбора образцов"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="sampling_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="sampling_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="inspection_availability"
                                                valuePropName="checked"
                                                label="Акт обследования производства"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="inspection_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="inspection_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="testing_availability"
                                                valuePropName="checked"
                                                label="Протокол испытаний собственной АИЛ"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="testing_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="testing_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="other_testing_availability"
                                                valuePropName="checked"
                                                label="Протокол испытаний привлеченной АИЛ"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="other_testing_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="other_testing_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Item
                                                {...checkboxLayout}
                                                name="returning_availability"
                                                valuePropName="checked"
                                                label="Акт о списании (возврате) продукции"
                                            >
                                                <Checkbox/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...tailLayout}
                                                name="returning_conclusion"
                                                rules={[ {
                                                    required: true,
                                                    message: 'Пожалуйста Заключение по документу',
                                                } ]}>
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Заключение по документу"
                                                    optionFilterProp="children"
                                                    name="returning_conclusion"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    <Option value="Удовлетворительно"
                                                            selected>Удовлетворительно</Option>
                                                    <Option value="Неудовлетворительно">Неудовлетворительно</Option>
                                                    <Option value="Не предусмотрено">Не предусмотрено</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </Col>
                            <Col xs={24} sm={4} offset={10}>
                                <Space style={{ marginTop: '20px' }}>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onFormSubmit}
                                    >
                                        {data !== null && data._id ? "Обновить" : "Добавить"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </AppForm>
                </>
            )}
        </>
    );
};

export default ApplicationExtradition;