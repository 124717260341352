import {$api} from "../http";

class RiskService {

    async addRisk(
        risk_date,
        risk_content,
        employee
    ) {
        return $api.post( '/risk/', {
            risk_date,
            risk_content,
            employee
        } );
    }

    async getAllRisks() {
        return $api.get( `/risk/` );
    }

    async updateRisk(
        riskId,
        risk_date,
        risk_content,
        risk_name,
        potential_consequences,
        risk_reason,
        status,
        risk_eliminates
    ) {
        return $api.put( `/risk/${riskId}`, {
                risk_date,
                risk_content,
                risk_name,
                potential_consequences,
                risk_reason,
                status,
                risk_eliminates
            }
        );
    }

    async removeRisk( riskId ) {
        return $api.delete( `/risk/${riskId}` );
    }

    async getRiskById( riskId ) {
        return $api.get( `/risk/${riskId}` );
    }

    async getRiskByUser( employeeId ) {
        return $api.get( `/risk/user/${employeeId}` );
    }
}

export default new RiskService();

