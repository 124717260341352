import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllStandarts, removeStandart, getStandartById } from '../../actions/standarts';
import Loading from "../../components/Loading/Loading";
import appNotification from "../../components/AppNotification/AppNotification";
import StandartModal from "../../components/Modals/StandartModal";
import { CLEAR_STANDART } from "../../utils/consts";

const { Title } = Typography;

const Standarts = () => {

    const [ filteredInfo, setFilteredInfo ] = useState( null );
    const [ sortedInfo, setSortedInfo ] = useState( null );
    const [ visible, setVisible ] = useState( false );

    const standarts = useSelector( state => state.standarts.standarts );
    const isLoading = useSelector( state => state.standarts.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const dispatch = useDispatch();

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllStandarts() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    let sorted = sortedInfo;
    let filtered = filteredInfo;
    sorted = sorted || {};
    filtered = filtered || {};

    // change filters and sorts
    const handleChange = ( pagination, filters, sorter ) => {
        setFilteredInfo( filters );
        setSortedInfo( sorter );
    };
    // clear filter and sorts
    const clearAll = () => {
        setFilteredInfo( null );
        setSortedInfo( null );
    };

    let standartCode = [];
    let standartName = [];
    let standartProductName = [];
    let standartStatus = [];

    new Set( standarts.map( ( standart ) => standart.code ) ).forEach( val => standartCode.push( val ) );
    new Set( standarts.map( ( standart ) => standart.name ) ).forEach( val => standartName.push( val ) );
    new Set( standarts.map( ( standart ) => standart.product_name ) ).forEach( val => standartProductName.push( val ) );
    new Set( standarts.map( ( standart ) => standart.status ) ).forEach( val => standartStatus.push( val ) );

    const columns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 60,
            render: ( text, record, index ) => index + 1,
        },
        {
            title: 'Обозначение',
            dataIndex: 'code',
            key: 'code',
            render: ( text, record ) => ( <a href={record.standart_link} target="_blank" rel="noreferrer">{text}</a> ),
            filters: ( standartCode.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.code || null,
            onFilter: ( value, record ) => record.code.includes( value ),
            sorter: ( a, b ) => a.name.length - b.code.length,
            sortOrder: sorted.columnKey === 'code' && sorted.order,
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            filters: ( standartName.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.name || null,
            onFilter: ( value, record ) => record.name.includes( value ),
            sorter: ( a, b ) => a.name.length - b.name.length,
            sortOrder: sorted.columnKey === 'name' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Наименование объекта',
            dataIndex: 'product_name',
            key: 'product_name',
            filters: ( standartProductName.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.product_name || null,
            onFilter: ( value, record ) => record.product_name.includes( value ),
            sorter: ( a, b ) => a.product_name.length - b.product_name.length,
            sortOrder: sorted.columnKey === 'product_name' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            filters: ( standartStatus.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.status || null,
            onFilter: ( value, record ) => record.status.includes( value ),
            sorter: ( a, b ) => a.status.length - b.status.length,
            sortOrder: sorted.columnKey === 'status' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Примечание',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            render: ( text, record ) => (
                <>
                    <Button type="primary" icon={<EditOutlined/>} size="small" onClick={() => {
                        dispatch( getStandartById( record._id ) );
                        setVisible( true );
                    }}/>
                    {
                        standarts.length >= 1 ? (
                            <Popconfirm title="Вы уверены что хотите удалить запись ?"
                                        onConfirm={() => dispatch( removeStandart( record._id ) )}>
                                <Button type="primary" danger icon={<DeleteOutlined/>} size="small"/>
                            </Popconfirm>
                        ) : null
                    }
                </>
            ),
            width: 150,
            fixed: 'right'
        },
    ];

    return isLoading ? <Loading/> : (
        <>
            <Title level={3}>Список НД</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button onClick={clearAll}>Очистить фильтры</Button>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_STANDART } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            <Table
                bordered
                rowKey={( record ) => record._id}
                columns={columns}
                dataSource={standarts}
                onChange={handleChange}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1200, y: 500 }}
            />
            <StandartModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </>
    );

};

export default Standarts;