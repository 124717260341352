import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm, Select } from 'antd';
import { addUser, removeUser, updateUser } from "../../actions/users";
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const { Option } = Select;

const initialState = {
    email: '',
    name: '',
    password: '',
    role: '',
    position: '',
    department: '',
};

const UserModal = ( { visible, onCreate, onCancel, history } ) => {

    const user = useSelector( state => state.users.user );
    const isLoading = useSelector( state => state.users.isLoading );
    const positions = useSelector( state => state.positions.positions );
    const roles = useSelector( state => state.roles.roles );
    const departments = useSelector( state => state.departments.departments );

    const dispatch = useDispatch();

    const [ userData, setUserData ] = useState( user || initialState );

    useEffect( () => {

    }, [ dispatch ] );

    useEffect( () => {
        setUserData( user !== null ? user : initialState );
    }, [ user ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {

                const newUserData = {
                    email: values.email,
                    name: values.name,
                    password: values.password,
                    role: values.role,
                    position: values.position,
                    department: values.department,
                };

                const newData = { ...userData, ...newUserData };

                setUserData( newData );
                if ( user !== null && user.id ) {
                    dispatch( updateUser( user.id, userData ) );
                } else {
                    dispatch( addUser( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const onChangeHandler = ( value ) => {
        const newData = { ...userData, ...value };
        setUserData( newData );
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 8 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 16 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={user !== null && user.id ? "Изменить пользователя" : "Добавить пользователя"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                user !== null && user.id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeUser( user.id, history ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {user !== null && user.id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="userForm"
                size="middle"
                initValues={userData}
                onValuesChange={onChangeHandler}
            >
                <FormField name="name" label="ФИО"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста укажите ФИО сотрудника!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
                <FormField name="email" label="Email пользователя"
                           rules={[ { required: true, type: 'email', message: 'Введен некорентный email адрес!' } ]}>
                    <Input name="email"/>
                </FormField>
                <FormField name="position" label="Должность"
                           rules={[ { required: true, message: 'Пожалуйста выберите должность пользователя!' } ]}>
                    <Select
                        showSearch
                        placeholder="Должность пользователя"
                        optionFilterProp="children"
                        name="position"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        {positions.map( item => <Option key={item._id} value={item._id} selected>{item.name}</Option> )}
                    </Select>
                </FormField>
                <FormField name="role" label="Роль пользователя"
                           rules={[ { required: true, message: 'Пожалуйста выберите роль пользователя!' } ]}>
                    <Select
                        showSearch
                        placeholder="Роль пользователя"
                        optionFilterProp="children"
                        name="role"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        {roles.map( item => <Option key={item._id} value={item._id} selected>{item.name}</Option> )}
                    </Select>
                </FormField>
                <FormField name="department" label="Отдел"
                           rules={[ { required: true, message: 'Пожалуйста выберите Отдел!' } ]}>
                    <Select
                        showSearch
                        placeholder="Отдел"
                        optionFilterProp="children"
                        name="department"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        {departments.map( item => <Option key={item._id} value={item._id}
                                                          selected>{item.name}</Option> )}
                    </Select>
                </FormField>

                <FormField name="password" label="Password" rules={[ { message: 'Please input user password!', }, ]}
                           hasFeedback>
                    <Input.Password name="password"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default UserModal;