import {
    ADD_DEPARTMENT,
    GET_DEPARTMENT,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    DEPARTMENT_ERROR,
    GET_DEPARTMENTS,
    CLEAR_DEPARTMENT,
    SET_DEPARTMENT_LOADING
} from '../utils/consts';

const initialState = {
    department: null,
    departments: [],
    isLoading: false
};

const departments = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_DEPARTMENT:
            return {
                ...state,
                departments: [ payload, ...state.departments ],
            };
        case GET_DEPARTMENTS:
            return {
                ...state,
                departments: payload,
                isLoading: false,
            };
        case GET_DEPARTMENT:
        case UPDATE_DEPARTMENT:
            return {
                ...state,
                department: payload,
                isLoading: false
            };
        case DELETE_DEPARTMENT:
            return {
                ...state,
                department: null,
                isLoading: false
            };
        case SET_DEPARTMENT_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_DEPARTMENT:
            return {
                ...state,
                department: null,
            };
        case DEPARTMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                department: null
            };
        default:
            return state;
    }
};

export default departments;