import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeClients, addClients, updateClients } from '../../actions/clients';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    type: '',
    name: '',
    address: '',
    phone: '',
    email: ''
};
const { TextArea } = Input;

const ClientsModal = ( { visible, onCreate, onCancel, history } ) => {

    const client = useSelector( state => state.clients.client );
    const isLoading = useSelector( state => state.clients.isLoading );
    const dispatch = useDispatch();

    const [ clientData, setClientData ] = useState( client || initialState );

    useEffect( () => {
        setClientData( client !== null ? client : initialState );
    }, [ client ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newClientData = {
                    type: values.type,
                    name: values.name,
                    address: values.address,
                    phone: values.phone,
                    email: values.email,
                };
                const newData = { ...clientData, ...newClientData };
                setClientData( newData );
                if ( client !== null && client._id ) {
                    dispatch( updateClients( client._id, newData ) );
                } else {
                    dispatch( addClients( newData ) );
                }
                form.submit();
                form.resetFields();
                setClientData( initialState );
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 8 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 16 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={client !== null && client._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={800}
            footer={[
                client !== null && client._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeClients( client._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {client !== null && client._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="clientForm"
                size="middle"

                initValues={clientData}
            >
                <FormField name="type" label="Тип"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Тип',
                               whitespace: true
                           } ]}>
                    <Input name="type"/>
                </FormField>
                <FormField name="name" label="Наименование"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
                <FormField name="address" label="Адрес"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование!',
                           } ]}>
                    <TextArea autoSize={{ minRows: 2, maxRows: 6 }}/>
                </FormField>
                <FormField name="phone" label="Номер телефона" >
                    <Input type="tel"/>
                </FormField>
                <FormField type="email" name="email" label="Email"
                           rules={[ {
                               type: 'email',
                               message: 'Пожалуйста введите корректный email адрес',
                           } ]}>
                    <Input name="email"/>
                </FormField>
            </AppForm>
        )}

        </Modal>
    );
};

export default ClientsModal;