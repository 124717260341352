import {
    ADD_LAB,
    GET_LAB,
    UPDATE_LAB,
    DELETE_LAB,
    LAB_ERROR,
    GET_LABS,
    CLEAR_LAB,
    SET_LAB_LOADING
} from '../utils/consts';

const initialState = {
    lab: null,
    labsList: [],
    isLoading: false
};

const labs = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_LAB:
            return {
                ...state,
                labsList: [ payload, ...state.labsList ],
            };
        case GET_LABS:
            return {
                ...state,
                labsList: payload,
                isLoading: false,
            };
        case GET_LAB:
        case UPDATE_LAB:
            return {
                ...state,
                lab: payload,
                isLoading: false
            };
        case DELETE_LAB:
            return {
                ...state,
                lab: null,
                isLoading: false
            };
        case SET_LAB_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_LAB:
            return {
                ...state,
                lab: null,
            };
        case LAB_ERROR:
            return {
                ...state,
                isLoading: false,
                lab: null
            };
        default:
            return state;
    }
};

export default labs;