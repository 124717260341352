import {
    ADD_CERT_SCHEMA,
    GET_CERT_SCHEMA,
    UPDATE_CERT_SCHEMA,
    DELETE_CERT_SCHEMA,
    CERT_SCHEMA_ERROR,
    GET_CERT_SCHEMAS,
    CLEAR_CERT_SCHEMA,
    SET_CERT_SCHEMA_LOADING
} from '../utils/consts';

const initialState = {
    certSchema: null,
    certSchemasList: [],
    isLoading: false
};

const certSchemas = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_CERT_SCHEMA:
            return {
                ...state,
                certSchemasList: [ payload, ...state.certSchemasList ],
            };
        case GET_CERT_SCHEMAS:
            return {
                ...state,
                certSchemasList: payload,
                isLoading: false,
            };
        case GET_CERT_SCHEMA:
        case UPDATE_CERT_SCHEMA:
            return {
                ...state,
                certSchema: payload,
                isLoading: false
            };
        case DELETE_CERT_SCHEMA:
            return {
                ...state,
                certSchema: null,
                isLoading: false
            };
        case SET_CERT_SCHEMA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_CERT_SCHEMA:
            return {
                ...state,
                certSchema: null,
            };
        case CERT_SCHEMA_ERROR:
            return {
                ...state,
                isLoading: false,
                certSchema: null
            };
        default:
            return state;
    }
};

export default certSchemas;