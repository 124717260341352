import React from 'react';

import '../Qsm.css';

const RiskMap = () => {
    return (
        <div className="QsmTableWrapper">
            <table className="QsmTable riskMap">
                <thead className="QsmTableHead">
                <tr>
                    <th rowSpan={2}>Вероятность</th>
                    <th colSpan={5}>Последствия</th>
                </tr>
                <tr>
                    <th>Очень маленькое</th>
                    <th>Небольшое</th>
                    <th>Среднее</th>
                    <th>Серьезное</th>
                    <th>Очень серьезное</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Очень высокая</td>
                    <td className="bg-yellow"></td>
                    <td className="bg-orange"></td>
                    <td className="bg-red"></td>
                    <td className="bg-red"></td>
                    <td className="bg-red"></td>
                </tr>
                <tr>
                    <td>Высокая</td>
                    <td className="bg-green"></td>
                    <td className="bg-yellow"></td>
                    <td className="bg-orange"></td>
                    <td className="bg-red"></td>
                    <td className="bg-red"></td>
                </tr>
                <tr>
                    <td>Средняя</td>
                    <td className="bg-green"></td>
                    <td className="bg-yellow"></td>
                    <td className="bg-yellow"></td>
                    <td className="bg-orange"></td>
                    <td className="bg-red"></td>
                </tr>
                <tr>
                    <td>Низкая</td>
                    <td className="bg-green"></td>
                    <td className="bg-green"></td>
                    <td className="bg-yellow"></td>
                    <td className="bg-yellow"></td>
                    <td className="bg-orange"></td>
                </tr>
                <tr>
                    <td>Очень низкая</td>
                    <td className="bg-green"></td>
                    <td className="bg-green"></td>
                    <td className="bg-green"></td>
                    <td className="bg-green"></td>
                    <td className="bg-yellow"></td>
                </tr>
                </tbody>
            </table>
            <div className="QsmTableWrapper">
                <table className="QsmTable">
                    <tbody>
                    <tr>
                        <td className="bg-green">1-4</td>
                        <td>Незначительный, минимальный риск (нет необходимости принимать меры, постоянный мониторинг не
                            требуется)
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-yellow">5-9</td>
                        <td>Приемлемый, низкий риск (постоянный мониторинг, является ли он все еще в приемлемых
                            пределах)
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-orange">10-14</td>
                        <td>Средний, менее серьезный риск (планирование мер и снижение рисков в установленные сроки
                            мониторинг)
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-red">15-25</td>
                        <td>Неприемлемый/серьезный риск (немедленные меры по снижению риска и последующий мониторинг)
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default RiskMap;