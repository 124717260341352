import {$api} from "../http";

class SubdivisionService {

    async addSubdivision( name ) {
        return $api.post( '/subdivision', { name } );
    }

    async getAllSubdivision() {
        return $api.get( '/subdivision' );
    }

    async updateSubdivision( subdivisionId, name ) {
        return $api.put( `/subdivision/${subdivisionId}`, { name } );
    }

    async removeSubdivision( subdivisionId ) {
        return $api.delete( `/subdivision/${subdivisionId}` );
    }

    async getSubdivisionById( subdivisionId ) {
        return $api.get( `/subdivision/${subdivisionId}` );
    }
}

export default new SubdivisionService();

