import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, Row, Col, Space, Checkbox, Typography, InputNumber } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import {
    updateApplication,
    addApplication,
    generateSamplingPdf
} from '../../actions/applications';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";

const initialState = {
    sampling_number: 0,
    sampling_date: moment(),
    sampling_address: '',
    inspection_result: '',
    inspection_app: false,
    packaging_labeling: 'Упаковка и маркировка соответствует требованиям НД',
    destination: '',
    characteristics: '',
    storage_conditions: 'Условия хранения удовлетворительные и соответствует требованиям НД',
    additional_info: '',
    customer: '',
    cb_user: '',
    inspector: '',
    return_date: moment(),
    return_state: 'не подвергшиеся разрушению или частично разрушенные в период испытаний возвращены заявителю',
    app_objects: []
};

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const ApplicationSampling = ( { data, users } ) => {

    const isLoading = useSelector( state => state.applications.isLoading );
    const dispatch = useDispatch();

    if ( data && data.sampling ) {
        data.sampling.sampling_date = moment( data.sampling.sampling_date );
        data.sampling.return_date = moment( data.sampling.return_date );
        data.app_objects.map( item => item.production_date = moment( item.production_date ) )
    }

    const [ appData, setAppData ] = useState( data || initialState );

    useEffect( () => {
        setAppData( data !== null ? data : initialState );
    }, [ data ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newAppData = {
                    sampling_number: values.sampling_number,
                    sampling_date: values.sampling_date,
                    sampling_address: values.sampling_address,
                    inspection_result: values.inspection_result,
                    inspection_app: values.inspection_app,
                    packaging_labeling: values.packaging_labeling,
                    destination: values.destination,
                    characteristics: values.characteristics,
                    storage_conditions: values.storage_conditions,
                    additional_info: values.additional_info,
                    customer: values.customer,
                    cb_user: values.cb_user,
                    inspector: values.inspector,
                    return_date: values.return_date,
                    return_state: values.return_state,
                };

                const newData = { ...appData, sampling: newAppData, app_objects: values.app_objects };

                setAppData( newData );
                if ( appData !== null && appData._id ) {
                    dispatch( updateApplication( appData._id, newData ) );
                } else {
                    dispatch( addApplication( newData ) );
                }
                form.submit();
                form.resetFields();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };
    const onFieldsChange = ( changedFields, allFields ) => {
        // setAppData( { ...appData, ...allFields } );
    };

    return (
        <>
            {isLoading ? <Loading/> : (
                <>
                    <AppForm
                        formItemLayout={formItemLayout}
                        formInit={form}
                        name="appForm"
                        size="middle"
                        labelWrap="true"
                        onValuesChange={onFieldsChange}
                        initValues={{
                            ...appData.sampling,
                            app_objects: [ ...appData.app_objects ],
                            sampling_number: appData.sampling?.sampling_number ? appData.sampling.sampling_number : appData.request_number,
                        }}
                    >
                        <Row gutter={15}>
                            <Col xs={24}>
                                <Space style={{ marginBottom: '20px' }}>
                                    {data !== null && data._id ?
                                        <>
                                            <Button type="primary" icon={<DownloadOutlined/>}
                                                    onClick={() => dispatch( generateSamplingPdf( data._id ) )}
                                            >
                                                Акты отбора, идентификации и возврата
                                            </Button>
                                        </>
                                        : ""
                                    }
                                </Space>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }}>
                                <Form.Item name="sampling_number" label="№ акта отбора"
                                           rules={[ {
                                               required: true,
                                               message: 'Пожалуйста введите № акта отбора',
                                           } ]}>
                                    <Input name="sampling_number"/>
                                </Form.Item>
                                <Form.Item
                                    name="sampling_date" label="Дата отбора"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Дата отбора',
                                    } ]}>
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="sampling_address"
                                    label="Наименование и адрес организации, где производился отбор образцов (проб)"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Наименование и адрес организации, где производился отбор образцов (проб)',
                                    } ]}>
                                    <TextArea name="sampling_address"/>
                                </Form.Item>
                                <Form.Item
                                    name="inspection_result"
                                    label="Результат наружного осмотра партии"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Результат наружного осмотра партии',
                                    } ]}>
                                    <TextArea name="inspection_result"/>
                                </Form.Item>
                                <Form.Item
                                    valuePropName="checked"
                                    name="inspection_app"
                                    label="Результат наружного осмотра в приложении"
                                >
                                    <Checkbox>

                                    </Checkbox>
                                </Form.Item>

                                <Form.Item
                                    name="packaging_labeling"
                                    label="Сведения об упаковке и маркировке"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Сведения об упаковке и маркировке',
                                    } ]}>
                                    <Input name="packaging_labeling"/>
                                </Form.Item>
                                <Form.Item
                                    name="destination"
                                    label="Предназначение">
                                    <Input name="destination"/>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }}>
                                <Form.Item
                                    name="characteristics"
                                    label="Основные характеристики"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Основные характеристики',
                                    } ]}>
                                    <TextArea name="characteristics"/>
                                </Form.Item>
                                <Form.Item
                                    name="storage_conditions"
                                    label="Условия хранения"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Условия хранения',
                                    } ]}>
                                    <Input name="storage_conditions"/>
                                </Form.Item>
                                <Form.Item
                                    name="additional_info"
                                    label="Дополнительная информация">
                                    <Input name="additional_info"/>
                                </Form.Item>
                                <Form.Item
                                    name="customer"
                                    label="От изготовителя (заказчика)"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите ответственного от изготовителя (заказчика)',
                                    } ]}>
                                    <Input name="customer"/>
                                </Form.Item>
                                <Form.Item
                                    name="cb_user"
                                    label="От органа по сертификации"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите ответственного от органа по сертификации',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="От органа по сертификации"
                                        optionFilterProp="children"
                                        name="cb_user"
                                        filterOption={( input, option ) =>
                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                        }
                                    >
                                        {users?.filter( item => item?.department?.name.includes('Орган по сертификации продукции') || item?.department?.name.includes('АУП')).map(item => <Option key={item._id} value={item._id} selected>{item.name}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="inspector"
                                    label="Инспектор таможенного поста">
                                    <Input name="inspector"/>
                                </Form.Item>
                                <Form.Item
                                    name="return_date"
                                    label="Дата возврата образов"
                                >
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="return_state"
                                    label="Состояние образцов после возврата"
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Состояние образцов после возврата"
                                        optionFilterProp="children"
                                        name="return_state"
                                        filterOption={( input, option ) =>
                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                        }
                                    >
                                        <Option
                                            value="не подвергшиеся разрушению или частично разрушенные в период испытаний возвращены заявителю"
                                            selected>не подвергшиеся разрушению или частично разрушенные в период
                                            испытаний возвращены заявителю</Option>
                                        <Option value="были полностью разрушены в период испытания">были полностью
                                            разрушены в период испытания</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24}>
                                <Row gutter={10}>
                                    <Col>
                                        <Title level={4}>Объекты</Title>
                                    </Col>
                                </Row>

                                <div className="AppObjectsWrapper">
                                    <table className="AppObjectsTable">
                                        <thead className="AppObjectsTableHead">
                                        <tr>
                                            <th>№</th>
                                            <th>Наименование продукции</th>
                                            <th>Размер партии</th>
                                            <th>Кол-во <br/>отобранных<br/> образцов</th>
                                            <th>Для испытаний</th>
                                            <th>Для <br/>контрольных<br/> образцов</th>
                                            <th>Наименование (марка, модель, тип, сорт при наличии)</th>
                                            <th>Страна и наименование производителя</th>
                                            <th>Основные характеристики</th>
                                            <th>Дата изготовления</th>
                                            <th>Срок годности</th>
                                            <th>Дополнительные информации (при наличии)</th>
                                        </tr>
                                        </thead>

                                        <Form.List name="app_objects">
                                            {( fields ) => (
                                                <tbody>
                                                    {fields.map( ( { key, name, ...restField } ) => (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>
                                                                {appData?.app_objects[ key ]?.object_name}
                                                            </td>
                                                            <td>
                                                                {appData?.app_objects[ key ]?.quantity} {appData?.app_objects[ key ]?.unit}
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'selected_samples' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите кол-во отобранных образцов',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumber/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'for_testing' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите кол-во отобранных образцов для испытаний',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumber/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'for_control' ]}
                                                                >
                                                                    <InputNumber/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'object_real_name' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите Наименование продукции',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'producing_country_company' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите страну и наименование производителя',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'characteristics' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите основные характеристики',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TextArea/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'production_date' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите дату изготовления',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker format='DD.MM.YYYY'/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'expiration_date' ]}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'additional_info' ]}
                                                                >
                                                                    <TextArea/>
                                                                </Form.Item>
                                                            </td>

                                                        </tr>
                                                    ) )}
                                                </tbody>
                                            )}

                                        </Form.List>

                                    </table>
                                </div>
                            </Col>
                            <Col xs={24} sm={4} offset={10}>
                                <Space style={{marginTop: '15px'}}>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onFormSubmit}
                                    >
                                        {data !== null && data._id ? "Обновить" : "Добавить"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </AppForm>
                </>
            )}
        </>
    );
};

export default ApplicationSampling;