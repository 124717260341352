import {$api} from "../http";

class DepartmentService {

    async addDepartment( name ) {
        return $api.post( '/department', { name } );
    }

    async getAllDepartments() {
        return $api.get( '/department' );
    }

    async updateDepartment( departmentId, name ) {
        return $api.put( `/department/${departmentId}`, { name } );
    }

    async removeDepartment( departmentId ) {
        return $api.delete( `/department/${departmentId}` );
    }

    async getDepartmentById( departmentId ) {
        return $api.get( `/department/${departmentId}` );
    }
}

export default new DepartmentService();

