import {
    ADD_SUBDIVISION,
    GET_SUBDIVISION,
    UPDATE_SUBDIVISION,
    DELETE_SUBDIVISION,
    SUBDIVISION_ERROR,
    GET_SUBDIVISIONS,
    CLEAR_SUBDIVISION,
    SET_SUBDIVISION_LOADING
} from '../utils/consts';

const initialState = {
    subdivision: null,
    subdivisionsList: [],
    isLoading: false
};

const subdivisions = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_SUBDIVISION:
            return {
                ...state,
                subdivisionsList: [ payload, ...state.subdivisionsList ],
            };
        case GET_SUBDIVISIONS:
            return {
                ...state,
                subdivisionsList: payload,
                isLoading: false,
            };
        case GET_SUBDIVISION:
        case UPDATE_SUBDIVISION:
            return {
                ...state,
                subdivision: payload,
                isLoading: false
            };
        case DELETE_SUBDIVISION:
            return {
                ...state,
                subdivision: null,
                isLoading: false
            };
        case SET_SUBDIVISION_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_SUBDIVISION:
            return {
                ...state,
                subdivision: null,
            };
        case SUBDIVISION_ERROR:
            return {
                ...state,
                isLoading: false,
                subdivision: null
            };
        default:
            return state;
    }
};

export default subdivisions;