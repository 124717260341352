import React, { useState } from 'react';
import { Layout } from 'antd';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

import './Wrapper.css';

const { Content } = Layout;

const Wrapper = ({children}) => {
    const [ toggle, setToggle ] = useState( false );

    const changeToggle = () => {
        console.log('click');
        setToggle( !toggle );
    };

    return (
        <Layout className="appLayout">
            <Sidebar toggle={toggle} />
            <Layout className="site-layout">
                <Header changeToggle={changeToggle} toggle={toggle}/>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default Wrapper;