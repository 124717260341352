import { SET_NOTIFY, CLEAR_NOTIFY } from '../utils/consts';

const initialState = {
    notify: null
};

const notifications = ( state = initialState, action ) => {
    const { type, payload } = action;

    switch ( type ) {
        case SET_NOTIFY:
            return {
                ...state,
                notify: payload
            };
        case CLEAR_NOTIFY:
            return {
                ...state,
                notify: null
            };
        default:
            return state;
    }
};

export default notifications;
