import React, { useEffect, useState } from 'react';
import { Button, Empty, Space, Typography } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import appNotification from "../../components/AppNotification/AppNotification";
import { getRiskByUser } from "../../actions/risks";
import RiskAddModal from "../../components/Modals/RiskAddModal";
import { CLEAR_RISK } from "../../utils/consts";
import '../Qsm.css';
import Loading from "../../components/Loading/Loading";

const { Title } = Typography;
const RiskAddJournal = () => {
    const dispatch = useDispatch();
    const user = useSelector( state => state.auth.user );
    const risks = useSelector( state => state.risks.risks );
    const isLoading = useSelector( state => state.risks.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const [ visible, setVisible ] = useState( false );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getRiskByUser( user.id ) );
    }, [ dispatch, user.id ] );

    const onCreate = () => {
        setVisible( false );
    };

    return (
        <>
            <Title level={3}>Журнал регистрации рисков</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_RISK } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            {isLoading ? <Loading/> : risks.length > 0 ? (
                <div className="QsmTableWrapper">
                    <table className="QsmTable">
                        <thead className="QsmTableHead">
                        <tr>
                            <th>№ п/п</th>
                            <th>Дата обнаружения риска</th>
                            <th>Содержание обнаруженного риска</th>
                            <th>Ф.И.О. сотрудника, обнаружившего риск</th>
                            <th>Статус риска (отметка о принятии риска)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            risks.map( ( risk, index ) => (
                                <tr key={risk._id}>
                                    <td>{index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{moment( risk.risk_date ).format( 'DD.MM.YYYY' )}</td>
                                    <td>{risk.risk_content}</td>
                                    <td>{risk?.employee?.name}</td>
                                    <td>{risk.status}</td>
                                </tr>
                            ) )
                        }
                        </tbody>
                    </table>
                </div>

            ) : ( <Empty/> )}
            <RiskAddModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </> )
};

export default RiskAddJournal;