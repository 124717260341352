import {
    ADD_NONCONFORMITY,
    GET_NONCONFORMITY,
    GET_NONCONFORMITY_USER,
    UPDATE_NONCONFORMITY,
    DELETE_NONCONFORMITY,
    NONCONFORMITY_ERROR,
    GET_ALL_NONCONFORMITY,
    CLEAR_NONCONFORMITY,
    SET_NONCONFORMITY_LOADING,
    GET_LAST_NONCONFORMITY
} from '../utils/consts';

import { setNotify } from './notications';

import NonconformityService from "../services/NonconformityService";

// Get All Nonconformity
export const getAllNonconformity = () => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );
    try {
        const response = await NonconformityService.getAllNonconformity();
        dispatch( {
            type: GET_ALL_NONCONFORMITY,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};

// Get Nonconformity by ID
export const getNonconformityById = ( nonconformityId ) => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );
    try {
        const response = await NonconformityService.getNonconformityById( nonconformityId );
        dispatch( {
            type: GET_NONCONFORMITY,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};

// Get Nonconformity by User
export const getNonconformityByUser = ( userId ) => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );

    try {
        const response = await NonconformityService.getNonconformityByUser( userId );

        dispatch( {
            type: GET_NONCONFORMITY_USER,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};

// Get LAST Nonconformity
export const getLastNonconformity = () => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );
    try {
        const response = await NonconformityService.getLastNonconformity();
        dispatch( {
            type: GET_LAST_NONCONFORMITY,
            payload: response.data
        } );


    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};

// Add Nonconformity
export const addNonconformity = (
    {
        audit_code,
        nonconformity_code,
        nonconformity_date,
        analysis_type,
        employee,
        subdivision,
        influence,
        document_designation,
        document_section,
        nonconformity_description,
        nonconformity_reason,
        corrective_number,
        corrective_description,
        elimination_terms,
        elimination_responsible,
        completion_mark,
        corrective_confirmation,
        analysis_result,
        r_corrective_description,
        r_elimination_terms,
        r_elimination_responsible,
        r_completion_mark,
        r_corrective_confirmation,
        r_analysis_result,
        status
    }
) => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );
    try {
        const response = await NonconformityService.addNonconformity(
            audit_code,
            nonconformity_code,
            nonconformity_date,
            analysis_type,
            employee,
            subdivision,
            influence,
            document_designation,
            document_section,
            nonconformity_description,
            nonconformity_reason,
            corrective_number,
            corrective_description,
            elimination_terms,
            elimination_responsible,
            completion_mark,
            corrective_confirmation,
            analysis_result,
            r_corrective_description,
            r_elimination_terms,
            r_elimination_responsible,
            r_completion_mark,
            r_corrective_confirmation,
            r_analysis_result,
            status
        );
        dispatch( {
            type: ADD_NONCONFORMITY,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getLastNonconformity() );
        dispatch( getAllNonconformity() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};

// Update Nonconformity
export const updateNonconformity = (
    nonconformityId,
    {
        audit_code,
        nonconformity_code,
        nonconformity_date,
        analysis_type,
        employee,
        subdivision,
        influence,
        document_designation,
        document_section,
        nonconformity_description,
        nonconformity_reason,
        corrective_number,
        corrective_description,
        elimination_terms,
        elimination_responsible,
        completion_mark,
        corrective_confirmation,
        analysis_result,
        r_corrective_description,
        r_elimination_terms,
        r_elimination_responsible,
        r_completion_mark,
        r_corrective_confirmation,
        r_analysis_result,
        status
    }
) => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );
    try {
        const response = await NonconformityService.updateNonconformity(
            nonconformityId,
            audit_code,
            nonconformity_code,
            nonconformity_date,
            analysis_type,
            employee,
            subdivision,
            influence,
            document_designation,
            document_section,
            nonconformity_description,
            nonconformity_reason,
            corrective_number,
            corrective_description,
            elimination_terms,
            elimination_responsible,
            completion_mark,
            corrective_confirmation,
            analysis_result,
            r_corrective_description,
            r_elimination_terms,
            r_elimination_responsible,
            r_completion_mark,
            r_corrective_confirmation,
            r_analysis_result,
            status
        );
        dispatch( {
            type: UPDATE_NONCONFORMITY,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getLastNonconformity() );
        dispatch( getAllNonconformity() );
        dispatch( getNonconformityById( nonconformityId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};

// Delete Nonconformity
export const removeNonconformity = ( nonconformityId ) => async dispatch => {
    dispatch( { type: SET_NONCONFORMITY_LOADING } );
    dispatch( { type: CLEAR_NONCONFORMITY } );
    try {
        await NonconformityService.removeNonconformity( nonconformityId );
        dispatch( {
            type: DELETE_NONCONFORMITY,
            payload: nonconformityId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getLastNonconformity() );
        dispatch( getAllNonconformity() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: NONCONFORMITY_ERROR
        } );
    }
};




