import {
    ADD_QSMONEDOCTYPE,
    GET_QSMONEDOCTYPE,
    UPDATE_QSMONEDOCTYPE,
    DELETE_QSMONEDOCTYPE,
    QSMONEDOCTYPE_ERROR,
    GET_ALLQSMONEDOCTYPE,
    CLEAR_QSMONEDOCTYPE,
    SET_QSMONEDOCTYPE_LOADING,
} from '../utils/consts';

const initialState = {
    qsmOneDocType: null,
    qsmOneDocTypes: [],
    isLoading: false
};

const qsmOneDocTypes = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_QSMONEDOCTYPE:
            return {
                ...state,
                qsmOneDocTypes: [ payload, ...state.qsmOneDocTypes ],
            };
        case GET_ALLQSMONEDOCTYPE:
            return {
                ...state,
                qsmOneDocTypes: payload,
                isLoading: false,
            };
        case GET_QSMONEDOCTYPE:
        case UPDATE_QSMONEDOCTYPE:
            return {
                ...state,
                qsmOneDocType: payload,
                isLoading: false
            };
        case DELETE_QSMONEDOCTYPE:
            return {
                ...state,
                qsmOneDocType: null,
                loading: false
            };
        case SET_QSMONEDOCTYPE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_QSMONEDOCTYPE:
            return {
                ...state,
                qsmOneDocType: null,
            };
        case QSMONEDOCTYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                qsmOneDocType: null
            };
        default:
            return state;
    }
};

export default qsmOneDocTypes;