import {
    ADD_ROLE,
    GET_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
    ROLE_ERROR,
    GET_ROLES,
    CLEAR_ROLE,
    SET_ROLE_LOADING
} from '../utils/consts';

const initialState = {
    role: null,
    roles: [],
    isLoading: false
};

const roles = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_ROLE:
            return {
                ...state,
                roles: [ payload, ...state.roles ],
            };
        case GET_ROLES:
            return {
                ...state,
                roles: payload,
                isLoading: false,
            };
        case GET_ROLE:
        case UPDATE_ROLE:
            return {
                ...state,
                role: payload,
                isLoading: false
            };
        case DELETE_ROLE:
            return {
                ...state,
                role: null,
                isLoading: false
            };
        case SET_ROLE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_ROLE:
            return {
                ...state,
                role: null
            };

        case ROLE_ERROR:
            return {
                ...state,
                isLoading: false,
                role: null
            };
        default:
            return state;
    }
};

export default roles;