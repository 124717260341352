import {
    ADD_CLIENT,
    GET_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    CLIENT_ERROR,
    GET_CLIENTS,
    CLEAR_CLIENT,
    SET_CLIENT_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import ClientsService from "../services/ClientsService";

// Get All Clients
export const getAllClients = () => async dispatch => {
    dispatch( { type: SET_CLIENT_LOADING } );
    dispatch( { type: CLEAR_CLIENT } );
    try {
        const response = await ClientsService.getAllClients();
        dispatch( {
            type: GET_CLIENTS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CLIENT_ERROR
        } );
    }
};

// Get Clients by ID
export const getClientsById = ( clientId ) => async dispatch => {
    dispatch( { type: SET_CLIENT_LOADING } );
    dispatch( { type: CLEAR_CLIENT } );
    try {
        const response = await ClientsService.getClientsById( clientId );
        dispatch( {
            type: GET_CLIENT,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CLIENT_ERROR
        } );
    }
};


// Add Clients
export const addClients = ( { type, name, address, phone, email } ) => async dispatch => {
    dispatch( { type: SET_CLIENT_LOADING } );
    dispatch( { type: CLEAR_CLIENT } );
    try {
        const response = await ClientsService.addClients( type, name, address, phone, email );
        dispatch( {
            type: ADD_CLIENT,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllClients() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CLIENT_ERROR
        } );
    }
};
// Update Clients
export const updateClients = ( clientId, { type, name, address, phone, email } ) => async dispatch => {
    dispatch( { type: SET_CLIENT_LOADING } );
    dispatch( { type: CLEAR_CLIENT } );
    try {
        const response = await ClientsService.updateClients( clientId, type, name, address, phone, email );
        dispatch( {
            type: UPDATE_CLIENT,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllClients() );
        dispatch( getClientsById( clientId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CLIENT_ERROR
        } );
    }
};

// Delete Clients
export const removeClients = ( clientId ) => async dispatch => {
    dispatch( { type: SET_CLIENT_LOADING } );
    dispatch( { type: CLEAR_CLIENT } );
    try {
        await ClientsService.removeClients( clientId );
        dispatch( {
            type: DELETE_CLIENT,
            payload: clientId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllClients() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CLIENT_ERROR
        } );
    }
};




