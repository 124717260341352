import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm, Select, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import { addNonconformity, removeNonconformity, updateNonconformity } from "../../actions/nonconformity";
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const { Option } = Select;

const initialState = {
    audit_code: "",
    nonconformity_code: "",
    nonconformity_date: moment(),
    analysis_type: "",
    employee: "",
    subdivision: "",
    influence: "",
    document_designation: "",
    document_section: "",
    nonconformity_description: "",
    nonconformity_reason: "",
    corrective_number: "",
    corrective_description: "",
    elimination_terms: "",
    elimination_responsible: [],
    completion_mark: "Выполнено",
    corrective_confirmation: "",
    analysis_result: "",

    r_corrective_description: "",
    r_elimination_terms: "",
    r_elimination_responsible: [],
    r_completion_mark: "Выполнено",
    r_corrective_confirmation: "",
    r_analysis_result: "",
    status: "Не принято"
};

const { TextArea } = Input;

const NonconformityAddModal = ( { visible, onCreate, onCancel } ) => {

    const users = useSelector( state => state.users.users );
    const nonconformityLast = useSelector( state => state.nonconformity.nonconformityLast );
    const nonconformity = useSelector( state => state.nonconformity.nonconformity );
    const analysisList = useSelector( state => state.analysis.analysisList );
    const influencesList = useSelector( state => state.influences.influencesList );
    const subdivisionsList = useSelector( state => state.subdivisions.subdivisionsList );
    const isLoading = useSelector( state => state.nonconformity.isLoading );

    initialState.audit_code = nonconformityLast && nonconformityLast.length > 0 ? nonconformityLast[ 0 ].audit_code + 1 : '';
    initialState.nonconformity_code = nonconformityLast && nonconformityLast.length > 0 ? nonconformityLast[ 0 ].nonconformity_code + 1 : '';
    initialState.corrective_number = nonconformityLast && nonconformityLast.length > 0 ? nonconformityLast[ 0 ].corrective_number + 1 : '';

    const dispatch = useDispatch();
    if ( nonconformity ) {
        nonconformity.nonconformity_date = moment( nonconformity.nonconformity_date );
    }

    const [ nonconformityData, setNonconformityData ] = useState( nonconformity || initialState );

    useEffect( () => {
    }, [ dispatch ] );

    useEffect( () => {
        setNonconformityData( nonconformity !== null ? nonconformity : initialState );
    }, [ nonconformity ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newNonconformityData = {
                    audit_code: values.audit_code,
                    nonconformity_code: values.nonconformity_code,
                    nonconformity_date: values.nonconformity_date,
                    analysis_type: values.analysis_type,
                    employee: values.employee,
                    subdivision: values.subdivision,
                    influence: values.influence,
                    document_designation: values.document_designation,
                    document_section: values.document_section,
                    nonconformity_description: values.nonconformity_description,
                    nonconformity_reason: values.nonconformity_reason,
                    corrective_number: values.corrective_number,
                    corrective_description: values.corrective_description,
                    elimination_terms: values.elimination_terms,
                    elimination_responsible: values.elimination_responsible,
                    completion_mark: values.completion_mark,
                    corrective_confirmation: values.corrective_confirmation,
                    analysis_result: values.analysis_result,
                    r_corrective_description: values.r_corrective_description,
                    r_elimination_terms: values.r_elimination_terms,
                    r_elimination_responsible: values.r_elimination_responsible,
                    r_completion_mark: values.r_completion_mark,
                    r_corrective_confirmation: values.r_corrective_confirmation,
                    r_analysis_result: values.r_analysis_result,
                    status: values.status
                };

                const newData = { ...nonconformityData, ...newNonconformityData };

                setNonconformityData( newData );
                if ( nonconformity !== null && nonconformity._id ) {
                    dispatch( updateNonconformity( nonconformity._id, newData ) );
                } else {
                    dispatch( addNonconformity( newData ) );
                }
                form.submit();
                form.resetFields();
                setNonconformityData( initialState );
                onCreate();
            } )

            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const onChangeHandler = ( value ) => {

    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 12 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 12 },
            xs: { span: 24 },
        },
    };


    return (
        <Modal
            visible={visible}
            title={nonconformity !== null && nonconformity._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={1100}
            footer={[
                nonconformity !== null && nonconformity._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeNonconformity( nonconformity._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {nonconformity !== null && nonconformity._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="qsmOneForm"
                size="middle"
                initValues={{
                    ...nonconformityData,
                    analysis_type: nonconformityData.analysis_type._id,
                    employee: nonconformityData.employee._id,
                    subdivision: nonconformityData.subdivision._id,
                    influence: nonconformityData.influence._id,
                    elimination_responsible: [ ...nonconformityData.elimination_responsible.map( item => item._id ) ],
                    r_elimination_responsible: [ ...nonconformityData.r_elimination_responsible.map( item => item._id ) ],
                }}
                onValuesChange={onChangeHandler}
            >
                <Row gutter={15}>
                    <Col span={12}>
                        <h4>Основная информация</h4>
                        <FormField
                            name="audit_code" label="Код аудита"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Код аудита!',
                            } ]}>

                            <Input addonBefore="A-" name="audit_code"/>
                        </FormField>
                        <FormField
                            name="nonconformity_code" label="ID несоответсвия"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите ID несоответсвия!',
                            } ]}>
                            <Input addonBefore="H-" name="nonconformity_code"/>
                        </FormField>
                        <FormField
                            name="nonconformity_date" label="Дата обнаружения"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Дата обнаружения!',
                            } ]}>
                            <DatePicker format='DD.MM.YYYY'/>
                        </FormField>
                        <FormField
                            name="analysis_type"
                            label="Вид анализа"
                            rules={[ { required: true, message: 'Пожалуйста укажите Вид анализа!' } ]}>
                            <Select
                                showSearch
                                placeholder="Вид анализа"
                                optionFilterProp="children"
                                name="analysis_type"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {analysisList.map( item => <Option key={item._id} value={item._id}
                                                                   selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                        <FormField
                            name="employee"
                            label="Обнаружил несоответствие"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Ф. И. О. сотрудника, обнаружившего несоответствие!'
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Ф. И. О. сотрудника"
                                optionFilterProp="children"
                                name="employee"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {users.map( item => <Option key={item._id} value={item._id}
                                                            selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                        <FormField
                            name="subdivision"
                            label="Выявлено несоответствие"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Подразделение/ сотрудник, в котором/ у которого выявлено несоответствие!'
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Подразделение/ сотрудник"
                                optionFilterProp="children"
                                name="subdivision"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {subdivisionsList.map( item => <Option key={item._id} value={item._id}
                                                                       selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                        <FormField
                            name="influence"
                            label="Сфера влияния несоответствия"
                            rules={[ { required: true, message: 'Сфера влияния несоответствия!' } ]}>
                            <Select
                                showSearch
                                placeholder="Сфера влияния несоответствия"
                                optionFilterProp="children"
                                name="influence"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {influencesList.map( item => <Option key={item._id} value={item._id}
                                                                     selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                        <FormField
                            name="document_designation" label="Обозначение документа"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите обозначение документа!',
                                whitespace: true
                            } ]}>
                            <Input name="document_designation"/>
                        </FormField>
                        <FormField
                            name="document_section" label="Раздел, пункт документа"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите раздел, пункт документа!',
                                whitespace: true
                            } ]}>
                            <Input name="document_section"/>
                        </FormField>
                        <FormField
                            name="nonconformity_description" label="Описание несоответствия"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Описание несоответствия!',
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                        <FormField
                            name="nonconformity_reason" label="Причина несоответсвия"
                            rules={[ {
                                message: 'Пожалуйста укажите Причина несоответсвия!',
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                    </Col>
                    <Col span={12}>
                        <h4>Корректирующие действия</h4>
                        <FormField
                            name="corrective_number" label="№ корректирующих действий">
                            <Input addonBefore="K-" name="corrective_number"/>
                        </FormField>
                        <FormField
                            name="corrective_description" label="Содержание коррекции"
                            rules={[ {
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                        <FormField
                            name="elimination_terms" label="Сроки устранения"
                            rules={[ {
                                whitespace: true
                            } ]}>
                            <Input name="elimination_terms"/>
                        </FormField>
                        <FormField
                            name="elimination_responsible"
                            label="Ответственный по устранению">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Ответственный"
                                optionFilterProp="children"
                                name="elimination_responsible"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {users.map( item => <Option key={item._id} value={item._id}
                                                            selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                        <FormField
                            name="completion_mark" label="Отметка о выполнении"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Отметка о выполнении!',
                                whitespace: true
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Отметка о выполнении"
                                optionFilterProp="children"
                                name="completion_mark"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                <Option value="Выполнено" selected>Выполнено</Option>
                                <Option value="Не выполнено">Не выполнено</Option>
                            </Select>
                        </FormField>
                        <FormField
                            name="corrective_confirmation" label="Подтверждение выполнение"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Подтверждение выполнение корректирующих действий!',
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                        <FormField
                            name="analysis_result" label="Итоги анализа результативности"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Итоги анализа результативности корректирующих действий!',
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                        <FormField
                            name="status" label="Статус"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Статус документа!',
                                whitespace: true
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Статус документа"
                                optionFilterProp="children"
                                name="status"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                <Option value="Принято" selected>Принято</Option>
                                <Option value="Не принято">Не принято</Option>
                            </Select>
                        </FormField>
                        <h4>Повторные корректирующие действия</h4>
                        <FormField
                            name="r_corrective_description" label="Содержание коррекции"
                            rules={[ {
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                        <FormField
                            name="r_elimination_terms" label="Сроки устранения"
                            rules={[ {
                                whitespace: true
                            } ]}>
                            <Input name="r_elimination_terms"/>
                        </FormField>
                        <FormField
                            name="r_elimination_responsible"
                            label="Ответственный по устранению">
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="Ответственный"
                                optionFilterProp="children"
                                name="r_elimination_responsible"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {users.map( item => <Option key={item._id} value={item._id}
                                                            selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                        <FormField
                            name="r_completion_mark" label="Отметка о выполнении">
                            <Select
                                showSearch
                                placeholder="Отметка о выполнении"
                                optionFilterProp="children"
                                name="r_completion_mark"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                <Option value="Выполнено" selected>Выполнено</Option>
                                <Option value="Не выполнено">Не выполнено</Option>
                            </Select>
                        </FormField>
                        <FormField
                            name="r_corrective_confirmation" label="Подтверждение выполнение"
                            rules={[ {
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                        <FormField
                            name="r_analysis_result" label="Итоги анализа результативности"
                            rules={[ {
                                whitespace: true
                            } ]}>
                            <TextArea/>
                        </FormField>
                    </Col>
                </Row>
            </AppForm>

        )}

        </Modal>
    );
};

export default NonconformityAddModal;