import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm, Select } from 'antd';
import { removeStandart, addStandart, updateStandart } from '../../actions/standarts';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    code: '',
    name: '',
    product_name: '',
    standart_link: '',
    status: 'Действующий',
    description: ''
};

const { TextArea } = Input;
const { Option } = Select;

const StandartModal = ( { visible, onCreate, onCancel } ) => {

    const standart = useSelector( state => state.standarts.standart );
    const isLoading = useSelector( state => state.standarts.isLoading );
    const dispatch = useDispatch();

    const [ standartData, setStandartData ] = useState( standart || initialState );

    useEffect( () => {
        setStandartData( standart !== null ? standart : initialState )
    }, [ standart ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newStandartData = {
                    code: values.code,
                    name: values.name,
                    product_name: values.product_name,
                    standart_link: values.standart_link,
                    status: values.status,
                    description: values.description
                };
                const newData = { ...standartData, ...newStandartData };
                setStandartData( newData );
                if ( standart !== null && standart._id ) {
                    dispatch( updateStandart( standart._id, newData ) );
                } else {
                    dispatch( addStandart( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 12 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 12 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={standart !== null && standart._id ? "Изменить Запись" : "Добавить Запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                standart !== null && standart._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeStandart( standart._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {standart !== null && standart._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="roleForm"
                size="middle"
                initValues={standartData}
            >
                <FormField name="code" label="Обозначение"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Обозначение документа!',
                               whitespace: true
                           } ]}>
                    <Input name="code"/>
                </FormField>
                <FormField name="name" label="Наименование"
                           rules={[ {
                               // required: true,
                               // message: 'Пожалуйста введите наименование!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
                <FormField name="product_name" label="Наименование объекта"
                           rules={[ {
                               whitespace: true
                           } ]}>
                    <Input name="product_name"/>
                </FormField>
                <FormField
                    rules={[ {
                        type: 'url',
                    } ]}
                    name="standart_link"
                    label="Ссылка на документ">
                    <Input name="standart_link"/>
                </FormField>
                <FormField
                    name="status" label="Статус"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста укажите Статус!',
                        whitespace: true
                    } ]}>
                    <Select
                        showSearch
                        placeholder="Статус"
                        optionFilterProp="children"
                        name="status"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        <Option value="Действующий" selected>Действующий</Option>
                        <Option value="Не действующий">Не действующий</Option>
                    </Select>
                </FormField>
                <FormField
                    name="description" label="Примечание">
                    <TextArea/>
                </FormField>
            </AppForm>
        )}
        </Modal>
    );
};

export default StandartModal;