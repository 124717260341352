import axios from 'axios';

// export const API_URL = `http://localhost:5000/api`;
// export const PDF_URL = `http://localhost:5000/api/pdfreports`;
// export const SERVER_URL = `http://localhost:5000/`;
export const API_URL = `https://server.energotest.uz/api`;
export const PDF_URL = `https://server.energotest.uz/api/pdfreports`;
export const SERVER_URL = `https://server.energotest.uz/`;


export const $api = axios.create( {
    withCredentials: true,
    baseURL: API_URL
} );

$api.interceptors.request.use( ( config ) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem( 'token' )}`;
    return config;
} );

$api.interceptors.response.use( ( config ) => {
    return config;
}, async ( error ) => {
    const originalRequest = error.config;
    if ( error.response.status === 401 && error.config && !error.config._isRetry ) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get( `${API_URL}/user/refresh`, { withCredentials: true } );
            localStorage.setItem( 'token', response.data.accessToken );
            return $api.request( originalRequest );
        } catch ( e ) {
            console.log( 'НЕ АВТОРИЗОВАН' )
        }
    }
    throw error;
} );

