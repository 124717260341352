import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm, Select } from 'antd';
import { removeTnved, addTnved, updateTnved } from '../../actions/tnveds';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";

const initialState = {
    code: '',
    name: '',
    economic_code: '',
    certification_code: '',
    standarts: []
};
const { Option } = Select;

const TnvedModal = ( { visible, onCreate, onCancel } ) => {

    const tnved = useSelector( state => state.tnveds.tnved );
    const isLoading = useSelector( state => state.tnveds.isLoading );
    const economicCodeList = useSelector( state => state.economicCode.economicCodeList );
    const certificationCodeList = useSelector( state => state.certificationCode.certificationCodeList );
    const standartsList = useSelector( state => state.standarts.standarts );
    const dispatch = useDispatch();

    const [ tnvedData, setTnvedData ] = useState( tnved || initialState );

    useEffect( () => {
        setTnvedData( tnved !== null ? tnved : initialState );
    }, [ tnved ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newTnvedData = {
                    code: values.code,
                    name: values.name,
                    economic_code: values.economic_code,
                    certification_code: values.certification_code,
                    standarts: values.standarts
                };
                const newData = { ...tnvedData, ...newTnvedData };
                setTnvedData( newData );
                if ( tnved !== null && tnved._id ) {
                    dispatch( updateTnved( tnved._id, newData ) );
                } else {
                    dispatch( addTnved( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={tnved !== null && tnved._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={700}
            footer={[
                tnved !== null && tnved._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeTnved( tnved._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {tnved !== null && tnved._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="tnvedForm"
                size="middle"
                initValues={{
                    ...tnvedData,
                    economic_code: tnvedData?.economic_code?._id,
                    certification_code: tnvedData?.certification_code?._id,
                    standarts: [...tnvedData.standarts.map(item => item._id)],
                }}
            >
                <Form.Item name="code" label="Код ТН ВЭД"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите код ТН ВЭД!',
                           } ]}>
                    <Input name="code"/>
                </Form.Item>
                <Form.Item name="name" label="Наименование"
                           rules={[ {
                               // required: true,
                               // message: 'Пожалуйста введите наименование!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </Form.Item>
                <Form.Item
                    name="economic_code"
                    label="Код экономического сектора"
                >
                    <Select
                        showSearch
                        placeholder="Код экономического сектора"
                        optionFilterProp="children"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        {economicCodeList.map( item => <Option key={item._id} value={item._id}
                                                               selected>{item.code}</Option> )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="certification_code"
                    label="Код объекта сертификации"
                >
                    <Select
                        showSearch
                        placeholder="Код экономического сектора"
                        optionFilterProp="children"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        {certificationCodeList.map( item => <Option key={item._id} value={item._id}
                                                                    selected>{item.code}</Option> )}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="standarts"
                    label="Нормативные документы"
                >
                    <Select
                        showSearch
                        placeholder="Нормативные документы"
                        optionFilterProp="children"
                        mode="multiple"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        {standartsList.map( item => <Option key={item._id} value={item._id}
                                                            selected>{item.code}</Option> )}

                    </Select>
                </Form.Item>
            </AppForm>

        )}

        </Modal>
    );
};

export default TnvedModal;