import {
    ADD_RISK,
    GET_RISK,
    GET_RISK_USER,
    UPDATE_RISK,
    DELETE_RISK,
    RISK_ERROR,
    GET_RISKS,
    CLEAR_RISK,
    SET_RISK_LOADING
} from '../utils/consts';

const initialState = {
    risk: null,
    risks: [],
    isLoading: false
};

const risks = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_RISK:
            return {
                ...state,
                risks: [ payload, ...state.risks ],
            };
        case GET_RISKS:
            return {
                ...state,
                risks: payload,
                isLoading: false,
            };
        case GET_RISK_USER:
            return {
                ...state,
                risks: payload,
                isLoading: false,
            };
        case GET_RISK:
        case UPDATE_RISK:
            return {
                ...state,
                risk: payload,
                isLoading: false
            };
        case DELETE_RISK:
            return {
                ...state,
                risk: null,
                isLoading: false
            };
        case SET_RISK_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_RISK:
            return {
                ...state,
                risk: null,
            };
        case RISK_ERROR:
            return {
                ...state,
                isLoading: false,
                risk: null
            };

        default:
            return state;
    }
};

export default risks;