import {
    ADD_POSITION,
    GET_POSITION,
    UPDATE_POSITION,
    DELETE_POSITION,
    POSITION_ERROR,
    GET_POSITIONS,
    CLEAR_POSITION,
    SET_POSITION_LOADING
} from '../utils/consts';

const initialState = {
    position: null,
    positions: [],
    isLoading: false
};

const positions = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_POSITION:
            return {
                ...state,
                positions: [ payload, ...state.positions ],
            };
        case GET_POSITIONS:
            return {
                ...state,
                positions: payload,
                isLoading: false,
            };
        case GET_POSITION:
        case UPDATE_POSITION:
            return {
                ...state,
                position: payload,
                isLoading: false
            };
        case DELETE_POSITION:
            return {
                ...state,
                position: null,
                isLoading: false
            };
        case SET_POSITION_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_POSITION:
            return {
                ...state,
                position: null,
            };
        case POSITION_ERROR:
            return {
                ...state,
                isLoading: false,
                position: null
            };
        default:
            return state;
    }
};

export default positions;