import React, { useEffect } from 'react';
import { Button, Empty, Popconfirm, Space, Typography } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import appNotification from "../../components/AppNotification/AppNotification";
import { getAllApplication, getApplicationById, removeApplication } from "../../actions/applications";
import { CLEAR_APP } from "../../utils/consts";
import '../Qsm.css';
import Loading from "../../components/Loading/Loading";

const { Title } = Typography;
const JournalQSMDesignation = () => {
    const dispatch = useDispatch();
    const applicationsList = useSelector( state => state.applications.applicationsList );
    const isLoading = useSelector( state => state.applications.isLoading );
    const notify = useSelector( state => state.notifications.notify );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllApplication() );
    }, [ dispatch ] );

    return (
        <>
            <Title level={3}>Заявки</Title>
            <Space style={{ marginBottom: 16 }}>
                <RouterLink to={`/applications/add`}>
                    <Button type="primary" htmlType="button" onClick={() => dispatch( { type: CLEAR_APP } )}>Добавить запись</Button>
                </RouterLink>
            </Space>
            {isLoading ? <Loading/> : applicationsList.length > 0 ? (
                <div className="QsmTableWrapper">
                    <table className="QsmTable colFixed">
                        <thead className="QsmTableHead">
                        <tr>
                            <th>№ п/п</th>
                            <th>Код заявки</th>
                            <th>Дата регистрации заявки</th>
                            <th>Наименование Организации</th>
                            <th>Контактные данные заявителя (адрес, телефон, e-mail)</th>
                            <th>Номер обращения заказчика</th>
                            <th>Дата обращения заказчика</th>
                            <th>Схема сертификации</th>
                            <th>Статус заявки</th>
                            <th className="colFixed">Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            applicationsList.map( ( appItem, index ) => (
                                <tr key={appItem._id}>
                                    <td>{index + 1}</td>
                                    <td>{appItem.app_number_pref}-{appItem.app_number}</td>
                                    <td>{moment( appItem.app_date ).format( 'DD.MM.YYYY' )}</td>
                                    <td>{appItem.client.name} {appItem.client.type}</td>
                                    <td>{appItem.client.address}, {appItem.client.phone}</td>
                                    <td>{appItem.request_number}</td>
                                    <td>{moment( appItem.request_date ).format( 'DD.MM.YYYY' )}</td>
                                    <td>{appItem.cert_schema.code}</td>
                                    <td>{appItem.status}</td>
                                    <td>
                                        <>

                                            <RouterLink to={`/applications/${appItem._id}`}>
                                                <Button type="primary" icon={<EditOutlined/>} size="small" onClick={() => dispatch(getApplicationById(appItem._id))}/>
                                            </RouterLink>
                                            {
                                                applicationsList && applicationsList.length >= 1 ? (
                                                    <Popconfirm
                                                        title="Вы уверены что хотите удалить запись ?"
                                                        onConfirm={() => dispatch( removeApplication( appItem._id ) )}>
                                                        <Button type="primary" danger
                                                                icon={<DeleteOutlined/>} size="small"/>
                                                    </Popconfirm>
                                                ) : null
                                            }
                                        </>
                                    </td>
                                </tr>
                            ) )
                        }
                        </tbody>
                    </table>
                </div>

            ) : ( <Empty/> )}

        </> )
};

export default JournalQSMDesignation;