import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import positions from './positions';
import roles from './roles';
import departments from './departments';
import qsmOne from './qsmOne';
import qsmDesignation from './qsmDesignation';
import qsmOneDocTypes from './qsmOneDocTypes';
import risks from './risks';
import riskTypes from './riskTypes';
import analysis from './analysis';
import influences from './influences';
import subdivisions from './subdivisions';
import nonconformity from './nonconformity';
import notifications from './notifications';
import standarts from './standarts';
import tnveds from './tnveds';
import certSchemas from './certSchemas';
import labs from './labs';
import clients from './clients';
import applications from './applications';
import cbsoa from './cbsoa';
import economicCode from "./economicCode";
import certificationCode from "./certificationCode";

export default combineReducers( {
    notifications,
    auth,
    users,
    positions,
    roles,
    departments,
    qsmOneDocTypes,
    qsmOne,
    qsmDesignation,
    risks,
    riskTypes,
    analysis,
    influences,
    subdivisions,
    nonconformity,
    standarts,
    tnveds,
    certSchemas,
    labs,
    clients,
    applications,
    cbsoa,
    economicCode,
    certificationCode
} );