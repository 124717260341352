import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Modal, Form, Input, Button, Popconfirm, DatePicker, Select } from 'antd';
import { removeRisk, updateRisk } from '../../actions/risks';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const initialState = {
    risk_date: moment(),
    risk_content: "",
    risk_name: "",
    potential_consequences: "",
    risk_reason: "",
    status: "",
    risk_eliminates: [],
};
const { TextArea } = Input;
const { Option } = Select;

const RiskAddModal = ( { visible, onCreate, onCancel } ) => {

    const users = useSelector( state => state.users.users );
    const risk = useSelector( state => state.risks.risk );
    const isLoading = useSelector( state => state.risks.isLoading );
    const dispatch = useDispatch();

    if ( risk ) {
        risk.risk_date = moment( risk.risk_date );
    }

    const [ riskData, setRiskData ] = useState( risk || initialState );

    useEffect( () => {
        setRiskData( risk !== null ? risk : initialState );
    }, [ risk ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newRiskData = {
                    risk_date: values.risk_date,
                    risk_content: values.risk_content,
                    risk_name: values.risk_name,
                    potential_consequences: values.potential_consequences,
                    risk_reason: values.risk_reason,
                    status: values.status,
                    risk_eliminates: values.risk_eliminates,
                };
                const newData = { ...riskData, ...newRiskData };
                setRiskData( newData );
                if ( risk !== null && risk._id ) {
                    dispatch( updateRisk( risk._id, newData ) );
                }
                form.submit();
                form.resetFields();
                setRiskData( initialState );
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={"Изменить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={700}
            footer={[
                risk !== null && risk._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeRisk( risk._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {"Обновить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="riskForm"
                size="middle"
                initValues={
                   {
                       ...riskData,
                       risk_eliminates: [ ...riskData.risk_eliminates.map( item => {
                           return {
                               impact_assessment: item.impact_assessment,
                               probability_score: item.probability_score,
                               risk_activity: item.risk_activity,
                               risk_activity_date: item.risk_activity_date,
                               risk_responsible: item.risk_responsible ? [...item.risk_responsible.map(resp=>resp._id)] : []
                           }
                       } ) ],
                   }
                }
            >
                <h4>Основная информация</h4>
                <FormField
                    name="risk_date" label="Дата обнаружения риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста укажите Дата обнаружения риска',
                    } ]}>
                    <DatePicker format='DD.MM.YYYY'/>
                </FormField>
                <FormField
                    name="risk_name"
                    label="Наименование риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Наименование риска!',
                        whitespace: true
                    } ]}
                >
                    <Input name="risk_name"/>
                </FormField>
                <FormField
                    name="risk_content"
                    label="Содержание обнаруженного риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Содержание обнаруженного риска!',
                        whitespace: true
                    } ]}
                >
                    <TextArea name="risk_content" rows={3}/>
                </FormField>

                <FormField
                    name="potential_consequences"
                    label="Возможные последствия риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Возможные последствия риска!',
                        whitespace: true
                    } ]}
                >
                    <TextArea name="potential_consequences" rows={3}/>
                </FormField>
                <FormField
                    name="risk_reason"
                    label="Причины риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Причины риска!',
                        whitespace: true
                    } ]}
                >
                    <TextArea name="risk_reason" rows={3}/>
                </FormField>
                <FormField
                    name="status" label="Статус"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста укажите Статус документа!',
                        whitespace: true
                    } ]}>
                    <Select
                        showSearch
                        placeholder="Статус риска (отметка о принятии риска)"
                        optionFilterProp="children"
                        name="status"
                        filterOption={( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        <Option value="Принят" selected>Принят</Option>
                        <Option value="Не принят">Не принят</Option>
                    </Select>
                </FormField>

                <h4>Оценка риска</h4>
                <Form.List name="risk_eliminates">
                    {( fields, { add, remove } ) => (
                        <>
                            <table>
                                <thead>
                                <tr>
                                    <th>Оценка влияния (I)</th>
                                    <th>Оценка вероятности (P)</th>
                                    <th>Мероприятия по устранению (минимизации) риска</th>
                                    <th>Сроки выполнения мероприятий</th>
                                    <th>Ответственный за выполнение</th>
                                    <th>-</th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map( ( { key, name, fieldKey, ...restField } ) => (
                                    <tr key={key}>
                                        <td>
                                            <Form.Item
                                                {...restField}
                                                wrapperCol={{
                                                    sm: { span: 24 },
                                                    xs: { span: 24 },
                                                }}
                                                name={[ name, 'impact_assessment' ]}
                                                fieldKey={[ fieldKey, 'impact_assessment' ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...restField}
                                                wrapperCol={{
                                                    sm: { span: 24 },
                                                    xs: { span: 24 },
                                                }}
                                                name={[ name, 'probability_score' ]}
                                                fieldKey={[ fieldKey, 'probability_score' ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...restField}
                                                wrapperCol={{
                                                    sm: { span: 24 },
                                                    xs: { span: 24 },
                                                }}
                                                name={[ name, 'risk_activity' ]}
                                                fieldKey={[ fieldKey, 'risk_activity' ]}
                                            >
                                                <TextArea name="risk_activity" rows={1}/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...restField}
                                                wrapperCol={{
                                                    sm: { span: 24 },
                                                    xs: { span: 24 },
                                                }}
                                                name={[ name, 'risk_activity_date' ]}
                                                fieldKey={[ fieldKey, 'risk_activity_date' ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item
                                                {...restField}
                                                wrapperCol={{
                                                    sm: { span: 24 },
                                                    xs: { span: 24 },
                                                }}
                                                name={[ name, 'risk_responsible' ]}
                                                fieldKey={[ fieldKey, 'risk_responsible' ]}
                                            >
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    allowClear
                                                    placeholder="Ответственный"
                                                    optionFilterProp="children"
                                                    filterOption={( input, option ) =>
                                                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                    }
                                                >
                                                    {users.map( item => <Option key={item._id} value={item._id}
                                                                                selected>{item.name}</Option> )}

                                                </Select>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <MinusCircleOutlined onClick={() => remove( name )}/>
                                        </td>
                                    </tr>
                                ) )}
                                </tbody>
                            </table>
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Добавить поля
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </AppForm>

        )}

        </Modal>
    );
};

export default RiskAddModal;