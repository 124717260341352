import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, DatePicker, Row, Col, Space, Typography } from 'antd';
import moment from 'moment';
import {
    updateApplication,
    addApplication,
} from '../../actions/applications';
import { getTnvedById } from "../../actions/tnveds";
import ClientsModal from "../Modals/ClientsModal";
import TnvedModal from "../Modals/TnvedModal";
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';

import '../../pages/Applications/style.css';
import { CLEAR_CLIENT, CLEAR_TNVED } from "../../utils/consts";


const { Option } = Select;
const { Title } = Typography;

const initialState = {
    app_number_pref: 'ОСП',
    app_number: 0,
    app_date: moment(),
    request_number: 0,
    request_date: moment(),
    cert_schema: '',
    contract_number: '',
    contract_date: moment(),
    contract_pay_date: moment(),
    executor: '',
    status: 'В процессе',
    app_objects: [],
};
const ApplicationMain = ( { data, certSchemasList, clients, users, tnved, standarts, max } ) => {

    const isLoading = useSelector( state => state.applications.isLoading );
    const dispatch = useDispatch();

    if ( data ) {
        data.app_date = moment( data.app_date );
        data.request_date = moment( data.request_date );
        data.contract_date = moment( data.contract_date );
        data.contract_pay_date = moment( data.contract_pay_date );
    }
    const [ visibleClient, setVisibleClient ] = useState( false );
    const [ visibleTnved, setVisibleTnved ] = useState( false );
    const [ appData, setAppData ] = useState( data || initialState );

    useEffect( () => {
        setAppData( data !== null ? data : initialState );
    }, [ data ] );

    const onCreateClient = () => {
        setVisibleClient( false );
    };
    const onCreateTnved = () => {
        setVisibleTnved( false );
    };


    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newAppData = {
                    app_number_pref: values.app_number_pref,
                    app_number: values.app_number,
                    app_date: values.app_date,
                    client: values.client,
                    request_number: values.request_number,
                    cert_schema: values.cert_schema,
                    contract_number: values.contract_number,
                    contract_date: values.contract_date,
                    contract_pay_date: values.contract_pay_date,
                    executor: values.executor,
                    status: values.status,
                    app_objects: values.app_objects,
                };
                const newData = { ...appData, ...newAppData };
                setAppData( newData );
                if ( appData !== null && appData._id ) {
                    dispatch( updateApplication( appData._id, newData ) );
                } else {
                    dispatch( addApplication( newData ) );
                }
                form.submit();
                form.resetFields();

            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const prefixSelector = (
        <Form.Item name="app_number_pref" noStyle>
            <Select
                style={{
                    width: 100,
                }}
            >
                <Option value="ОСП">ОСП</Option>
                <Option value="SI">SI</Option>
                <Option value="LI">LI</Option>
                <Option value="KI">KI</Option>
                <Option value="V">V</Option>
            </Select>
        </Form.Item>
    );

    const onFieldsChange = ( changedFields, allFields ) => {
        setAppData( { ...appData, ...allFields } );
    };

    return (
        <>
            {isLoading ? <Loading/> : (
                <>
                    <AppForm
                        formItemLayout={formItemLayout}
                        formInit={form}
                        name="appForm"
                        size="middle"
                        onValuesChange={onFieldsChange}
                        initValues={{
                            ...appData,
                            app_number: appData.app_number === 0 ? +max + 1 : appData.app_number,
                            cert_schema: appData.cert_schema._id
                        }}
                    >
                        <Row gutter={0}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="app_number" label="Код заявки"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пожалуйста введите код заявки!',
                                        },
                                    ]}
                                >
                                    <Input addonBefore={prefixSelector} style={{ width: '180px', }}/>
                                </Form.Item>
                                <Form.Item
                                    name="app_date" label="Дата регистрации заявки"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Дата регистрации заявки',
                                    } ]}>
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Row>
                                    <Col span={23}>
                                        <Form.Item
                                            labelCol={{ span: 10 }}
                                            name="client"
                                            label="Наименование Организации"
                                            rules={[ {
                                                required: true,
                                                message: 'Пожалуйста укажите Наименование Организации!',
                                            } ]}>
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="Наименование Организации"
                                                optionFilterProp="children"
                                                style={{ width: '100%' }}
                                                filterOption={( input, option ) =>
                                                    option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                                }
                                            >
                                                {clients.map( item => <Option key={item._id} value={item._id}
                                                                              selected>{item.name} {item.type}</Option> )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Button
                                            type="primary"
                                            icon={<PlusCircleOutlined/>}
                                            htmlType="button"
                                            onClick={() => {
                                                dispatch( { type: CLEAR_CLIENT } );
                                                setVisibleClient( true );
                                            }}/>
                                    </Col>
                                </Row>

                                <Form.Item name="request_number" label="№ обращения заказчика"
                                           rules={[ {
                                               required: true,
                                               message: 'Пожалуйста введите № обращения заказчика!',
                                           } ]}>
                                    <Input name="request_number"/>
                                </Form.Item>
                                <Form.Item
                                    name="request_date" label="Дата обращения заказчика"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Дата обращения заказчика',
                                    } ]}>
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="cert_schema"
                                    label="Схема сертификации"

                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Схема сертификации!',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Схема сертификации"
                                        optionFilterProp="children"
                                        name="cert_schema"
                                        style={{width: '150px'}}
                                        filterOption={( input, option ) =>
                                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                        }
                                    >
                                        {certSchemasList.map( item => <Option key={item._id} value={item._id}
                                                                              selected>{item.code}</Option> )}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item name="contract_number" label="№ договора с Заказчиком"
                                           rules={[ {
                                               whitespace: true
                                           } ]}>
                                    <Input name="contract_number"/>
                                </Form.Item>
                                <Form.Item
                                    name="contract_date" label="Дата договора с Заказчиком">
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="contract_pay_date" label="Дата оплаты по договору">
                                    <DatePicker format='DD.MM.YYYY'/>
                                </Form.Item>
                                <Form.Item
                                    name="executor"
                                    label="Ответственный исполнитель"
                                    rules={[ {
                                        required: true,
                                        message: 'Пожалуйста укажите Ответственный исполнитель!',
                                    } ]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Ответственный исполнитель"
                                        optionFilterProp="children"
                                        name="executor"
                                        filterOption={( input, option ) =>
                                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                        }
                                    >
                                        {users?.filter( item => item?.department?.name.includes('Орган по сертификации продукции') || item?.department?.name.includes('АУП')).map(item => <Option key={item._id} value={item._id} selected>{item.name}</Option>)}

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="Статус">
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Статус"
                                        optionFilterProp="children"
                                        name="status"
                                        filterOption={( input, option ) =>
                                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                        }
                                    >
                                        <Option key="1" value="В процессе" selected>В процессе</Option>
                                        <Option key="2" value="Выполнено">Выполнено</Option>
                                        <Option key="3" value="Отменено">Отменено</Option>

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24}>
                                <Form.List name="app_objects">
                                    {( fields, { add, remove } ) => (
                                        <>
                                            <Row gutter={10}>
                                                <Col>
                                                    <Title level={4}>Объекты</Title>
                                                </Col>
                                                <Col>
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()}
                                                                icon={<PlusOutlined/>}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {fields.map( ( { key, name, ...restField } ) => (
                                                <Row key={key} gutter={5}>
                                                    <Col span={1}>
                                                        {key + 1}
                                                    </Col>
                                                    <Col span={3}>
                                                        <Row gutter={0}>
                                                            <Col span={20}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'tnved' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите код ТН ВЭД',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        allowClear
                                                                        placeholder="Код ТН ВЭД"
                                                                        optionFilterProp="children"
                                                                        name="tnved"
                                                                        filterOption={( input, option ) =>
                                                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                                                        }
                                                                    >
                                                                        {tnved.map( item => <Option key={item._id}
                                                                                                    value={item._id}
                                                                                                    selected>{item.code}</Option> )}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Button
                                                                    type="primary"
                                                                    icon={<PlusCircleOutlined/>}
                                                                    htmlType="button"
                                                                    onClick={() => {
                                                                        dispatch( { type: CLEAR_TNVED } );
                                                                        setVisibleTnved( true );
                                                                    }}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            {...restField}
                                                            {...tailLayout}
                                                            name={[ name, 'object_name' ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Пожалуйста укажите Наименование продукции',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Наименование продукции"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1}>
                                                        <Form.Item
                                                            {...restField}
                                                            {...tailLayout}
                                                            name={[ name, 'quantity' ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Пожалуйста укажите кол-во',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="кол-во"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Form.Item
                                                            {...restField}
                                                            {...tailLayout}
                                                            name={[ name, 'unit' ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Пожалуйста укажите ед.изм',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                placeholder="ед.изм"
                                                                optionFilterProp="children"
                                                                name="unit"
                                                                filterOption={( input, option ) =>
                                                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                                                }
                                                            >
                                                                <Option value="шт." selected>шт.</Option>
                                                                <Option value="комп.">комп.</Option>
                                                                <Option value="серийное производство">серийное
                                                                    производство</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={9}>
                                                        <Row gutter={0}>
                                                            <Col span={22}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    {...tailLayout}
                                                                    name={[ name, 'standarts' ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Пожалуйста укажите НД',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        allowClear
                                                                        placeholder="НД"
                                                                        optionFilterProp="children"
                                                                        name="standarts"
                                                                        mode="multiple"
                                                                        filterOption={( input, option ) =>
                                                                            option.children.toString().toLowerCase().indexOf( input.toString().toLowerCase() ) >= 0
                                                                        }
                                                                    >
                                                                        {
                                                                            tnved.filter( item => item._id === appData.app_objects[ key ]?.tnved )[ 0 ]?.standarts.map( item =>
                                                                                <Option
                                                                                    key={item._id} value={item._id}
                                                                                    selected>{item.code}</Option> )
                                                                        }
                                                                    </Select>

                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}>
                                                                <Button type="primary" icon={<PlusCircleOutlined/>}
                                                                        onClick={() => {
                                                                            dispatch( getTnvedById( appData.app_objects[ key ]?.tnved ) );
                                                                            setVisibleTnved( true );
                                                                        }}/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <MinusCircleOutlined onClick={() => remove( name )}/>
                                                    </Col>
                                                </Row>
                                            ) )}
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={4} offset={10}>
                                <Space>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={isLoading}
                                        onClick={onFormSubmit}
                                    >
                                        {data !== null && data._id ? "Обновить" : "Добавить"}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </AppForm>
                    <ClientsModal
                        visible={visibleClient}
                        onCreate={onCreateClient}
                        onCancel={() => {
                            setVisibleClient( false );
                        }}
                    />
                    <TnvedModal
                        visible={visibleTnved}
                        onCreate={onCreateTnved}
                        onCancel={() => {
                            setVisibleTnved( false );
                        }}
                    />
                </>

            )}
        </>
    );
};

export default ApplicationMain;