import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Empty, Popconfirm, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllQsmOneDocType } from '../../actions/qsmOneDocTypes';
import { getAllQsmOne, getQsmOneById, removeQsmOne } from '../../actions/qsmOne';
import { getAllPositions } from "../../actions/positions";
import { getAllUsers } from "../../actions/users";
import Loading from "../../components/Loading/Loading";
import appNotification from "../../components/AppNotification/AppNotification";
import QsmOneModal from "../../components/Modals/QsmOneModal";
import { CLEAR_QSMONE } from "../../utils/consts";

import '../Qsm.css';

const { Title } = Typography;

const JournalQSMOne = () => {
    const [ visible, setVisible ] = useState( false );


    const dispatch = useDispatch();
    const qmsDocTypes = useSelector( state => state.qsmOneDocTypes.qsmOneDocTypes );
    const qmsOneDataList = useSelector( state => state.qsmOne.qsmOneDataList );
    const isLoading = useSelector( state => state.qsmOne.isLoading );
    const notify = useSelector( state => state.notifications.notify );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllQsmOneDocType() );
        dispatch( getAllQsmOne() );
        dispatch( getAllPositions() );
        dispatch( getAllUsers() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    return (
        <>
            <Title level={3}>Журнал управления внутренними документами ПСМ-LET-01-B</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_QSMONE } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            {isLoading ?
                <Loading/> : qmsOneDataList && qmsOneDataList.length > 0 ? (
                    <div className="QsmTableWrapper">
                        <table className="QsmTable colFixed">
                            <thead className="QsmTableHead">
                            <tr>
                                <th rowSpan={2}>№ п/п</th>
                                <th rowSpan={2}>Код<br/> документа</th>
                                <th rowSpan={2}>Наименование<br/> документа</th>
                                <th rowSpan={2}>Пункт стандарта<br/>
                                    O‛z DSt ISO 9001:2015/<br/>
                                    O‛z DSt ISO/IEC 17025:2019/<br/>
                                    O‛z DSt ISO/IEC 17065:2015
                                </th>
                                <th rowSpan={2}>Дата<br/> утверждения</th>
                                <th rowSpan={2}>Дата <br/>введения</th>
                                <th colSpan={2}>Актуализация</th>
                                <th colSpan={5}>Ответственность за документ</th>
                                <th colSpan={3}>Рассылка</th>
                                <th rowSpan={2}>Статус</th>
                                <th rowSpan={2}>Ссылка<br/> на документ</th>
                                <th rowSpan={2} className="colFixed">Действия</th>
                            </tr>
                            <tr>
                                <th>Последняя</th>
                                <th>Следующая</th>
                                <th>Разработка</th>
                                <th>Утверждение</th>
                                <th>Согласование</th>
                                <th>Ознакомление</th>
                                <th>Хранение<br/> контрольного<br/> экземпляра</th>
                                <th>Дата</th>
                                <th>Копия №</th>
                                <th>Ф. И. О.<br/> получателя</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                                <th>10</th>
                                <th>11</th>
                                <th>12</th>
                                <th>13</th>
                                <th>14</th>
                                <th>15</th>
                                <th>16</th>
                                <th>17</th>
                                <th>18</th>
                                <th>19</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                qmsDocTypes.map( item => {
                                    return (
                                        <React.Fragment key={`parent${item._id}`}>
                                            <tr className="QsmTableParent">
                                                <td colSpan={18}>{item.name}</td>
                                                <td></td>
                                            </tr>
                                            {
                                                qmsOneDataList.filter( ( data ) => data.qsmdoctype._id === item._id ).map( ( child, index ) => (
                                                    <tr key={`child-${child._id + index}`}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{child.document_code}</td>
                                                        <td>{child.document_name}</td>
                                                        <td>{child.standart_clause}</td>
                                                        <td>{moment( child.approval_date ).format( 'DD.MM.YYYY' )}</td>
                                                        <td>{moment( child.introduction_date ).format( 'DD.MM.YYYY' )}</td>
                                                        <td>{moment( child.actual_last ).format( 'DD.MM.YYYY' )}</td>
                                                        <td>{moment( child.actual_next ).format( 'DD.MM.YYYY' )}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                            {
                                                                child?.responsibility_dev?.map( ( respDev ) => (
                                                                    <p key={respDev._id}>{respDev.name}</p>
                                                                ) )
                                                            }
                                                        </td>
                                                        <td>{child?.responsibility_approval?.name}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                            {
                                                                child?.responsibility_agreement?.map( ( respAgr ) => (
                                                                    <p key={respAgr._id}>{respAgr.name}</p>
                                                                ) )
                                                            }
                                                        </td>
                                                        <td>{child?.responsibility_acquaintance?.name}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{child?.responsibility_keeping?.name}</td>
                                                        <td>{child?.journalqsmone_mailing?.map( mailing =>
                                                            <p key={`mdate${mailing._id}`}>{moment( mailing.mailing_date ).format( 'DD.MM.YYYY' )}</p> )}</td>
                                                        <td>{child?.journalqsmone_mailing?.map( mailing =>
                                                            <p key={`mcopy${mailing._id}`}>{mailing.mailing_copy}</p> )}</td>
                                                        <td>{child?.journalqsmone_mailing?.map( mailing =>
                                                            <p key={`muser${mailing._id}`}>{mailing.user.name}</p> )}</td>
                                                        <td>{child.status}</td>
                                                        <td><a rel="noreferrer" target="_blank"
                                                               href={child.document_link}>{child.document_code}</a>
                                                        </td>
                                                        <td>
                                                            <>
                                                                <Button type="primary" icon={<EditOutlined/>}
                                                                        size="small"
                                                                        onClick={() => {
                                                                            dispatch( getQsmOneById( child._id ) );
                                                                            setVisible( true );
                                                                        }}/>
                                                                {
                                                                    qmsOneDataList.length >= 1 ? (
                                                                        <Popconfirm
                                                                            title="Вы уверены что хотите удалить запись ?"
                                                                            onConfirm={() => dispatch( removeQsmOne( child._id ) )}>
                                                                            <Button type="primary" danger
                                                                                    icon={<DeleteOutlined/>}
                                                                                    size="small"/>
                                                                        </Popconfirm>
                                                                    ) : null
                                                                }
                                                            </>
                                                        </td>
                                                    </tr>
                                                ) )
                                            }
                                        </React.Fragment>
                                    )

                                } )
                            }
                            </tbody>
                        </table>
                    </div>
                ) : ( <Empty/> )}
            <QsmOneModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </> )

};

export default JournalQSMOne;