import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { addCertSchema, removeCertSchema, updateCertSchema } from '../../actions/certSchemas';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    code: '',
    test_type: '',
    survey: '',
    inspection: ''
};

const CertSchemaModal = ( { visible, onCreate, onCancel, history } ) => {

    const certSchema = useSelector( state => state.certSchemas.certSchema );
    const isLoading = useSelector( state => state.certSchemas.isLoading );
    const dispatch = useDispatch();

    const [ certSchemaData, setCertSchemaData ] = useState( certSchema || initialState );

    useEffect( () => {
        setCertSchemaData( certSchema !== null ? certSchema : initialState );
    }, [ certSchema ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newCertSchemaData = {
                    code: values.code,
                    test_type: values.test_type,
                    survey: values.survey,
                    inspection: values.inspection,
                };
                const newData = { ...certSchemaData, ...newCertSchemaData };
                setCertSchemaData( newData );
                if ( certSchema !== null && certSchema._id ) {
                    dispatch( updateCertSchema( certSchema._id, newData ) );
                } else {
                    dispatch( addCertSchema( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={certSchema !== null && certSchema._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={700}
            footer={[
                certSchema !== null && certSchema._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeCertSchema( certSchema._id ));
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {certSchema !== null && certSchema._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="certSchemaForm"
                size="middle"
                initValues={certSchemaData}
            >
                <FormField name="code" label="№ схемы"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите код ТН ВЭД!',
                               whitespace: true
                           } ]}>
                    <Input name="code"/>
                </FormField>
                <FormField name="test_type" label="Вид испытания"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите Вид испытания!',
                               whitespace: true
                           } ]}>
                    <Input name="test_type"/>
                </FormField>
                <FormField name="survey" label="Обследования производства"
                           rules={[ {
                               whitespace: true
                           } ]}>
                    <Input name="survey"/>
                </FormField>
                <FormField name="inspection" label="Инспекционный контроль"
                           rules={[ {
                               whitespace: true
                           } ]}>
                    <Input name="inspection"/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default CertSchemaModal;