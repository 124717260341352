import {
    ADD_LAB,
    GET_LAB,
    UPDATE_LAB,
    DELETE_LAB,
    LAB_ERROR,
    GET_LABS,
    CLEAR_LAB,
    SET_LAB_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import LabService from "../services/LabService";

// Get All Lab
export const getAllLab = () => async dispatch => {
    dispatch( { type: SET_LAB_LOADING } );
    dispatch( { type: CLEAR_LAB } );
    try {
        const response = await LabService.getAllLab();
        dispatch( {
            type: GET_LABS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: LAB_ERROR
        } );
    }
};

// Get Lab by ID
export const getLabById = ( labId ) => async dispatch => {
    dispatch( { type: SET_LAB_LOADING } );
    dispatch( { type: CLEAR_LAB } );
    try {
        const response = await LabService.getLabById( labId );
        dispatch( {
            type: GET_LAB,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: LAB_ERROR
        } );
    }
};


// Add Lab
export const addLab = ( { registry_number, name } ) => async dispatch => {
    dispatch( { type: SET_LAB_LOADING } );
    dispatch( { type: CLEAR_LAB } );
    try {
        const response = await LabService.addLab( registry_number, name );
        dispatch( {
            type: ADD_LAB,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllLab() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: LAB_ERROR
        } );
    }
};
// Update Lab
export const updateLab = ( labId, { registry_number, name } ) => async dispatch => {
    dispatch( { type: SET_LAB_LOADING } );
    dispatch( { type: CLEAR_LAB } );
    try {
        const response = await LabService.updateLab( labId, registry_number, name );
        dispatch( {
            type: UPDATE_LAB,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllLab() );
        dispatch( getLabById( labId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: LAB_ERROR
        } );
    }
};

// Delete Lab
export const removeLab = ( labId ) => async dispatch => {
    dispatch( { type: SET_LAB_LOADING } );
    dispatch( { type: CLEAR_LAB } );
    try {
        await LabService.removeLab( labId );
        dispatch( {
            type: DELETE_LAB,
            payload: labId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllLab() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: LAB_ERROR
        } );
    }
};




