import { $api, PDF_URL } from "../http";

class ApplicationService {

    async addApplication(
        app_number_pref,
        app_number,
        app_date,
        client,
        request_number,
        request_date,
        cert_schema,
        contract_number,
        contract_date,
        contract_pay_date,
        executor,
        status,
        app_objects,
        decision,
        sampling,
        extradition
    ) {
        return $api.post( '/applications', {
            app_number_pref,
            app_number,
            app_date,
            client,
            request_number,
            request_date,
            cert_schema,
            contract_number,
            contract_date,
            contract_pay_date,
            executor,
            status,
            app_objects,
            decision,
            sampling,
            extradition
        } );
    }

    async getAllApplication() {
        return $api.get( '/applications' );
    }

    async updateApplication(
        applicationsId,
        app_number_pref,
        app_number,
        app_date,
        client,
        request_number,
        request_date,
        cert_schema,
        contract_number,
        contract_date,
        contract_pay_date,
        executor,
        status,
        app_objects,
        decision,
        sampling,
        extradition
    ) {
        return $api.put( `/applications/${applicationsId}`, {
            app_number_pref,
            app_number,
            app_date,
            client,
            request_number,
            request_date,
            cert_schema,
            contract_number,
            contract_date,
            contract_pay_date,
            executor,
            status,
            app_objects,
            decision,
            sampling,
            extradition
        } );
    }

    async removeApplication( applicationsId ) {
        return $api.delete( `/applications/${applicationsId}` );
    }

    async getApplicationById( applicationsId ) {
        return $api.get( `/applications/${applicationsId}` );
    }

    async generateDecisionPdf( applicationsId ) {
        console.log( applicationsId );
        window.open( `${PDF_URL}/decision/${applicationsId}` ); //does the download
    }

    async generateSamplingPdf( applicationsId ) {
        console.log( applicationsId );
        window.open( `${PDF_URL}/sampling/${applicationsId}` ); //does the download
    }

    async generateExtraditionPdf( applicationsId ) {
        console.log( applicationsId );
        window.open( `${PDF_URL}/extradition/${applicationsId}` ); //does the download
    }
}

export default new ApplicationService();

