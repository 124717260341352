import {
    GET_ALLQSMDESIGNATION,
    UPDATE_QSMDESIGNATION,
    GET_QSMDESIGNATION,
    DELETE_QSMDESIGNATION,
    SET_QSMDESIGNATION_LOADING,
    CLEAR_QSMDESIGNATION,
    QSMDESIGNATION_ERROR,
    ADD_QSMDESIGNATION,
} from '../utils/consts';

const initialState = {
    qsmDesignationData: null,
    qsmDesignationDataList: [],
    isLoading: false
};

const qsmDesignation = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {

        case ADD_QSMDESIGNATION:
            return {
                ...state,
                qsmFourDataList: [ payload, ...state.qsmDesignationDataList ],
            };
        case GET_ALLQSMDESIGNATION:
            return {
                ...state,
                qsmDesignationDataList: payload,
                isLoading: false,
            };
        case GET_QSMDESIGNATION:
        case UPDATE_QSMDESIGNATION:
            return {
                ...state,
                qsmDesignationData: payload,
                isLoading: false
            };
        case DELETE_QSMDESIGNATION:
            return {
                ...state,
                qsmDesignationData: null,
                isLoading: false
            };
        case SET_QSMDESIGNATION_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_QSMDESIGNATION:
            return {
                ...state,
                qsmDesignationData: null,
            };
        case QSMDESIGNATION_ERROR:
            return {
                ...state,
                isLoading: false,
                qsmDesignationData: null
            };
        default:
            return state;
    }
};

export default qsmDesignation;