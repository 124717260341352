import {$api} from "../http";

class QsmDesignationsService {

    async addQsmDesignation(
        document_code,
        document_name_ru,
        document_name_uz,
        document_name_en,
        description
    ) {
        return $api.post( '/qsmdesignation', {
            document_code,
            document_name_ru,
            document_name_uz,
            document_name_en,
            description
        } );
    }

    async getAllQsmDesignation() {
        return $api.get( '/qsmdesignation' );
    }

    async updateQsmDesignation(
        qsmId,
        document_code,
        document_name_ru,
        document_name_uz,
        document_name_en,
        description
    ) {
        return $api.put( `/qsmdesignation/${qsmId}`, {
            document_code,
            document_name_ru,
            document_name_uz,
            document_name_en,
            description
        } );
    }

    async removeQsmDesignation( qsmId ) {
        return $api.delete( `/qsmdesignation/${qsmId}` );
    }

    async getQsmDesignationById( qsmId ) {
        return $api.get( `/qsmdesignation/${qsmId}` );
    }
}

export default new QsmDesignationsService();

