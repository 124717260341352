import {
    ADD_APP,
    GET_APP,
    UPDATE_APP,
    DELETE_APP,
    APP_ERROR,
    GET_APPS,
    CLEAR_APP,
    SET_APP_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import ApplicationService from "../services/ApplicationService";

// Get All Application
export const getAllApplication = () => async dispatch => {
    dispatch( { type: SET_APP_LOADING } );
    dispatch( { type: CLEAR_APP } );
    try {
        const response = await ApplicationService.getAllApplication();
        dispatch( {
            type: GET_APPS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};

// Get Application by ID
export const getApplicationById = ( applicationId ) => async dispatch => {
    dispatch( { type: SET_APP_LOADING } );
    dispatch( { type: CLEAR_APP } );
    try {
        const response = await ApplicationService.getApplicationById( applicationId );
        dispatch( {
            type: GET_APP,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};


// Add Application
export const addApplication = ( { app_number_pref, app_number, app_date, client, request_number, request_date, cert_schema, contract_number, contract_date, contract_pay_date, executor, status, app_objects, decision, sampling, extradition } ) => async dispatch => {
    dispatch( { type: SET_APP_LOADING } );
    dispatch( { type: CLEAR_APP } );
    try {
        const response = await ApplicationService.addApplication( app_number_pref, app_number, app_date, client, request_number, request_date, cert_schema, contract_number, contract_date, contract_pay_date, executor, status, app_objects, decision, sampling, extradition );
        dispatch( {
            type: ADD_APP,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllApplication() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};
// Update Application
export const updateApplication = ( applicationId, { app_number_pref, app_number, app_date, client, request_number, request_date, cert_schema, contract_number, contract_date, contract_pay_date, executor, status, app_objects, decision, sampling, extradition } ) => async dispatch => {
    dispatch( { type: SET_APP_LOADING } );
    dispatch( { type: CLEAR_APP } );
    try {
        const response = await ApplicationService.updateApplication( applicationId, app_number_pref, app_number, app_date, client, request_number, request_date, cert_schema, contract_number, contract_date, contract_pay_date, executor, status, app_objects, decision, sampling, extradition );
        dispatch( {
            type: UPDATE_APP,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getApplicationById( applicationId ) );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};

// Delete Application
export const removeApplication = ( applicationId ) => async dispatch => {
    dispatch( { type: SET_APP_LOADING } );
    dispatch( { type: CLEAR_APP } );
    try {
        await ApplicationService.removeApplication( applicationId );
        dispatch( {
            type: DELETE_APP,
            payload: applicationId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllApplication() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};

export const generateDecisionPdf = ( applicationId ) => async dispatch => {
    try {
        await ApplicationService.generateDecisionPdf( applicationId );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};

export const generateSamplingPdf = ( applicationId ) => async dispatch => {
    try {
        await ApplicationService.generateSamplingPdf( applicationId );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};

export const generateExtraditionPdf = ( applicationId ) => async dispatch => {
    try {
        await ApplicationService.generateExtraditionPdf( applicationId );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: APP_ERROR
        } );
    }
};



