import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Space, Button, Popconfirm, Empty } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAllUsers, getUserById, removeUser } from '../../actions/users';
import Loading from "../../components/Loading/Loading";
import appNotification from "../../components/AppNotification/AppNotification";
import { CLEAR_USER } from "../../utils/consts";
import UserModal from "../../components/Modals/UserModal";
import { getAllPositions } from "../../actions/positions";
import { getAllRoles } from "../../actions/roles";
import { getAllDepartments } from "../../actions/departments";

const { Title } = Typography;

const Users = () => {

    const [ filteredInfo, setFilteredInfo ] = useState( null );
    const [ sortedInfo, setSortedInfo ] = useState( null );
    const [ visible, setVisible ] = useState( false );

    const users = useSelector( state => state.users.users );
    const isLoading = useSelector( state => state.users.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const dispatch = useDispatch();

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllUsers() );
        dispatch( getAllRoles() );
        dispatch( getAllPositions() );
        dispatch( getAllDepartments() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    let sorted = sortedInfo;
    let filtered = filteredInfo;
    sorted = sorted || {};
    filtered = filtered || {};

    // change filters and sorts
    const handleChange = ( pagination, filters, sorter ) => {
        setFilteredInfo( filters );
        setSortedInfo( sorter );
    };
    // clear filter and sorts
    const clearAll = () => {
        setFilteredInfo( null );
        setSortedInfo( null );
    };

    let usersName = [],
        usersEmail = [],
        usersRules = [],
        usersPositions = [],
        usersDepartments = [];

    new Set( users.map( ( user ) => user.name ) ).forEach( val => usersName.push( val ) );
    new Set( users.map( ( user ) => user.email ) ).forEach( val => usersEmail.push( val ) );
    new Set( users.map( ( user ) => user.role ) ).forEach( val => usersRules.push( val ) );
    new Set( users.map( ( user ) => user.position ) ).forEach( val => usersPositions.push( val ) );
    new Set( users.map( ( user ) => user.department ) ).forEach( val => usersDepartments.push( val ) );

    const columns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 60,
            render: ( text, record, index ) => index + 1,
        },
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
            render: ( text, record ) => ( <Button type="link" size="small" onClick={() => {
                dispatch( getUserById( record._id ) );
                setVisible( true );
            }}>{text}</Button> ),
            filters: ( usersName.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.name || null,
            onFilter: ( value, record ) => record.name.includes( value ),
            sorter: ( a, b ) => a.name.length - b.name.length,
            sortOrder: sorted.columnKey === 'name' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            filters: ( usersEmail.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),

            filteredValue: filtered.email || null,
            onFilter: ( value, record ) => record.email.includes( value ),
            sorter: ( a, b ) => a.email.length - b.email.length,
            sortOrder: sorted.columnKey === 'email' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Должность',
            dataIndex: 'position',
            key: 'position',
            render: position => position ? position.name : '',
            filters: ( usersPositions.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.position || null,
            onFilter: ( value, record ) => record.position.name.includes( value ),
            sorter: ( a, b ) => a.position.name.length - b.position.name.length,
            sortOrder: sorted.columnKey === 'position' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
            render: role => role ? role.name : '',
            filters: ( usersRules.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.role || null,
            onFilter: ( value, record ) => record.role.name.includes( value ),
            sorter: ( a, b ) => a.role.name.length - b.role.name.length,
            sortOrder: sorted.columnKey === 'role' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Отдел',
            dataIndex: 'department',
            key: 'department',
            render: department => department ? department.name : '',
            filters: ( usersDepartments.map( item => {
                return {
                    text: item,
                    value: item
                }
            } ) ),
            filteredValue: filtered.department || null,
            onFilter: ( value, record ) => record.department?.name.includes( value ),
            sorter: ( a, b ) => a.department?.name.length - b.department?.name.length,
            sortOrder: sorted.columnKey === 'department' && sorted.order,
            ellipsis: true,
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            render: ( text, record ) => (
                <>
                    <Button type="primary" icon={<EditOutlined/>} size="small" onClick={() => {
                        dispatch( getUserById( record._id ) );
                        setVisible( true );
                    }}/>
                    {
                        users.length >= 1 ? (
                            <Popconfirm title="Вы уверены что хотите удалить запись ?"
                                        onConfirm={() => dispatch( removeUser( record._id ) )}>
                                <Button type="primary" danger icon={<DeleteOutlined/>} size="small"/>
                            </Popconfirm>
                        ) : null
                    }
                </>
            ),
            width: 150,
            fixed: 'right'
        },
    ];

    return isLoading ? <Loading/> : users ? (
            <>
                <Title level={3}>Пользователи</Title>
                <Space style={{ marginBottom: 16 }}>
                    <Button onClick={clearAll}>Очистить фильтры</Button>
                    <Button type="primary" htmlType="button" onClick={() => {
                        dispatch( { type: CLEAR_USER } );
                        setVisible( true );
                    }}>Добавить должность</Button>
                </Space>
                <Table
                    bordered
                    rowKey={( record ) => record._id}
                    columns={columns}
                    dataSource={users}
                    onChange={handleChange}
                    pagination={{ pageSize: 20 }}
                    scroll={{ x: 1200, y: 500 }}
                />
                <UserModal
                    visible={visible}
                    onCreate={onCreate}
                    onCancel={() => {
                        setVisible( false );
                    }}
                />
            </>
        ) :
        ( <Empty/> );
};

export default Users;