import React, { useEffect, useState } from 'react';
import { Button, Empty, Popconfirm, Space, Typography } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import appNotification from "../../components/AppNotification/AppNotification";
import { getAllQsmDesignation, getQsmDesignationById, removeQsmDesignation } from "../../actions/qsmDesignation";
import QsmDesignationModal from "../../components/Modals/QsmDesignationModal";
import { CLEAR_QSMDESIGNATION } from "../../utils/consts";
import '../Qsm.css';
import Loading from "../../components/Loading/Loading";

const { Title } = Typography;
const JournalQSMDesignation = () => {
    const dispatch = useDispatch();
    const qsmFourDataList = useSelector( state => state.qsmDesignation.qsmDesignationDataList );
    const isLoading = useSelector( state => state.qsmDesignation.isLoading );
    const notify = useSelector( state => state.notifications.notify );
    const [ visible, setVisible ] = useState( false );

    useEffect( () => {
        if ( notify !== null ) {
            appNotification( notify.type, notify.message, notify.description );
        }
    }, [ notify ] );

    useEffect( () => {
        dispatch( getAllQsmDesignation() );
    }, [ dispatch ] );

    const onCreate = () => {
        setVisible( false );
    };

    return (
        <>
            <Title level={3}>Обозначения, принятые для документов системы менеджмента</Title>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" htmlType="button" onClick={() => {
                    dispatch( { type: CLEAR_QSMDESIGNATION } );
                    setVisible( true );
                }}>Добавить запись</Button>
            </Space>
            {isLoading ? <Loading/> : qsmFourDataList.length > 0 ? (
                <div className="QsmTableWrapper">
                    <table className="QsmTable colFixed">
                        <thead className="QsmTableHead">
                        <tr>
                            <th rowSpan={2}>№ п/п</th>
                            <th rowSpan={2}>Код<br/> обозначения</th>
                            <th colSpan={3}>Расшифровка</th>
                            <th rowSpan={2}>Примечание</th>
                            <th rowSpan={2} className="colFixed">Действия</th>
                        </tr>
                        <tr>
                            <th>Русский</th>
                            <th>Ўзбекча</th>
                            <th>English</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            qsmFourDataList.map( ( qsm, index ) => (
                                <tr key={qsm._id}>
                                    <td>{index + 1}</td>
                                    <td>{qsm.document_code}</td>
                                    <td>{qsm.document_name_ru}</td>
                                    <td>{qsm.document_name_uz}</td>
                                    <td>{qsm.document_name_en}</td>
                                    <td>{qsm.description}</td>
                                    <td>
                                        <>
                                            <Button type="primary" icon={<EditOutlined/>} size="small"
                                                    onClick={() => {
                                                        dispatch( getQsmDesignationById( qsm._id ) );
                                                        setVisible( true );
                                                    }}/>
                                            {
                                                qsmFourDataList && qsmFourDataList.length >= 1 ? (
                                                    <Popconfirm
                                                        title="Вы уверены что хотите удалить запись ?"
                                                        onConfirm={() => dispatch( removeQsmDesignation( qsm._id ) )}>
                                                        <Button type="primary" danger
                                                                icon={<DeleteOutlined/>} size="small"/>
                                                    </Popconfirm>
                                                ) : null
                                            }
                                        </>
                                    </td>
                                </tr>
                            ) )
                        }
                        </tbody>
                    </table>
                </div>

            ) : ( <Empty/> )}
            <QsmDesignationModal
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible( false );
                }}
            />
        </> )
};

export default JournalQSMDesignation;