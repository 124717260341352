import {
    ADD_INFLUENCE,
    GET_INFLUENCE,
    UPDATE_INFLUENCE,
    DELETE_INFLUENCE,
    INFLUENCE_ERROR,
    GET_INFLUENCES,
    CLEAR_INFLUENCE,
    SET_INFLUENCE_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import InfluenceService from "../services/InfluenceService";

// Get All Influence
export const getAllInfluence = () => async dispatch => {
    dispatch( { type: SET_INFLUENCE_LOADING } );
    dispatch( { type: CLEAR_INFLUENCE } );
    try {
        const response = await InfluenceService.getAllInfluence();
        dispatch( {
            type: GET_INFLUENCES,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: INFLUENCE_ERROR
        } );
    }
};

// Get Influence by ID
export const getInfluenceById = ( influenceId ) => async dispatch => {
    dispatch( { type: SET_INFLUENCE_LOADING } );
    dispatch( { type: CLEAR_INFLUENCE } );
    try {
        const response = await InfluenceService.getInfluenceById( influenceId );
        dispatch( {
            type: GET_INFLUENCE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: INFLUENCE_ERROR
        } );
    }
};


// Add Influence
export const addInfluence = ( { name } ) => async dispatch => {
    dispatch( { type: SET_INFLUENCE_LOADING } );
    dispatch( { type: CLEAR_INFLUENCE } );
    try {
        const response = await InfluenceService.addInfluence( name );
        dispatch( {
            type: ADD_INFLUENCE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllInfluence() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: INFLUENCE_ERROR
        } );
    }
};
// Update Influence
export const updateInfluence = ( influenceId, { name } ) => async dispatch => {
    dispatch( { type: SET_INFLUENCE_LOADING } );
    dispatch( { type: CLEAR_INFLUENCE } );
    try {
        const response = await InfluenceService.updateInfluence( influenceId, name );
        dispatch( {
            type: UPDATE_INFLUENCE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllInfluence() );
        dispatch( getInfluenceById( influenceId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: INFLUENCE_ERROR
        } );
    }
};

// Delete Influence
export const removeInfluence = ( influenceId ) => async dispatch => {
    dispatch( { type: SET_INFLUENCE_LOADING } );
    dispatch( { type: CLEAR_INFLUENCE } );
    try {
        await InfluenceService.removeInfluence( influenceId );
        dispatch( {
            type: DELETE_INFLUENCE,
            payload: influenceId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllInfluence() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: INFLUENCE_ERROR
        } );
    }
};




