import {
    ADD_QSMONEDOCTYPE,
    GET_QSMONEDOCTYPE,
    UPDATE_QSMONEDOCTYPE,
    DELETE_QSMONEDOCTYPE,
    QSMONEDOCTYPE_ERROR,
    GET_ALLQSMONEDOCTYPE,
    CLEAR_QSMONEDOCTYPE,
    SET_QSMONEDOCTYPE_LOADING,
} from '../utils/consts';

import { setNotify } from './notications';

import QsmService from "../services/QsmDocTypeService";

// Get All Qsm Doc Types
export const getAllQsmOneDocType = () => async dispatch => {
    dispatch( { type: SET_QSMONEDOCTYPE_LOADING } );
    dispatch( { type: CLEAR_QSMONEDOCTYPE } );

    try {
        const response = await QsmService.getAllQsmOneDocType();
        dispatch( {
            type: GET_ALLQSMONEDOCTYPE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONEDOCTYPE_ERROR
        } );
    }
};

// Get QSM Doc Type by Id
export const getQsmOneDocTypeById = ( qsmId ) => async dispatch => {
    dispatch( { type: SET_QSMONEDOCTYPE_LOADING } );
    dispatch( { type: CLEAR_QSMONEDOCTYPE } );
    try {
        const response = await QsmService.getQsmOneDocTypeById( qsmId );
        dispatch( {
            type: GET_QSMONEDOCTYPE,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONEDOCTYPE_ERROR
        } );
    }
};

// Add QSM doc type
export const addQsmOneDocType = ( { name } ) => async dispatch => {
    dispatch( { type: SET_QSMONEDOCTYPE_LOADING } );
    dispatch( { type: CLEAR_QSMONEDOCTYPE } );
    try {
        const response = await QsmService.addQsmOneDocType( name );
        dispatch( {
            type: ADD_QSMONEDOCTYPE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Тип документа успешно добавлен!' ) );
        dispatch( getAllQsmOneDocType() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONEDOCTYPE_ERROR
        } );
    }
};
// Update QSM Doc Type
export const updateQsmOneDocType = ( qsmId, { name } ) => async dispatch => {
    dispatch( { type: SET_QSMONEDOCTYPE_LOADING } );
    dispatch( { type: CLEAR_QSMONEDOCTYPE } );
    try {
        const response = await QsmService.updateQsmOneDocType( qsmId, name );
        dispatch( {
            type: UPDATE_QSMONEDOCTYPE,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllQsmOneDocType() );
        dispatch( getQsmOneDocTypeById( qsmId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONEDOCTYPE_ERROR
        } );
    }
};

// DELETE QSM Doc Type
export const removeQsmOneDocType = ( qsmId ) => async dispatch => {
    dispatch( { type: SET_QSMONEDOCTYPE_LOADING } );
    dispatch( { type: CLEAR_QSMONEDOCTYPE } );
    try {
        await QsmService.removeQsmOneDocType( qsmId );
        dispatch( {
            type: DELETE_QSMONEDOCTYPE,
            payload: qsmId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllQsmOneDocType() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: QSMONEDOCTYPE_ERROR
        } );
    }
};