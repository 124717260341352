import {
    ADD_RISK,
    GET_RISK,
    GET_RISK_USER,
    UPDATE_RISK,
    DELETE_RISK,
    RISK_ERROR,
    GET_RISKS,
    CLEAR_RISK,
    SET_RISK_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import RiskService from "../services/RiskService";

// Get All Risks
export const getAllRisks = ( ) => async dispatch => {
    dispatch( { type: SET_RISK_LOADING } );
    dispatch( { type: CLEAR_RISK } );
    try {
        const response = await RiskService.getAllRisks( );
        dispatch( {
            type: GET_RISKS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISK_ERROR
        } );
    }
};

// Get Risk by ID
export const getRiskById = ( riskId ) => async dispatch => {
    dispatch( { type: SET_RISK_LOADING } );
    dispatch( { type: CLEAR_RISK } );
    try {
        const response = await RiskService.getRiskById( riskId );
        dispatch( {
            type: GET_RISK,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISK_ERROR
        } );
    }
};

// Get Risk by User
export const getRiskByUser = ( employeeId ) => async dispatch => {
    dispatch( { type: SET_RISK_LOADING } );
    dispatch( { type: CLEAR_RISK } );
    try {
        const response = await RiskService.getRiskByUser( employeeId );
        dispatch( {
            type: GET_RISK_USER,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISK_ERROR
        } );
    }
};

// Add Risk
export const addRisk = (
    {
        risk_date,
        risk_content,
        employee
    }
) => async dispatch => {
    dispatch( { type: SET_RISK_LOADING } );
    dispatch( { type: CLEAR_RISK } );
    try {
        const response = await RiskService.addRisk(
            risk_date,
            risk_content,
            employee
        );
        dispatch( {
            type: ADD_RISK,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getRiskByUser(employee) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISK_ERROR
        } );
    }
};

// Update Risk
export const updateRisk = (
    riskId,
    {
        risk_date,
        risk_content,
        risk_name,
        potential_consequences,
        risk_reason,
        status,
        risk_eliminates
    }
) => async dispatch => {
    dispatch( { type: SET_RISK_LOADING } );
    dispatch( { type: CLEAR_RISK } );
    try {
        const response = await RiskService.updateRisk(
            riskId,
            risk_date,
            risk_content,
            risk_name,
            potential_consequences,
            risk_reason,
            status,
            risk_eliminates
        );
        dispatch( {
            type: UPDATE_RISK,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllRisks() );
        dispatch( getRiskById( riskId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISK_ERROR
        } );
    }
};

// Delete Risk
export const removeRisk = ( riskId ) => async dispatch => {
    dispatch( { type: SET_RISK_LOADING } );
    dispatch( { type: CLEAR_RISK } );
    try {
        await RiskService.removeRisk( riskId );
        dispatch( {
            type: DELETE_RISK,
            payload: riskId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllRisks() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: RISK_ERROR
        } );
    }
};




