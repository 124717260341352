import {
    ADD_TNVED,
    GET_TNVED,
    UPDATE_TNVED,
    DELETE_TNVED,
    TNVED_ERROR,
    GET_TNVEDS,
    CLEAR_TNVED,
    SET_TNVED_LOADING
} from '../utils/consts';

const initialState = {
    tnved: null,
    tnvedsList: [],
    isLoading: false
};

const tnveds = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_TNVED:
            return {
                ...state,
                tnvedsList: [ payload, ...state.tnvedsList ],
            };
        case GET_TNVEDS:
            return {
                ...state,
                tnvedsList: payload,
                isLoading: false,
            };
        case GET_TNVED:
        case UPDATE_TNVED:
            return {
                ...state,
                tnved: payload,
                isLoading: false
            };
        case DELETE_TNVED:
            return {
                ...state,
                tnved: null,
                isLoading: false
            };
        case SET_TNVED_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_TNVED:
            return {
                ...state,
                tnved: null,
            };
        case TNVED_ERROR:
            return {
                ...state,
                isLoading: false,
                tnved: null
            };
        default:
            return state;
    }
};

export default tnveds;