import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm, Select, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import { addQsmOne, removeQsmOne, updateQsmOne } from "../../actions/qsmOne";
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const initialState = {
    qsmdoctype: '',
    document_code: '',
    document_name: '',
    standart_clause: '',
    approval_date: moment(),
    introduction_date: moment(),
    actual_last: moment(),
    actual_next: moment(),
    responsibility_dev: [],
    responsibility_approval: '',
    responsibility_agreement: [],
    responsibility_acquaintance: '',
    responsibility_keeping: '',
    status: 'Действующий',
    document_link: '',
    journalqsmone_mailing: [],
};

const QsmOneModal = ( { visible, onCreate, onCancel } ) => {

    const users = useSelector( state => state.users.users );
    const qsmOneData = useSelector( state => state.qsmOne.qsmOneData );
    const qsmOneDocTypes = useSelector( state => state.qsmOneDocTypes.qsmOneDocTypes );
    const isLoading = useSelector( state => state.users.isLoading );
    const positions = useSelector( state => state.positions.positions );

    const dispatch = useDispatch();
    if ( qsmOneData ) {
        qsmOneData.approval_date = moment( qsmOneData.approval_date );
        qsmOneData.introduction_date = moment( qsmOneData.introduction_date );
        qsmOneData.actual_last = moment( qsmOneData.actual_last );
        qsmOneData.actual_next = moment( qsmOneData.actual_next );
        if ( qsmOneData.journalqsmone_mailing ) {
            qsmOneData.journalqsmone_mailing.map( item => item.mailing_date = moment( item.mailing_date ) )
        }
    }

    const [ qsmData, setQsmData ] = useState( qsmOneData || initialState );

    useEffect( () => {
        setQsmData( qsmOneData !== null ? qsmOneData : initialState );
    }, [ qsmOneData ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newQsmData = {
                    qsmdoctype: values.qsmdoctype,
                    document_code: values.document_code,
                    document_name: values.document_name,
                    standart_clause: values.standart_clause,
                    approval_date: values.approval_date,
                    introduction_date: values.introduction_date,
                    actual_last: values.actual_last,
                    actual_next: values.actual_next,
                    responsibility_dev: values.responsibility_dev,
                    responsibility_approval: values.responsibility_approval,
                    responsibility_agreement: values.responsibility_agreement,
                    responsibility_acquaintance: values.responsibility_acquaintance,
                    responsibility_keeping: values.responsibility_keeping,
                    status: values.status,
                    document_link: values.document_link,
                    journalqsmone_mailing: values.journalqsmone_mailing,
                };

                const newData = { ...qsmData, ...newQsmData };

                setQsmData( newData );
                if ( qsmOneData !== null && qsmOneData._id ) {
                    dispatch( updateQsmOne( qsmOneData._id, newData ) );
                } else {
                    dispatch( addQsmOne( newData ) );
                }
                form.submit();
                form.resetFields();
                setQsmData( initialState );
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const onChangeHandler = ( value ) => {
        if ( value.hasOwnProperty( 'journalqsmone_mailing' ) ) {
            return false;
        }
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 12 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 12 },
            xs: { span: 24 },
        },
    };


    let mailing = [];
qsmData.journalqsmone_mailing.map(item => {
    mailing.push({
        user: item.user._id,
        mailing_date: item.mailing_date,
        mailing_copy: item.mailing_copy
    });
    return false;
});

    return (
        <Modal
            visible={visible}
            title={qsmOneData !== null && qsmOneData._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            width={1100}
            footer={[
                qsmOneData !== null && qsmOneData._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeQsmOne( qsmOneData._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {qsmOneData !== null && qsmOneData._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="qsmOneForm"
                size="middle"
                initValues={{
                    ...qsmData,
                    qsmdoctype: qsmData.qsmdoctype._id,
                    responsibility_dev: [ ...qsmData.responsibility_dev.map( item => item._id ) ],
                    responsibility_approval: qsmData.responsibility_approval._id,
                    responsibility_agreement: [ ...qsmData.responsibility_agreement.map( item => item._id ) ],
                    responsibility_acquaintance: qsmData.responsibility_acquaintance._id,
                    responsibility_keeping: qsmData.responsibility_keeping._id,
                    journalqsmone_mailing: mailing,
                }}
                onValuesChange={onChangeHandler}
            >
                <Row gutter={15}>
                    <Col span={12}>
                        <h4>Основная информация</h4>
                        <FormField
                            name="qsmdoctype"
                            label="Тип документа"
                            rules={[ { required: true, message: 'Пожалуйста укажите Тип документа!' } ]}>
                            <Select
                                showSearch
                                placeholder="Тип документа"
                                optionFilterProp="children"
                                name="qsmdoctype"
                                onChange={onChangeHandler}
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {qsmOneDocTypes.map( item => <Option key={item._id} value={item._id}
                                                                     selected>{item.name}</Option> )}
                            </Select>
                        </FormField>
                        <FormField
                            name="document_code" label="Код документа"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Код документа!',
                                whitespace: true
                            } ]}>
                            <Input name="document_code"/>
                        </FormField>
                        <FormField
                            name="document_name" label="Наименование документа"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Наименование документа!',
                                whitespace: true
                            } ]}>
                            <Input name="document_name"/>
                        </FormField>
                        <FormField
                            name="standart_clause"
                            label="Пункт стандарта"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Пункт стандарта!',
                            } ]}>
                            <Input name="standart_clause"/>
                        </FormField>
                        <FormField
                            name="approval_date" label="Дата утверждения"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Дата утверждения',
                            } ]}>
                            <DatePicker format='DD.MM.YYYY'/>
                        </FormField>
                        <FormField
                            name="introduction_date" label="Дата введения"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Дата введения',
                            } ]}>
                            <DatePicker format='DD.MM.YYYY'/>
                        </FormField>
                        <FormField
                            rules={[ {
                                type: 'url',
                            } ]}
                            name="document_link"
                            label="Ссылка на документ">
                            <Input name="document_link"/>
                        </FormField>
                        <FormField
                            name="status" label="Статус"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Статус документа!',
                                whitespace: true
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Статус документа"
                                optionFilterProp="children"
                                name="status"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                <Option value="Действующий" selected>Действующий</Option>
                                <Option value="Не действующий">Не действующий</Option>
                            </Select>
                        </FormField>
                    </Col>
                    <Col span={12}>
                        <h4>Актуализация</h4>
                        <FormField
                            name="actual_last" label="Последняя"
                        >
                            <DatePicker format='DD.MM.YYYY'/>

                        </FormField>
                        <FormField
                            name="actual_next" label="Следующая"
                        >
                            <DatePicker format='DD.MM.YYYY'/>
                        </FormField>
                        <h4>Ответственность за документ</h4>
                        <FormField
                            name="responsibility_dev"
                            label="Разработка"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Разработка!',
                            } ]}>
                            <Select
                                showSearch
                                mode="multiple"
                                allowClear
                                placeholder="Разработка"
                                optionFilterProp="children"
                                name="responsibility_dev"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {positions.map( item => <Option key={item._id} value={item._id}
                                                                selected>{item.name}</Option> )}
                            </Select>
                        </FormField>
                        <FormField
                            name="responsibility_approval"
                            label="Утверждение"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Утверждение!',
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Утверждение"
                                optionFilterProp="children"
                                name="responsibility_approval"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {positions.map( item => <Option key={item._id} value={item._id}
                                                                selected>{item.name}</Option> )}
                            </Select>
                        </FormField>
                        <FormField
                            name="responsibility_agreement"
                            label="Согласование"
                        >
                            <Select
                                showSearch
                                mode="multiple"
                                allowClear
                                placeholder="Согласование"
                                optionFilterProp="children"
                                name="responsibility_agreement"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {positions.map( item => <Option key={item._id} value={item._id}
                                                                selected>{item.name}</Option> )}
                            </Select>
                        </FormField>
                        <FormField
                            name="responsibility_acquaintance"
                            label="Ознакомление"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста укажите Ознакомление!',
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Ознакомление"
                                optionFilterProp="children"
                                name="responsibility_acquaintance"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {positions.map( item => <Option key={item._id} value={item._id}
                                                                selected>{item.name}</Option> )}
                            </Select>
                        </FormField>
                        <FormField
                            name="responsibility_keeping" label="Хранитель контрольного экземпляра"
                            rules={[ {
                                required: true,
                                message: 'Пожалуйста выберите Хранитель контрольного экземпляра!'
                            } ]}>
                            <Select
                                showSearch
                                placeholder="Хранитель контрольного экземпляра"
                                optionFilterProp="children"
                                name="responsibility_keeping"
                                filterOption={( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                            >
                                {positions.map( item => <Option key={item._id} value={item._id}
                                                                selected>{item.name}</Option> )}

                            </Select>
                        </FormField>
                    </Col>
                    <Col span={24}>
                        <h4>Рассылка</h4>
                    </Col>
                    <Col span={24}>
                        <Form.List name="journalqsmone_mailing">
                            {( fields, { add, remove } ) => (
                                <>
                                    {fields.map( ( { key, name, fieldKey, ...restField } ) => (
                                        <Row key={key} gutter={10} align="center">
                                            <Col span={7}>
                                                <FormField
                                                    {...restField}
                                                    name={[ name, 'mailing_date' ]}
                                                    fieldKey={[ fieldKey, 'mailing_date' ]}
                                                    label="Дата"
                                                    rules={[ {
                                                        required: true,
                                                        message: 'Пожалуйста укажите Дата!',
                                                    } ]}
                                                >
                                                    <DatePicker format='DD.MM.YYYY'/>
                                                </FormField>
                                            </Col>
                                            <Col span={6}>
                                                <FormField
                                                    {...restField}
                                                    name={[ name, 'mailing_copy' ]}
                                                    fieldKey={[ fieldKey, 'mailing_copy' ]}
                                                    label="Копия №"
                                                    rules={[ {
                                                        required: true,
                                                        message: 'Пожалуйста укажите Копия №!',
                                                    } ]}
                                                >
                                                    <Input/>
                                                </FormField>
                                            </Col>
                                            <Col span={10}>
                                                <FormField
                                                    {...restField}
                                                    name={[ name, 'user' ]}
                                                    fieldKey={[ fieldKey, 'user' ]}
                                                    label="Ф. И. О. получателя"
                                                    rules={[ {
                                                        required: true,
                                                        message: 'Пожалуйста укажите Ф. И. О. получателя!',
                                                    } ]}
                                                >
                                                    <Select>
                                                        {users.map( item => (
                                                            <Option key={item._id} value={item._id}>
                                                                {item.name}
                                                            </Option>
                                                        ) )}
                                                    </Select>
                                                </FormField>
                                            </Col>
                                            <Col span={1}>
                                                <MinusCircleOutlined onClick={() => remove( name )}/>
                                            </Col>

                                        </Row>
                                    ) )}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            Добавить поля
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </AppForm>

        )}

        </Modal>
    );
};

export default QsmOneModal;