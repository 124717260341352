import {
    ADD_CBSOA,
    GET_CBSOA,
    UPDATE_CBSOA,
    DELETE_CBSOA,
    CBSOA_ERROR,
    GET_CBSOAS,
    CLEAR_CBSOA,
    SET_CBSOA_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import CbsoaService from "../services/CbsoaService";

// Get All Cbsoa
export const getAllCbsoa = () => async dispatch => {
    dispatch( { type: SET_CBSOA_LOADING } );
    dispatch( { type: CLEAR_CBSOA } );
    try {
        const response = await CbsoaService.getAllCbsoa();
        dispatch( {
            type: GET_CBSOAS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CBSOA_ERROR
        } );
    }
};

// Get Cbsoa by ID
export const getCbsoaById = ( cbsoaId ) => async dispatch => {
    dispatch( { type: SET_CBSOA_LOADING } );
    dispatch( { type: CLEAR_CBSOA } );
    try {
        const response = await CbsoaService.getCbsoaById( cbsoaId );
        dispatch( {
            type: GET_CBSOA,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CBSOA_ERROR
        } );
    }
};


// Add Cbsoa
export const addCbsoa = ( { code, name, objects } ) => async dispatch => {
    dispatch( { type: SET_CBSOA_LOADING } );
    dispatch( { type: CLEAR_CBSOA } );
    try {
        const response = await CbsoaService.addCbsoa( code, name, objects );
        dispatch( {
            type: ADD_CBSOA,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно добавлена!' ) );
        dispatch( getAllCbsoa() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CBSOA_ERROR
        } );
    }
};
// Update Cbsoa
export const updateCbsoa = ( cbsoaId, { code, name, objects } ) => async dispatch => {
    dispatch( { type: SET_CBSOA_LOADING } );
    dispatch( { type: CLEAR_CBSOA } );
    try {
        const response = await CbsoaService.updateCbsoa( cbsoaId, code, name, objects );
        dispatch( {
            type: UPDATE_CBSOA,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllCbsoa() );
        dispatch( getCbsoaById( cbsoaId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CBSOA_ERROR
        } );
    }
};

// Delete Cbsoa
export const removeCbsoa = ( cbsoaId ) => async dispatch => {
    dispatch( { type: SET_CBSOA_LOADING } );
    dispatch( { type: CLEAR_CBSOA } );
    try {
        await CbsoaService.removeCbsoa( cbsoaId );
        dispatch( {
            type: DELETE_CBSOA,
            payload: cbsoaId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Запись успешно удалена!' ) );
        dispatch( getAllCbsoa() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: CBSOA_ERROR
        } );
    }
};




