import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeDepartment, addDepartment, updateDepartment } from '../../actions/departments';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
};

const DepartmentModal = ( { visible, onCreate, onCancel } ) => {

    const department = useSelector( state => state.departments.department );
    const isLoading = useSelector( state => state.departments.isLoading );
    const dispatch = useDispatch();

    const [ departmentData, setDepartmentData ] = useState( department || initialState );

    useEffect( () => {
        setDepartmentData( department !== null ? department : initialState );
    }, [ department ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newDepartmentData = {
                    name: values.name
                };
                const newData = { ...departmentData, ...newDepartmentData };
                setDepartmentData( newData );
                if ( department !== null && department._id ) {
                    dispatch( updateDepartment( department._id, newData ) );
                } else {
                    dispatch( addDepartment( newData ) );
                }
                form.submit();
                form.resetFields();
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    return (
        <Modal
            visible={visible}
            title={department !== null && department._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                department !== null && department._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeDepartment( department._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {department !== null && department._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formInit={form}
                name="departmentForm"
                size="middle"
                initValues={departmentData}
            >
                <FormField name="name" label="Наименование отдела"
                           rules={[ {
                               required: true,
                               message: 'Пожалуйста введите наименование отдела!',
                               whitespace: true
                           } ]}>
                    <Input name="name"/>
                </FormField>
            </AppForm>
        )}

        </Modal>
    );
};

export default DepartmentModal;