import {
    ADD_POSITION,
    GET_POSITION,
    UPDATE_POSITION,
    DELETE_POSITION,
    POSITION_ERROR,
    GET_POSITIONS,
    CLEAR_POSITION, SET_POSITION_LOADING
} from '../utils/consts';

import { setNotify } from './notications';

import PositionService from "../services/PositionService";

// Get All Positions
export const getAllPositions = () => async dispatch => {
    dispatch( {type: SET_POSITION_LOADING } );
    dispatch( {type: CLEAR_POSITION } );
    try {
        const response = await PositionService.getAllPositions();
        dispatch( {
            type: GET_POSITIONS,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: POSITION_ERROR
        } );
    }
};

// Get Position by ID
export const getPositionById = ( positionId ) => async dispatch => {
    dispatch( {type: SET_POSITION_LOADING } );
    dispatch( {type: CLEAR_POSITION } );
    try {
        const response = await PositionService.getPositionById( positionId );
        dispatch( {
            type: GET_POSITION,
            payload: response.data
        } );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: POSITION_ERROR
        } );
    }
};


// Add position
export const addPosition = ( { name } ) => async dispatch => {
    dispatch( {type: SET_POSITION_LOADING } );
    dispatch( {type: CLEAR_POSITION } );
    try {
        const response = await PositionService.addPosition( name );
        dispatch( {
            type: ADD_POSITION,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Должность успешно добавлена!' ) );
        dispatch( getAllPositions() );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: POSITION_ERROR
        } );
    }
};
// Update Position
export const updatePosition = ( positionId, { name } ) => async dispatch => {
    dispatch( {type: SET_POSITION_LOADING } );
    dispatch( {type: CLEAR_POSITION } );
    try {
        const response = await PositionService.updatePosition( positionId, name );
        dispatch( {
            type: UPDATE_POSITION,
            payload: response.data
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Данные успешно обновлены!' ) );
        dispatch( getAllPositions() );
        dispatch( getPositionById( positionId ) );

    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: POSITION_ERROR
        } );
    }
};

// Delete Position
export const removePosition = ( positionId ) => async dispatch => {
    dispatch( { type: SET_POSITION_LOADING } );
    dispatch( { type: CLEAR_POSITION } );
    try {
        await PositionService.removePosition( positionId );
        dispatch( {
            type: DELETE_POSITION,
            payload: positionId
        } );
        dispatch( setNotify( 'success', 'Успех!', 'Должность успешно удалена!' ) );
        dispatch( getAllPositions() );
    } catch ( e ) {
        dispatch( setNotify( 'error', 'Ошибка', `${e.response?.statusText}` ) );
        dispatch( {
            type: POSITION_ERROR
        } );
    }
};




