export const ADMIN_ROUTE = 'admin';
export const LOGIN_ROUTE = 'login';
export const PROFILE_ROUTE = 'profile';

export const USERS_ROUTE = 'users';
export const POSITIONS_ROUTE = 'positions';
export const ROLES_ROUTE = 'roles';
export const DEPARTMENT_ROUTE = 'department';
export const DASHBOARD_ROUTE = 'dashboard';

export const JOURNALQSMONE_ROUTE = 'journalqsmone';
export const JOURNALQSMTWO_ROUTE = 'journalqsmtwo';
export const JOURNALQSMTHREE_ROUTE = 'journalqsmthree';
export const JOURNALQSMDESIGNATION_ROUTE = 'qsmdesignation';

export const NONCONFORMITY_ROUTE = 'nonconformity';
export const NONCONFORMITY_JOURNAL_ROUTE = 'nonconformityjournal';

export const RISKTYPES_ROUTE = 'risktypes';
export const RISKMAP_ROUTE = 'riskmap';
export const RISKADD_ROUTE = 'riskadd';
export const RISKS_ROUTE = 'riskjournal';

export const ANALYSIS_ROUTE = 'analysis';
export const INFLUENCES_ROUTE = 'influences';
export const SUBDIVISIONS_ROUTE = 'subdivisions';
export const QSM_DOCTYPES_ROUTE = 'qsmdoctypes';

export const STANDARTS_ROUTE = 'standarts';

export const DEVICES_ROUTE = 'devices';

export const TNVED_ROUTE = 'tnveds';

export const CERT_SCHEMA_ROUTE = 'certschema';

export const LABS_ROUTE = 'labs';

export const CLIENTS_ROUTE = 'clients';

export const APP_ROUTE = 'applications/:id';
export const APPS_ROUTE = 'applications';

export const CBSOA_ROUTE = 'cbsoa';

export const ECONOMIC_CODE_ROUTE = 'economiccode';

export const CERT_CODE_ROUTE = 'certificationcode';

export const ADD_USER = 'ADD_USER';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_ERROR = 'USER_ERROR';
export const GET_USERS = 'GET_USERS';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';

export const ADD_POSITION = 'ADD_POSITION';
export const GET_POSITION = 'GET_POSITION';
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const DELETE_POSITION = 'DELETE_POSITION';
export const POSITION_ERROR = 'POSITION_ERROR';
export const GET_POSITIONS = 'GET_POSITIONS';
export const CLEAR_POSITION = 'CLEAR_POSITION';
export const SET_POSITION_LOADING = 'SET_POSITION_LOADING';

export const ADD_ROLE = 'ADD_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const ROLE_ERROR = 'ROLE_ERROR';
export const GET_ROLES = 'GET_ROLES';
export const CLEAR_ROLE = 'CLEAR_ROLE';
export const SET_ROLE_LOADING = 'SET_USER_LOADING';

export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const CLEAR_DEPARTMENT = 'CLEAR_DEPARTMENT';
export const SET_DEPARTMENT_LOADING = 'SET_USER_LOADING';

export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CHECK_AUTH = 'CHECK_AUTH';
export const AUTH_LOADING_TRUE = 'AUTH_LOADING_TRUE';
export const AUTH_LOADING_FALSE = 'AUTH_LOADING_FALSE';

export const SET_NOTIFY = 'SET_NOTIFY';
export const CLEAR_NOTIFY = 'CLEAR_NOTIFY';

export const ADD_QSMONEDOCTYPE = 'ADD_QSMONEDOCTYPE';
export const GET_QSMONEDOCTYPE = 'GET_QSMONEDOCTYPE';
export const UPDATE_QSMONEDOCTYPE = 'UPDATE_QSMONEDOCTYPE';
export const DELETE_QSMONEDOCTYPE = 'DELETE_QSMONEDOCTYPE';
export const QSMONEDOCTYPE_ERROR = 'QSMONEDOCTYPE_ERROR';
export const GET_ALLQSMONEDOCTYPE = 'GET_ALLQSMONEDOCTYPE';
export const CLEAR_QSMONEDOCTYPE = 'CLEAR_QSMONEDOCTYPE';
export const SET_QSMONEDOCTYPE_LOADING = 'SET_QSMONEDOCTYPE_LOADING';

export const ADD_QSMONE = 'ADD_QSMONE';
export const GET_QSMONE = 'GET_QSMONE';
export const UPDATE_QSMONE = 'UPDATE_QSMONE';
export const DELETE_QSMONE = 'DELETE_QSMONE';
export const QSMONE_ERROR = 'QSMONE_ERROR';
export const GET_ALLQSMONE = 'GET_ALLQSMONE';
export const CLEAR_QSMONE = 'CLEAR_QSMONE';
export const SET_QSMONE_LOADING = 'SET_QSMONE_LOADING';

export const ADD_QSMDESIGNATION = 'ADD_QSMDESIGNATION';
export const GET_QSMDESIGNATION = 'GET_QSMDESIGNATION';
export const UPDATE_QSMDESIGNATION = 'UPDATE_QSMDESIGNATION';
export const DELETE_QSMDESIGNATION = 'DELETE_QSMDESIGNATION';
export const QSMDESIGNATION_ERROR = 'QSMDESIGNATION_ERROR';
export const GET_ALLQSMDESIGNATION = 'GET_ALLQSMDESIGNATION';
export const CLEAR_QSMDESIGNATION = 'CLEAR_QSMDESIGNATION';
export const SET_QSMDESIGNATION_LOADING = 'SET_QSMDESIGNATION_LOADING';

export const ADD_RISKTYPE = 'ADD_RISKTYPE';
export const GET_RISKTYPE = 'GET_RISKTYPE';
export const UPDATE_RISKTYPE = 'UPDATE_RISKTYPE';
export const DELETE_RISKTYPE = 'DELETE_RISKTYPE';
export const RISKTYPE_ERROR = 'RISKTYPE_ERROR';
export const GET_RISKTYPES = 'GET_RISKTYPES';
export const CLEAR_RISKTYPE = 'CLEAR_RISKTYPE';
export const SET_RISKTYPE_LOADING = 'SET_RISKTYPE_LOADING';

export const ADD_RISK = 'ADD_RISK';
export const GET_RISK = 'GET_RISK';
export const GET_RISK_USER = 'GET_RISK_USER';
export const UPDATE_RISK = 'UPDATE_RISK';
export const DELETE_RISK = 'DELETE_RISK';
export const RISK_ERROR = 'RISK_ERROR';
export const GET_RISKS = 'GET_RISKS';
export const CLEAR_RISK = 'CLEAR_RISK';
export const SET_RISK_LOADING = 'SET_RISK_LOADING';

export const ADD_ANALYSIS = 'ADD_ANALYSIS';
export const GET_ONE_ANALYSIS = 'GET_ONE_ANALYSIS';
export const UPDATE_ANALYSIS= 'UPDATE_ANALYSIS';
export const DELETE_ANALYSIS = 'DELETE_ANALYSIS';
export const ANALYSIS_ERROR = 'ANALYSIS_ERROR';
export const GET_ANALYSIS = 'GET_ANALYSIS';
export const CLEAR_ANALYSIS = 'CLEAR_ANALYSIS';
export const SET_ANALYSIS_LOADING = 'SET_ANALYSIS_LOADING';

export const ADD_INFLUENCE = 'ADD_INFLUENCE';
export const GET_INFLUENCE = 'GET_INFLUENCE';
export const UPDATE_INFLUENCE = 'UPDATE_INFLUENCE';
export const DELETE_INFLUENCE = 'DELETE_INFLUENCE';
export const INFLUENCE_ERROR = 'INFLUENCE_ERROR';
export const GET_INFLUENCES = 'GET_INFLUENCES';
export const CLEAR_INFLUENCE = 'CLEAR_INFLUENCE';
export const SET_INFLUENCE_LOADING = 'SET_INFLUENCE_LOADING';

export const ADD_SUBDIVISION = 'ADD_SUBDIVISION';
export const GET_SUBDIVISION = 'GET_SUBDIVISION';
export const GET_SUBDIVISIONS = 'GET_SUBDIVISIONS';
export const UPDATE_SUBDIVISION = 'UPDATE_SUBDIVISION';
export const DELETE_SUBDIVISION = 'DELETE_SUBDIVISION';
export const SUBDIVISION_ERROR = 'SUBDIVISION_ERROR';
export const CLEAR_SUBDIVISION = 'CLEAR_SUBDIVISION';
export const SET_SUBDIVISION_LOADING = 'SET_SUBDIVISION_LOADING';

export const ADD_NONCONFORMITY = 'ADD_NONCONFORMITY';
export const GET_NONCONFORMITY = 'GET_NONCONFORMITY';
export const GET_NONCONFORMITY_USER = 'GET_NONCONFORMITY_USER';
export const GET_ALL_NONCONFORMITY = 'GET_ALL_NONCONFORMITY';
export const GET_LAST_NONCONFORMITY = 'GET_LAST_NONCONFORMITY';
export const UPDATE_NONCONFORMITY = 'UPDATE_NONCONFORMITY';
export const DELETE_NONCONFORMITY = 'DELETE_NONCONFORMITY';
export const NONCONFORMITY_ERROR = 'NONCONFORMITY_ERROR';
export const CLEAR_NONCONFORMITY = 'CLEAR_NONCONFORMITY';
export const SET_NONCONFORMITY_LOADING = 'SET_NONCONFORMITY_LOADING';

export const ADD_STANDART = 'ADD_STANDART';
export const GET_STANDART = 'GET_STANDART';
export const GET_STANDARTS = 'GET_STANDARTS';
export const UPDATE_STANDART = 'UPDATE_STANDART';
export const DELETE_STANDART = 'DELETE_STANDART';
export const STANDART_ERROR = 'STANDART_ERROR';
export const CLEAR_STANDART = 'CLEAR_STANDART';
export const SET_STANDART_LOADING = 'SET_STANDART_LOADING';

export const ADD_TNVED = 'ADD_TNVED';
export const GET_TNVED = 'GET_TNVED';
export const GET_TNVEDS = 'GET_TNVEDS';
export const UPDATE_TNVED = 'UPDATE_TNVED';
export const DELETE_TNVED = 'DELETE_TNVED';
export const TNVED_ERROR = 'TNVED_ERROR';
export const CLEAR_TNVED = 'CLEAR_TNVED';
export const SET_TNVED_LOADING = 'SET_TNVED_LOADING';

export const ADD_CERT_SCHEMA = 'ADD_CERT_SCHEMA';
export const GET_CERT_SCHEMA = 'GET_CERT_SCHEMA';
export const GET_CERT_SCHEMAS = 'GET_CERT_SCHEMAS';
export const UPDATE_CERT_SCHEMA = 'UPDATE_CERT_SCHEMA';
export const DELETE_CERT_SCHEMA = 'DELETE_CERT_SCHEMA';
export const CERT_SCHEMA_ERROR = 'CERT_SCHEMA_ERROR';
export const CLEAR_CERT_SCHEMA = 'CLEAR_CERT_SCHEMA';
export const SET_CERT_SCHEMA_LOADING = 'SET_CERT_SCHEMA_LOADING';

export const ADD_LAB = 'ADD_LAB';
export const GET_LAB = 'GET_LAB';
export const GET_LABS = 'GET_LABS';
export const UPDATE_LAB = 'UPDATE_LAB';
export const DELETE_LAB = 'DELETE_LAB';
export const LAB_ERROR = 'LAB_ERROR';
export const CLEAR_LAB = 'CLEAR_LAB';
export const SET_LAB_LOADING = 'SET_LAB_LOADING';

export const ADD_CLIENT = 'ADD_CLIENT';
export const GET_CLIENT = 'GET_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const GET_CLIENTS = 'GET_CLIENTS';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';
export const SET_CLIENT_LOADING = 'SET_CLIENT_LOADING';

export const ADD_APP = 'ADD_APP';
export const GET_APP = 'GET_APP';
export const UPDATE_APP = 'UPDATE_APP';
export const DELETE_APP = 'DELETE_APP';
export const APP_ERROR = 'APP_ERROR';
export const GET_APPS = 'GET_APPS';
export const CLEAR_APP = 'CLEAR_APP';
export const SET_APP_LOADING = 'SET_APP_LOADING';

export const ADD_ECONOMIC_CODE = 'ADD_ECONOMIC_CODE';
export const GET_ECONOMIC_CODE = 'GET_ECONOMIC_CODE';
export const UPDATE_ECONOMIC_CODE = 'UPDATE_ECONOMIC_CODE';
export const DELETE_ECONOMIC_CODE = 'DELETE_ECONOMIC_CODE';
export const ECONOMIC_CODE_ERROR = 'ECONOMIC_CODE_ERROR';
export const GET_ECONOMIC_CODES = 'GET_ECONOMIC_CODES';
export const CLEAR_ECONOMIC_CODE = 'CLEAR_ECONOMIC_CODE';
export const SET_ECONOMIC_CODE_LOADING = 'SET_ECONOMIC_CODE_LOADING';

export const ADD_CERTIFICATION_CODE = 'ADD_CERTIFICATION_CODE';
export const GET_CERTIFICATION_CODE = 'GET_CERTIFICATION_CODE';
export const UPDATE_CERTIFICATION_CODE = 'UPDATE_CERTIFICATION_CODE';
export const DELETE_CERTIFICATION_CODE = 'DELETE_CERTIFICATION_CODE';
export const CERTIFICATION_CODE_ERROR = 'CERTIFICATION_CODE_ERROR';
export const GET_CERTIFICATION_CODES = 'GET_CERTIFICATION_CODES';
export const CLEAR_CERTIFICATION_CODE = 'CLEAR_CERTIFICATION_CODE';
export const SET_CERTIFICATION_CODE_LOADING = 'SET_CERTIFICATION_CODE_LOADING';

export const ADD_CBSOA = 'ADD_CBSOA';
export const GET_CBSOA = 'GET_CBSOA';
export const UPDATE_CBSOA = 'UPDATE_CBSOA';
export const DELETE_CBSOA = 'DELETE_CBSOA';
export const CBSOA_ERROR = 'CBSOA_ERROR';
export const GET_CBSOAS = 'GET_CBSOAS';
export const CLEAR_CBSOA = 'CLEAR_CBSOA';
export const SET_CBSOA_LOADING = 'SET_CBSOA_LOADING';

export const PDF_DECISION = 'PDF_DECISION';


