import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Popconfirm } from 'antd';
import { removeRiskType, addRiskType, updateRiskType } from '../../actions/riskTypes';
import Loading from "../../components/Loading/Loading";
import AppForm from "../Form/AppForm";
import FormField from "../Form/FormField";

const initialState = {
    name: '',
    description: ''
};
const { TextArea } = Input;

const RiskTypeModal = ( { visible, onCreate, onCancel } ) => {

    const riskType = useSelector( state => state.riskTypes.riskType );
    const isLoading = useSelector( state => state.risks.isLoading );
    const dispatch = useDispatch();

    const [ riskTypeData, setRiskTypeData ] = useState( riskType || initialState );

    useEffect( () => {
        setRiskTypeData( riskType !== null ? riskType : initialState );
    }, [ riskType ] );

    const [ form ] = Form.useForm();

    const onFormSubmit = () => {
        form
            .validateFields()
            .then( ( values ) => {
                const newRiskTypeData = {
                    name: values.name,
                    description: values.description
                };
                const newData = { ...riskTypeData, ...newRiskTypeData };
                setRiskTypeData( newData );
                if ( riskType !== null && riskType._id ) {
                    dispatch( updateRiskType( riskType._id, newData ) );
                } else {
                    dispatch( addRiskType( newData ) );
                }
                form.submit();
                form.resetFields();
                setRiskTypeData(initialState);
                onCreate();
            } )
            .catch( ( info ) => {
                console.log( 'Validate Failed:', info );
            } )
    };

    const formItemLayout = {
        labelCol: {
            sm: { span: 10 },
            xs: { span: 24 },

        },
        wrapperCol: {
            sm: { span: 14 },
            xs: { span: 24 },
        },
    };

    return (
        <Modal
            visible={visible}
            title={riskType !== null && riskType._id ? "Изменить запись" : "Добавить запись"}
            onCancel={onCancel}
            onOk={onFormSubmit}
            footer={[
                riskType !== null && riskType._id ?
                    <Popconfirm key="delete" title="Вы уверены что хотите удалить запись ?"
                                onConfirm={() => {
                                    dispatch( removeRiskType( riskType._id ) );
                                    onCreate();
                                }}>
                        <Button key="delete" type="danger">Удалить</Button>
                    </Popconfirm> : '',
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={onFormSubmit}
                >
                    {riskType !== null && riskType._id ? "Обновить" : "Добавить"}
                </Button>
            ]}
        >{isLoading ? <Loading/> : (
            <AppForm
                formItemLayout={formItemLayout}
                formInit={form}
                name="risktypeForm"
                size="middle"
                initValues={riskTypeData}
            >
                <FormField
                    name="name"
                    label="Вид риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Вид риска!',
                        whitespace: true
                    } ]}>
                    <Input name="name"/>
                </FormField>
                <FormField
                    name="description"
                    label="Описание риска"
                    rules={[ {
                        required: true,
                        message: 'Пожалуйста введите Описание риска!',
                        whitespace: true
                    } ]}
                >
                    <TextArea name="description" rows={3}/>
                </FormField>
            </AppForm>

        )}

        </Modal>
    );
};

export default RiskTypeModal;