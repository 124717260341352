import {$api} from "../http";

class LabService {

    async addLab( registry_number, name ) {
        return $api.post( '/lab', { registry_number, name } );
    }

    async getAllLab() {
        return $api.get( '/lab' );
    }

    async updateLab( labId, registry_number, name ) {
        return $api.put( `/lab/${labId}`, {registry_number, name } );
    }

    async removeLab( labId ) {
        return $api.delete( `/lab/${labId}` );
    }

    async getLabById( labId ) {
        return $api.get( `/lab/${labId}` );
    }
}

export default new LabService();

