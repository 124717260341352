import { SET_NOTIFY, CLEAR_NOTIFY } from '../utils/consts';

export const setNotify = (type, message, description, timeout = 4000) => dispatch => {

    dispatch({
        type: SET_NOTIFY,
        payload: { type, message, description}
    });

    setTimeout(() => dispatch({ type: CLEAR_NOTIFY }), timeout);
};
