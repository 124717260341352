import {
    ADD_APP,
    GET_APP,
    UPDATE_APP,
    DELETE_APP,
    APP_ERROR,
    GET_APPS,
    CLEAR_APP,
    SET_APP_LOADING,
} from '../utils/consts';

const initialState = {
    application: null,
    applicationsList: [],
    isLoading: false
};

const applications = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case ADD_APP:
            return {
                ...state,
                applicationsList: [ payload, ...state.applicationsList ],
            };
        case GET_APPS:
            return {
                ...state,
                applicationsList: payload,
                isLoading: false,
            };
        case GET_APP:
        case UPDATE_APP:
            return {
                ...state,
                application: payload,
                isLoading: false
            };

        case DELETE_APP:
            return {
                ...state,
                application: null,
                isLoading: false
            };
        case SET_APP_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case CLEAR_APP:
            return {
                ...state,
                application: null,
            };
        case APP_ERROR:
            return {
                ...state,
                isLoading: false,
                application: null
            };
        default:
            return state;
    }
};

export default applications;