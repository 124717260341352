import {$api} from "../http";

class QsmDocTypeService {
    async addQsmOneDocType( name ) {
        return $api.post( '/qsmdoctype', { name } );
    }

    async getAllQsmOneDocType() {
        return $api.get( '/qsmdoctype' );
    }

    async updateQsmOneDocType( qsmId, name ) {
        return $api.put( `/qsmdoctype/${qsmId}`, { name } );
    }

    async removeQsmOneDocType( qsmId ) {
        return $api.delete( `/qsmdoctype/${qsmId}` );
    }

    async getQsmOneDocTypeById( qsmId ) {
        return $api.get( `/qsmdoctype/${qsmId}` );
    }

}

export default new QsmDocTypeService();

