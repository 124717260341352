import React, { useEffect } from 'react';
import { Form } from 'antd';

const AppForm = (
    {
        formItemLayout,
        formInit,
        formName,
        children,
        initValues,
        onFinish,
        onValuesChange,
        onSubmitFailedHandler
    }
) => {

    useEffect( () => {
        formInit.setFieldsValue( { ...initValues } );
    }, [ formInit, initValues ] );
    
    return (
        <Form
            {...formItemLayout}
            size="middle"
            form={formInit}
            name={formName}
            onFinish={onFinish}
            onFinishFailed={onSubmitFailedHandler}
            onValuesChange={onValuesChange}
            scrollToFirstError
        >
            {children}
        </Form>
    );

};

export default AppForm;