import {
    LOGIN_FAIL,
    LOGOUT,
    AUTH_ERROR,
    CHECK_AUTH,
    AUTH_LOADING_TRUE,
    AUTH_LOADING_FALSE
} from '../utils/consts';

const initialState = {
    user: {},
    isAuth: false,
    isLoading: false
};

const auth = ( state = initialState, action ) => {
    const { type, payload } = action;
    switch ( type ) {
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                isAuth: false,
                user: {}
            };
        case CHECK_AUTH:
            return {
                ...state,
                user: payload,
                isAuth: true,
            };
        case AUTH_LOADING_FALSE:
            return {
                ...state,
                isLoading: false,
            };
        case AUTH_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
};

export default auth;